import { AxiosInstance } from 'axios';

import { IClientConfigProps } from '@shared/clients/types/types';

import { Banks, banks } from '@modules/banks/services/Banks';

import { InvoicesConfigWallet, invoicesConfigWallet } from '@modules/configurations/services/InvoicesConfigWallet';

import { Conciliation, conciliation } from '@modules/invoices/services/Conciliation';
import { invoicesReports, InvoicesReports } from '@modules/invoices/services/Reports';

import { Licenses, licenses } from '@modules/licenses/services/Licenses';

import { CreditCards, creditCards } from '@modules/payments/services/CreditCards';
import { Customers, customers } from '@modules/payments/services/Customers';

import { Subscriptions, subscriptions } from '@modules/subscriptions/services/Subscriptions';

import { paymentsApiConfig } from './config';

export class PaymentsAPI {
  private instance: AxiosInstance;

  constructor(params: IClientConfigProps) {
    this.instance = paymentsApiConfig(params).instance();
  }

  banks(): Banks {
    return banks(this.instance);
  }

  conciliation(): Conciliation {
    return conciliation(this.instance);
  }

  creditCards(): CreditCards {
    return creditCards(this.instance);
  }

  customers(): Customers {
    return customers(this.instance);
  }

  invoicesConfigWallet(): InvoicesConfigWallet {
    return invoicesConfigWallet(this.instance);
  }

  invoicesReports(): InvoicesReports {
    return invoicesReports(this.instance);
  }

  licenses(): Licenses {
    return licenses(this.instance);
  }

  subscriptions(): Subscriptions {
    return subscriptions(this.instance);
  }
}

export const PaymentsFactoryAPI = (params: IClientConfigProps): PaymentsAPI => new PaymentsAPI(params);
