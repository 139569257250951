import axios, { AxiosInstance } from 'axios';

interface IEquitesAxiosInstanceOptions {
  headers?: Record<string, string>;
  timeout?: number;
}

export class EquitesAxiosInstance {
  public static createInstance(apiUrl: string, options?: IEquitesAxiosInstanceOptions): AxiosInstance {
    return axios.create({
      baseURL: apiUrl,
      timeout: options?.timeout ?? 30000,
      headers: { ...options?.headers },
    });
  }
}
