/* eslint-disable @typescript-eslint/no-explicit-any */

import axios from 'axios';

import { toast } from '@shared/components/Toast';

interface IOptions {
  err: any;
  otherStatus?: number[];
  extraValidations?: boolean[];
  onError?: () => void;
}

export class HandleApiErrors {
  public static handle(options: IOptions): void {
    const { err, otherStatus, extraValidations, onError } = options;

    const validations = {
      isAxiosError: axios.isAxiosError(err),
      isNotError403: err.response?.status !== 403,
    };

    if (otherStatus?.length) {
      otherStatus.forEach(status => {
        Object.assign(validations, { [`isNotError${status}`]: err.response?.status !== status });
      });
    }

    if (extraValidations?.length) {
      extraValidations.forEach((validation, index) => {
        Object.assign(validations, { [`validation-${index}`]: validation });
      });
    }

    if (Object.values(validations).every(validation => validation))
      toast(err.response?.data.message || 'Houve algum erro, tente novamente mais tarde! :(', { type: 'error' });

    if (onError) onError();
  }
}
