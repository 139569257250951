import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { createContext } from 'use-context-selector';

import { CompaniesClient } from '@shared/clients/http/CompaniesClient';
import { toast } from '@shared/components/Toast';
import { HandleApiErrors } from '@shared/utils/HandleApiErrors';

import { useLoader } from '@modules/globals/hooks/useLoader';

import { IRiderHealthContext } from '@modules/riders/types/Health/context';
import { IRiderHealth } from '@modules/riders/types/Health/health';
import {
  ICreateEmergencyContactRequest,
  IDeleteEmergencyContactRequest,
  IUpdateRiderMedicalInformationRequest,
} from '@modules/riders/types/Health/requests';

const RidersHealthContext = createContext({} as IRiderHealthContext);
RidersHealthContext.displayName = 'RidersHealth';

const RidersHealthProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation('riders', { keyPrefix: 'messages' });

  const { startLoad, endLoad } = useLoader();

  const [riderHealth, setRiderHealth] = useState({} as IRiderHealth);

  const createEmergencyContact = useCallback(
    async (data: ICreateEmergencyContactRequest) => {
      try {
        startLoad();

        const response = await CompaniesClient.riders().createEmergencyContact(data);

        setRiderHealth({ contactsEmergency: [response.data] });

        toast(t('emergency_contact_created_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad, t],
  );

  const deleteEmergencyContact = useCallback(
    async (data: IDeleteEmergencyContactRequest) => {
      try {
        startLoad();

        await CompaniesClient.riders().deleteEmergencyContact(data);

        setRiderHealth({ contactsEmergency: [] });

        toast(t('emergency_contact_deleted_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad, t],
  );

  const getRiderHealthByRiderId = useCallback(
    async (riderId: string) => {
      try {
        startLoad();

        const response = await CompaniesClient.riders().getRiderHealthByRiderId(riderId);

        setRiderHealth(response.data);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const updateMedicalInformation = useCallback(
    async (data: IUpdateRiderMedicalInformationRequest) => {
      try {
        startLoad();

        const response = await CompaniesClient.riders().updateMedicalInformation(data);

        setRiderHealth(current => ({ ...current, health: response.data }));

        toast(t('medical_information_updated_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad, t],
  );

  const handleRiderHealthState = useCallback(async (data: Partial<IRiderHealth>, reset?: boolean) => {
    setRiderHealth(current => (reset ? ({ ...data } as IRiderHealth) : { ...current, ...data }));
  }, []);

  const contextValue = useMemo<IRiderHealthContext>(
    () => ({
      createEmergencyContact,
      deleteEmergencyContact,
      getRiderHealthByRiderId,
      handleRiderHealthState,
      riderHealth,
      updateMedicalInformation,
    }),
    [
      createEmergencyContact,
      deleteEmergencyContact,
      getRiderHealthByRiderId,
      handleRiderHealthState,
      riderHealth,
      updateMedicalInformation,
    ],
  );

  return <RidersHealthContext.Provider value={contextValue}>{children}</RidersHealthContext.Provider>;
};

export { RidersHealthProvider, RidersHealthContext };
