import { AxiosError, AxiosResponse } from 'axios';

interface IResponseError {
  status?: string;
  message?: string;
}

export class HandleResponseFunctions {
  public static async handleResponseError(error: AxiosError<IResponseError>): Promise<AxiosResponse<IResponseError>> {
    return Promise.reject(error);
  }
}
