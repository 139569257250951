import { AxiosInstance } from 'axios';

import { IPermission } from '@modules/configurations/types/Permissions/permissions';
import { IGetPermissionsRequest } from '@modules/configurations/types/Permissions/requests';

export class Permissions {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  async getPermissions(data: IGetPermissionsRequest): Promise<IPermission[]> {
    const { profile, ...params } = data;

    const response = await this.instance.get(`/permissions/profile/${profile}`, { params });

    return response.data;
  }
}

export const permissions = (instance: AxiosInstance): Permissions => new Permissions(instance);
