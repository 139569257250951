import { AxiosInstance, AxiosResponse } from 'axios';

import { IBank } from '@modules/banks/types/Banks/banks';

export class Banks {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Banks ----------

  getAll(): Promise<AxiosResponse<IBank[]>> {
    return this.instance.get('/banks');
  }
}

export const banks = (instance: AxiosInstance): Banks => new Banks(instance);
