import { ReactElement } from 'react';
import { FloatingWhatsApp } from 'react-floating-whatsapp';

import Icon from '@shared/assets/images/icon-texture.png';

import { useTheme } from '@modules/globals/hooks/useTheme';

export const WhatsApp = (): ReactElement | null => {
  const phoneNumber = import.meta.env.VITE_EQUITES_WPP_NUMBER;

  const { theme } = useTheme();

  return phoneNumber ? (
    <FloatingWhatsApp
      accountName="Time Equites"
      phoneNumber={phoneNumber}
      avatar={Icon}
      allowEsc
      allowClickAway
      darkMode={theme === 'dark'}
      statusMessage="Estamos por aqui!"
      chatMessage="Você está precisando de ajuda?"
      placeholder="Escreva sua mensagem..."
      notification={false}
      buttonClassName="wpp-equites-button"
    />
  ) : null;
};
