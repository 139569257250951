import { FC, PropsWithChildren, ReactElement } from 'react';
import { isDesktop } from 'react-device-detect';

import { useSidebar } from '@modules/globals/hooks/useSidebar';

const WrapperContentComponent: FC<PropsWithChildren> = ({ children }): ReactElement => {
  const { sidebarOpened } = useSidebar();

  return (
    <section
      data-sidebar-desktop-opened={sidebarOpened && isDesktop}
      data-sidebar-mobile={!isDesktop}
      className="ml-auto flex h-full min-h-screen w-[calc(100vw_-_117px)] flex-col px-3 transition-all duration-300 data-[sidebar-desktop-opened=true]:w-[calc(100vw_-_235px)] data-[sidebar-mobile=true]:w-full lg:px-8"
    >
      {children}
    </section>
  );
};

export const WrapperContent = WrapperContentComponent;
