import { FC, PropsWithChildren, useMemo } from 'react';

import { useAuth } from '@modules/auth/hooks/useAuth';

import { INeedPermissionProps } from './types';

export const NeedPermission: FC<PropsWithChildren<INeedPermissionProps>> = ({ permission, children }) => {
  const { permissions } = useAuth();

  const hasPermission = useMemo(() => {
    return typeof permission === 'string'
      ? permissions.includes(permission)
      : permissions.filter(p => permission.includes(p)).length > 0;
  }, [permission, permissions]);

  function render(): React.ReactNode {
    return hasPermission ? children : null;
  }

  return <>{render()}</>;
};
