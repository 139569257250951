/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { CompaniesClient } from '@shared/clients/http/CompaniesClient';

import { ITeacherSummary } from '@modules/teachers/types/Teachers/teacher';

export const QUERY_KEY_FETCH_TEACHERS_SUMMARY = ['teachers:summary'];

export function useFetchTeachersSummary() {
  return useQuery<ITeacherSummary[]>({
    queryKey: QUERY_KEY_FETCH_TEACHERS_SUMMARY,
    queryFn: () => CompaniesClient.teachers().getTeachersSummary(),
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });
}
