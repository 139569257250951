/**
 * PlansRoles
 * @readonly
 * @enum {string}
 */
export enum PlansRoles {
  /** Create a new plan */
  CREATE_PLAN = 'CREATE_PLAN',

  /** Link plan with rider */
  CREATE_PLAN_RIDER = 'CREATE_PLAN_RIDER',

  /** Delete a unique plan */
  DELETE_PLAN = 'DELETE_PLAN',

  /** Delete the link of plan with rider */
  DELETE_PLAN_RIDER = 'DELETE_PLAN_RIDER',

  /** Get list of plans */
  GET_PLANS = 'GET_PLANS',

  /** Get plan of riders */
  GET_PLANS_RIDERS = 'GET_PLANS_RIDERS',

  /** Update a plan */
  UPDATE_PLAN = 'UPDATE_PLAN',

  /** Update a rider plan data */
  UPDATE_PLAN_RIDER = 'UPDATE_PLAN_RIDER',
}
