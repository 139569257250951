import { AxiosInstance, AxiosResponse } from 'axios';

import {
  IFindOwnersByHorseIdRequest,
  IFindOwnersByHorseIdResponse,
  IUpdateHorseOwnersRequest,
  IUpdateHorseOwnersResponse,
} from '@modules/horses/types/Owners/requests';

export class HorseOwners {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  getHorseOwners(data: IFindOwnersByHorseIdRequest): Promise<AxiosResponse<IFindOwnersByHorseIdResponse[]>> {
    const { horseId } = data;
    return this.instance.get(`/horses/${horseId}/owners`);
  }

  updateHorseOwners(data: IUpdateHorseOwnersRequest): Promise<AxiosResponse<IUpdateHorseOwnersResponse>> {
    const { horseId, ...body } = data;
    return this.instance.patch(`/horses/${horseId}/owners`, body);
  }
}

export const horseOwners = (instance: AxiosInstance): HorseOwners => new HorseOwners(instance);
