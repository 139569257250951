import { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';

import { createContext } from 'use-context-selector';

import { PaymentsClient } from '@shared/clients/http/PaymentsClient';
import { HandleApiErrors } from '@shared/utils/HandleApiErrors';

import { useLoader } from '@modules/globals/hooks/useLoader';

import { ILicenseContext } from '@modules/licenses/types/Licenses/context';
import { ILicenseToSubscribe } from '@modules/licenses/types/Licenses/license';

const LicenseContext = createContext({} as ILicenseContext);
LicenseContext.displayName = 'Permissions';

const LicenseProvider: FC<PropsWithChildren> = ({ children }) => {
  const { startLoad, endLoad } = useLoader();

  const [licenses, setLicenses] = useState<ILicenseToSubscribe[]>([]);

  const getLicensesToSubscribe = useCallback(async () => {
    try {
      startLoad();

      const response = await PaymentsClient.licenses().getLicensesToSubscribe();

      setLicenses(response.data);
    } catch (err) {
      HandleApiErrors.handle({ err });
    } finally {
      endLoad();
    }
  }, [endLoad, startLoad]);

  const contextValue = useMemo<ILicenseContext>(
    () => ({ getLicensesToSubscribe, licenses }),
    [getLicensesToSubscribe, licenses],
  );

  return <LicenseContext.Provider value={contextValue}>{children}</LicenseContext.Provider>;
};

export { LicenseContext, LicenseProvider };
