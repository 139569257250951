import { FC, PropsWithChildren, ReactElement, useMemo } from 'react';
import { isDesktop } from 'react-device-detect';

import { CheckCircle, Info, Warning, XCircle } from '@phosphor-icons/react';
import { Toaster, ToasterProps, toast as toastFn } from 'sonner';

import { useTheme } from '@modules/globals/hooks/useTheme';

type ToastType = 'error' | 'info' | 'success' | 'warning';

const Toast: FC<PropsWithChildren<ToasterProps>> = (props): ReactElement => {
  const { theme } = useTheme();

  const position = useMemo(() => (isDesktop ? 'top-right' : 'top-center'), []);

  return (
    <Toaster
      position={position}
      theme={theme}
      richColors
      gap={8}
      toastOptions={{
        duration: 2500,
        classNames: {
          title: 'font-sans leading-none antialiased',
          toast: 'rounded-lg shadow-md',
        },
      }}
      icons={{
        error: <XCircle size={16} weight="fill" />,
        info: <Info size={16} weight="fill" />,
        success: <CheckCircle size={16} weight="fill" />,
        warning: <Warning size={16} weight="fill" />,
      }}
      {...props}
    />
  );
};

const toast = (message?: string, options?: { type: ToastType }): void => {
  if (options?.type === 'error') toastFn.error(message);
  if (options?.type === 'info') toastFn.info(message);
  if (options?.type === 'success') toastFn.success(message);
  if (options?.type === 'warning') toastFn.warning(message);
};

export { toast, Toast };
