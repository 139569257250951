import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { createContext } from 'use-context-selector';

import { CompaniesClient } from '@shared/clients/http/CompaniesClient';
import { toast } from '@shared/components/Toast';
import { IPaginateDTO } from '@shared/dtos/IPaginateDTO';
import { HandleApiErrors } from '@shared/utils/HandleApiErrors';

import { useLoader } from '@modules/globals/hooks/useLoader';

import { IHorseHorseshoeContext } from '@modules/horses/types/Horseshoes/context';
import { IFilterHorseshoes, IHorseHorseshoe } from '@modules/horses/types/Horseshoes/horseshoes';
import {
  IFindHorseHorseshoesByHorseIdRequest,
  ICreateHorseHorseshoeRequest,
  IDeleteHorseHorseshoeRequest,
} from '@modules/horses/types/Horseshoes/requests';

const HorseHorseshoesContext = createContext({} as IHorseHorseshoeContext);
HorseHorseshoesContext.displayName = 'HorsesHorseshoes';

const HorseHorseshoesProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation('horses', { keyPrefix: 'messages' });

  const { startLoad, endLoad } = useLoader();

  const [horseshoes, setHorseshoes] = useState({} as IPaginateDTO<IHorseHorseshoe>);
  const [filterHorseshoes, setFilterHorseshoes] = useState({} as IFilterHorseshoes);

  const getHorseshoes = useCallback(
    async (data: IFindHorseHorseshoesByHorseIdRequest) => {
      try {
        startLoad();

        const response = await CompaniesClient.horseHorseshoes().getHorseHorseshoes(data);

        setHorseshoes(response.data);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const createHorseshoes = useCallback(
    async (data: ICreateHorseHorseshoeRequest) => {
      try {
        startLoad();

        await CompaniesClient.horseHorseshoes().createHorseshoe(data);

        await getHorseshoes(filterHorseshoes);

        toast(t('horse_horseshoe_created_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, filterHorseshoes, getHorseshoes, startLoad, t],
  );

  const deleteHorseshoes = useCallback(
    async (data: IDeleteHorseHorseshoeRequest) => {
      try {
        startLoad();

        await CompaniesClient.horseHorseshoes().deleteHorseshoe(data);

        await getHorseshoes(filterHorseshoes);

        toast(t('horse_horseshoe_deleted_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, filterHorseshoes, getHorseshoes, startLoad, t],
  );

  const handleFilterHorseshoes = useCallback((data: IFilterHorseshoes) => {
    setFilterHorseshoes(data);
  }, []);

  const contextValue = useMemo<IHorseHorseshoeContext>(
    () => ({ createHorseshoes, deleteHorseshoes, filterHorseshoes, getHorseshoes, handleFilterHorseshoes, horseshoes }),
    [createHorseshoes, deleteHorseshoes, filterHorseshoes, getHorseshoes, handleFilterHorseshoes, horseshoes],
  );

  return <HorseHorseshoesContext.Provider value={contextValue}>{children}</HorseHorseshoesContext.Provider>;
};

export { HorseHorseshoesProvider, HorseHorseshoesContext };
