import { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';

import { createContext } from 'use-context-selector';

import { CompaniesClient } from '@shared/clients/http/CompaniesClient';
import { HandleApiErrors } from '@shared/utils/HandleApiErrors';

import { IBalanceReportContext } from '@modules/balances/types/Reports/context';
import {
  IFilterReportBalances,
  IReportBalancesByCategories,
  IReportEarningsExpenses,
  IReportExport,
} from '@modules/balances/types/Reports/reports';
import {
  IGetReportBalancesByCategoriesRequest,
  IGetReportEarningsExpensesDailyRequest,
  IGetReportEarningsExpensesMonthlyRequest,
} from '@modules/balances/types/Reports/requests';

import { useLoader } from '@modules/globals/hooks/useLoader';

const BalanceReportContext = createContext({} as IBalanceReportContext);
BalanceReportContext.displayName = 'BalanceReport';

const BalanceReportProvider: FC<PropsWithChildren> = ({ children }) => {
  const { startLoad, endLoad } = useLoader();

  const [filterReportBalances, setFilterReportBalances] = useState({} as IFilterReportBalances);
  const [reportBalancesByCategories, setReportBalancesByCategories] = useState({} as IReportBalancesByCategories);
  const [reportBalancesEarningsExpenses, setReportBalancesEarningsExpenses] = useState<IReportEarningsExpenses[]>([]);
  const [reportActiveToExport, setReportActiveToExport] = useState<IReportExport>('EARNINGS_EXPENSES_DAILY');

  const getReportBalancesByCategories = useCallback(
    async (data: IGetReportBalancesByCategoriesRequest) => {
      try {
        startLoad();

        const response = await CompaniesClient.balancesReports().getReportBalancesByCategories(data);

        setReportBalancesByCategories(response.data);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const getReportEarningsExpensesDaily = useCallback(
    async (data: IGetReportEarningsExpensesDailyRequest) => {
      try {
        startLoad();

        const response = await CompaniesClient.balancesReports().getReportEarningsExpensesDaily(data);

        setReportBalancesEarningsExpenses(response.data);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const getReportEarningsExpensesMonthly = useCallback(
    async (data: IGetReportEarningsExpensesMonthlyRequest) => {
      try {
        startLoad();

        const response = await CompaniesClient.balancesReports().getReportEarningsExpensesMonthly(data);

        setReportBalancesEarningsExpenses(response.data);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const exportReportBalancesByCategoriesCsv = useCallback(
    async (data: IGetReportBalancesByCategoriesRequest) => {
      try {
        startLoad();

        await CompaniesClient.balancesReports().exportBalancesByCategoriesCsv(data);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const exportReportBalancesByCategoriesPdf = useCallback(
    async (data: IGetReportBalancesByCategoriesRequest) => {
      try {
        startLoad();

        await CompaniesClient.balancesReports().exportBalancesByCategoriesPdf(data);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const exportReportEarningsExpensesDailyCsv = useCallback(
    async (data: IGetReportEarningsExpensesDailyRequest) => {
      try {
        startLoad();

        await CompaniesClient.balancesReports().exportEarningsExpensesDailyCsv(data);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const exportReportEarningsExpensesDailyPdf = useCallback(
    async (data: IGetReportEarningsExpensesDailyRequest) => {
      try {
        startLoad();

        await CompaniesClient.balancesReports().exportEarningsExpensesDailyPdf(data);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const exportReportEarningsExpensesMonthlyCsv = useCallback(
    async (data: IGetReportEarningsExpensesMonthlyRequest) => {
      try {
        startLoad();

        await CompaniesClient.balancesReports().exportEarningsExpensesMonthlyCsv(data);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const exportReportEarningsExpensesMonthlyPdf = useCallback(
    async (data: IGetReportEarningsExpensesMonthlyRequest) => {
      try {
        startLoad();

        await CompaniesClient.balancesReports().exportEarningsExpensesMonthlyPdf(data);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const handleFilterReportBalances = useCallback((data: Partial<IFilterReportBalances>, reset?: boolean) => {
    setFilterReportBalances(current => (reset ? (data as IFilterReportBalances) : { ...current, ...data }));
  }, []);

  const handleReportActiveToExport = useCallback((tab: IReportExport) => {
    setReportActiveToExport(tab);
  }, []);

  const contextValue = useMemo<IBalanceReportContext>(
    () => ({
      exportReportBalancesByCategoriesCsv,
      exportReportBalancesByCategoriesPdf,
      exportReportEarningsExpensesDailyCsv,
      exportReportEarningsExpensesDailyPdf,
      exportReportEarningsExpensesMonthlyCsv,
      exportReportEarningsExpensesMonthlyPdf,
      filterReportBalances,
      getReportBalancesByCategories,
      getReportEarningsExpensesDaily,
      getReportEarningsExpensesMonthly,
      handleFilterReportBalances,
      handleReportActiveToExport,
      reportActiveToExport,
      reportBalancesByCategories,
      reportBalancesEarningsExpenses,
    }),
    [
      exportReportBalancesByCategoriesCsv,
      exportReportBalancesByCategoriesPdf,
      exportReportEarningsExpensesDailyCsv,
      exportReportEarningsExpensesDailyPdf,
      exportReportEarningsExpensesMonthlyCsv,
      exportReportEarningsExpensesMonthlyPdf,
      filterReportBalances,
      getReportBalancesByCategories,
      getReportEarningsExpensesDaily,
      getReportEarningsExpensesMonthly,
      handleFilterReportBalances,
      handleReportActiveToExport,
      reportActiveToExport,
      reportBalancesByCategories,
      reportBalancesEarningsExpenses,
    ],
  );

  return <BalanceReportContext.Provider value={contextValue}>{children}</BalanceReportContext.Provider>;
};

export { BalanceReportContext, BalanceReportProvider };
