import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import EquitesIconWhite from '@shared/assets/images/icon-white.webp';
import EquitesIcon from '@shared/assets/images/icon.webp';

import { useTheme } from '@modules/globals/hooks/useTheme';

import { Text } from '../Text';

const FallbackNavigation = (): ReactElement => {
  const { t } = useTranslation('globals');

  const { theme } = useTheme();

  const Icon = theme === 'dark' ? EquitesIconWhite : EquitesIcon;

  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <img src={Icon} alt="Equites" className="mb-8 w-16 transition-all duration-200" />

      <Text className="text-black-400 dark:text-white">{t('we_are_loading_you_page')}</Text>
    </div>
  );
};

FallbackNavigation.displayName = 'Fallback.Navigation';

export const Fallback = {
  Navigation: FallbackNavigation,
};
