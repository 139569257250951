import { lazily } from 'react-lazily';

import { IEquitesRouteObject } from '@shared/routes';

const { AppointmentsCreate } = lazily(() => import('@modules/appointments/pages/AppointmentsCreate'));
const { AppointmentsEdit } = lazily(() => import('@modules/appointments/pages/AppointmentsEdit'));
const { AppointmentsHistory } = lazily(() => import('@modules/appointments/pages/AppointmentsHistory'));
const { AppointmentsList } = lazily(() => import('@modules/appointments/pages/AppointmentsList'));
const { AppointmentsFromRuleEdit } = lazily(() => import('@modules/appointments/pages/AppointmentsFromRuleEdit'));
const { AppointmentsRequests } = lazily(() => import('@modules/appointments/pages/AppointmentsRequests'));
const { AppointmentsRequestsEdit } = lazily(() => import('@modules/appointments/pages/AppointmentsRequestsEdit'));

const { BalancesCategories } = lazily(() => import('@modules/balances/pages/BalancesCategories'));
const { BalancesConciliation } = lazily(() => import('@modules/balances/pages/BalancesConciliation'));
const { BalancesDashboard } = lazily(() => import('@modules/balances/pages/BalancesDashboard'));
const { BalancesInvoices } = lazily(() => import('@modules/balances/pages/BalancesInvoices'));
const { BalancesMovements } = lazily(() => import('@modules/balances/pages/BalancesMovements'));
const { BalancesReports } = lazily(() => import('@modules/balances/pages/BalancesReports'));
const { BalancesWallets } = lazily(() => import('@modules/balances/pages/BalancesWallets'));

const { Configurations } = lazily(() => import('@modules/configurations/pages/Configurations'));
const { ConfigurationsAppointments } = lazily(() => import('@modules/configurations/pages/ConfigurationsAppointments'));
const { ConfigurationsFinancial } = lazily(() => import('@modules/configurations/pages/ConfigurationsFinancial'));
const { ConfigurationsGeneral } = lazily(() => import('@modules/configurations/pages/ConfigurationsGeneral'));
const { ConfigurationsGroupPermissionsCreate } = lazily(
  () => import('@modules/configurations/pages/ConfigurationsGroupPermissionsCreate'),
);
const { ConfigurationsGroupPermissionsUpdate } = lazily(
  () => import('@modules/configurations/pages/ConfigurationsGroupPermissionsUpdate'),
);
const { ConfigurationsHorses } = lazily(() => import('@modules/configurations/pages/ConfigurationsHorses'));
const { ConfigurationsMyTeam } = lazily(() => import('@modules/configurations/pages/ConfigurationsMyTeam'));
const { ConfigurationsNotifications } = lazily(
  () => import('@modules/configurations/pages/ConfigurationsNotifications'),
);

const { ContactsEdit } = lazily(() => import('@modules/contacts/pages/ContactsEdit'));
const { ContactsList } = lazily(() => import('@modules/contacts/pages/ContactsList'));
const { ContactsProfile } = lazily(() => import('@modules/contacts/pages/ContactsProfile'));

const { Dashboard } = lazily(() => import('@modules/dashboard/pages/Dashboard'));

const { HorsesEdit } = lazily(() => import('@modules/horses/pages/HorsesEdit'));
const { HorsesFeeding } = lazily(() => import('@modules/horses/pages/HorsesFeeding'));
const { HorsesFeedingAuto } = lazily(() => import('@modules/horses/pages/HorsesFeedingAuto'));
const { HorsesFeedingManual } = lazily(() => import('@modules/horses/pages/HorsesFeedingManual'));
const { HorsesFeedingDetailed } = lazily(() => import('@modules/horses/pages/HorsesFeedingDetailed'));
const { HorsesList } = lazily(() => import('@modules/horses/pages/HorsesList'));
const { HorsesManage } = lazily(() => import('@modules/horses/pages/HorsesManage'));
const { HorsesProfile } = lazily(() => import('@modules/horses/pages/HorsesProfile'));

const { OwnersEdit } = lazily(() => import('@modules/owners/pages/OwnersEdit'));
const { OwnersList } = lazily(() => import('@modules/owners/pages/OwnersList'));

const { NotificationsList } = lazily(() => import('@modules/notifications/pages/NotificationsList'));

const { PlansCreate } = lazily(() => import('@modules/plans/pages/PlansCreate'));
const { PlansEdit } = lazily(() => import('@modules/plans/pages/PlansEdit'));
const { PlansList } = lazily(() => import('@modules/plans/pages/PlansList'));

const { Profile } = lazily(() => import('@modules/profiles/pages/Profile'));
const { ProfileEdit } = lazily(() => import('@modules/profiles/pages/ProfileEdit'));

const { RidersEdit } = lazily(() => import('@modules/riders/pages/RidersEdit'));
const { RidersFollowUp } = lazily(() => import('@modules/riders/pages/RidersFollowUp'));
const { RidersList } = lazily(() => import('@modules/riders/pages/RidersList'));
const { RidersPlansHistory } = lazily(() => import('@modules/riders/pages/RidersPlansHistory'));
const { RidersProfile } = lazily(() => import('@modules/riders/pages/RidersProfile'));

const { SubscriptionView } = lazily(() => import('@modules/subscriptions/pages/SubscriptionView'));
const { SubscriptionUpdate } = lazily(() => import('@modules/subscriptions/pages/SubscriptionUpdate'));
const { SubscriptionInvoicesHistory } = lazily(
  () => import('@modules/subscriptions/pages/SubscriptionInvoicesHistory'),
);

const { TeachersForm } = lazily(() => import('@modules/teachers/pages/TeachersForm'));
const { TeachersList } = lazily(() => import('@modules/teachers/pages/TeachersList'));

const { WarehouseFoodsEdit } = lazily(() => import('@modules/warehouse/pages/WarehouseFoodsEdit'));
const { WarehouseFoodsList } = lazily(() => import('@modules/warehouse/pages/WarehouseFoodsList'));

export class EquitesRoutes {
  public static privateRoutes: IEquitesRouteObject[] = [
    // App dashboard
    { path: '/', element: <Dashboard />, title: 'dashboard' },

    // Appointments
    { path: '/appointments/calendar', element: <AppointmentsList />, title: 'appointments_list' },
    { path: '/appointments/history', element: <AppointmentsHistory />, title: 'appointments_history' },
    { path: '/appointments/schedule', element: <AppointmentsCreate />, title: 'appointments_schedule' },
    { path: '/appointments/:appointmentId/edit', element: <AppointmentsEdit />, title: 'appointments_edit' },
    {
      path: '/appointments/rules/:appointmentRuleId/edit',
      element: <AppointmentsFromRuleEdit />,
      title: 'appointments_edit_from_rules',
    },
    { path: '/appointments/requests', element: <AppointmentsRequests />, title: 'appointments_requests_list' },
    {
      path: '/appointments/requests/:appointmentId/edit',
      element: <AppointmentsRequestsEdit />,
      title: 'appointments_requests_edit',
    },

    // Balances
    { path: '/balances/categories', element: <BalancesCategories />, title: 'balances_categories' },
    { path: '/balances/conciliation', element: <BalancesConciliation />, title: 'balances_conciliation' },
    { path: '/balances/dashboard', element: <BalancesDashboard />, title: 'balances_dashboard' },
    { path: '/balances/invoices', element: <BalancesInvoices />, title: 'balances_invoices' },
    { path: '/balances/movements', element: <BalancesMovements />, title: 'balances_movements' },
    { path: '/balances/reports', element: <BalancesReports />, title: 'balances_reports' },
    { path: '/balances/wallets', element: <BalancesWallets />, title: 'balances_wallets' },

    // Configurations
    {
      path: '/configurations',
      element: <Configurations />,
      title: 'configurations',
    },
    {
      path: '/configurations/appointments',
      element: <ConfigurationsAppointments />,
      title: 'configurations_appointments',
    },
    {
      path: '/configurations/financial',
      element: <ConfigurationsFinancial />,
      title: 'configurations_financial',
    },
    {
      path: '/configurations/general',
      element: <ConfigurationsGeneral />,
      title: 'configurations_general',
    },
    {
      path: '/configurations/horses',
      element: <ConfigurationsHorses />,
      title: 'configurations_horses',
    },
    {
      path: '/configurations/my-team',
      element: <ConfigurationsMyTeam />,
      title: 'configurations_my_team',
    },
    {
      path: '/configurations/my-team/group-permissions/new',
      element: <ConfigurationsGroupPermissionsCreate />,
      title: 'configurations_group_permissions_create',
    },
    {
      path: '/configurations/my-team/group-permissions/:groupPermissionsId/edit',
      element: <ConfigurationsGroupPermissionsUpdate />,
      title: 'configurations_group_permissions_edit',
    },
    {
      path: '/configurations/notifications',
      element: <ConfigurationsNotifications />,
      title: 'configurations_notifications',
    },

    // Contacts
    { path: '/contacts', element: <ContactsList />, title: 'contacts_list' },
    { path: '/contacts/:contactId/edit', element: <ContactsEdit />, title: 'contacts_edit' },
    { path: '/contacts/:contactId/profile', element: <ContactsProfile />, title: 'contacts_profile' },

    // Horses
    { path: '/stable/horses', element: <HorsesList />, title: 'horses_list' },
    { path: '/stable/horses/:horseId/edit', element: <HorsesEdit />, title: 'horses_edit' },
    { path: '/stable/horses/:horseId/manage', element: <HorsesManage />, title: 'horses_manage' },
    { path: '/stable/horses/:horseId/profile', element: <HorsesProfile />, title: 'horses_profile' },
    { path: '/stable/horses/feeding', element: <HorsesFeeding />, title: 'horses_feeding' },
    { path: '/stable/horses/feeding/auto', element: <HorsesFeedingAuto />, title: 'horses_feeding_auto' },
    { path: '/stable/horses/feeding/manual', element: <HorsesFeedingManual />, title: 'horses_feeding_manual' },
    { path: '/stable/horses/owners', element: <OwnersList />, title: 'horses_owners_list' },
    { path: '/stable/horses/owners/:ownerId/edit', element: <OwnersEdit />, title: 'horses_owners_edit' },

    // Notifications
    { path: '/notifications', element: <NotificationsList />, title: 'notifications' },

    // Notifications
    { path: '/plans', element: <PlansList />, title: 'plans_list' },
    { path: '/plans/new', element: <PlansCreate />, title: 'plans_create' },
    { path: '/plans/:planId/edit', element: <PlansEdit />, title: 'plans_edit' },

    // Profile
    { path: '/profile', element: <Profile />, title: 'profile' },
    { path: '/profile/edit', element: <ProfileEdit />, title: 'profile_edit' },

    // Riders
    { path: '/riders', element: <RidersList />, title: 'riders' },
    { path: '/riders/:riderId/edit', element: <RidersEdit />, title: 'riders_edit' },
    { path: '/riders/:riderId/follow-up', element: <RidersFollowUp />, title: 'riders_follow_up' },
    { path: '/riders/:riderId/follow-up/plans', element: <RidersPlansHistory />, title: 'riders_plans_history' },
    { path: '/riders/:riderId/profile', element: <RidersProfile />, title: 'riders_profile' },

    // Teachers
    { path: '/teachers', element: <TeachersList />, title: 'teachers_list' },
    { path: '/teachers/new', element: <TeachersForm />, title: 'teachers_new' },
    { path: '/teachers/:teacherId/edit', element: <TeachersForm />, title: 'teachers_edit' },

    // Subscriptions
    { path: '/subscriptions', element: <SubscriptionView />, title: 'my_subscription' },
    { path: '/subscriptions/edit', element: <SubscriptionUpdate />, title: 'my_subscription_update' },
    { path: '/subscriptions/invoices', element: <SubscriptionInvoicesHistory />, title: 'my_subscription_invoices' },

    // Warehouse
    { path: '/warehouse/foods', element: <WarehouseFoodsList />, title: 'warehouse_foods_list' },
    { path: '/warehouse/foods/:foodId/edit', element: <WarehouseFoodsEdit />, title: 'warehouse_foods_edit' },
    { path: '/warehouse/foods/detailed', element: <HorsesFeedingDetailed />, title: 'warehouse_foods_detailed' },
  ];
}
