import { useContextSelector } from 'use-context-selector';

import { AuthContext } from '../contexts/Auth';
import { IAuthContext } from '../types/Auth/context';

export function useAuth(): IAuthContext {
  const isAuthenticated = useContextSelector(AuthContext, cs => cs.isAuthenticated);
  const permissions = useContextSelector(AuthContext, cs => cs.permissions);
  const tokens = useContextSelector(AuthContext, cs => cs.tokens);

  const signInByTokens = useContextSelector(AuthContext, cs => cs.signInByTokens);
  const signOut = useContextSelector(AuthContext, cs => cs.signOut);

  return { isAuthenticated, permissions, signInByTokens, signOut, tokens };
}
