import { AxiosInstance, AxiosResponse } from 'axios';

import {
  ICreateTeacherRequest,
  ICreateTeacherResponse,
  IDeleteTeacherRequest,
  IUpdateTeacherRequest,
  IUpdateTeacherResponse,
  IUpdateTeacherAvatarRequest,
  IUpdateTeacherAvatarResponse,
  IFindTeacherByIdRequest,
} from '@modules/teachers/types/Teachers/requests';
import { ITeacher, ITeacherSummary } from '@modules/teachers/types/Teachers/teacher';

export class Teachers {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Teachers ----------

  createTeacher(data: ICreateTeacherRequest): Promise<AxiosResponse<ICreateTeacherResponse>> {
    return this.instance.post(`/teachers`, data);
  }

  deleteTeacher(data: IDeleteTeacherRequest): Promise<AxiosResponse<void>> {
    const { teacherId } = data;
    return this.instance.delete(`/teachers/${teacherId}`);
  }

  findTeacherById(data: IFindTeacherByIdRequest): Promise<AxiosResponse<ITeacher>> {
    const { teacherId } = data;
    return this.instance.get(`/teachers/${teacherId}`);
  }

  updateTeacher(data: IUpdateTeacherRequest): Promise<AxiosResponse<IUpdateTeacherResponse>> {
    const { id, ...body } = data;
    return this.instance.patch(`/teachers/${id}`, body);
  }

  // ---------- Avatar ----------

  updateTeacherAvatar(data: IUpdateTeacherAvatarRequest): Promise<AxiosResponse<IUpdateTeacherAvatarResponse>> {
    const { teacherId, file } = data;

    const formData = new FormData();
    formData.append('avatar', file);

    return this.instance.patch(`/teachers/${teacherId}/avatar`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  // ---------- Summary ----------

  async getTeachersSummary(): Promise<ITeacherSummary[]> {
    const response = await this.instance.get(`/teachers/summary`);

    return response.data;
  }
}

export const teachers = (instance: AxiosInstance): Teachers => new Teachers(instance);
