import { AxiosInstance, AxiosResponse } from 'axios';
import fileDownload from 'js-file-download';

import { IPaginateDTO } from '@shared/dtos/IPaginateDTO';
import { openPdf } from '@shared/utils/OpenPDF';

import { IRiderPayment, IRiderTotalPayments } from '@modules/riders/types/Payments/payments';
import {
  ICreateRiderPaymentRequest,
  IDeleteRiderPaymentRequest,
  IFindRidersPaymentsRequest,
  IFindRidersTotalPaymentsRequest,
  IUpdateRiderPaymentRequest,
} from '@modules/riders/types/Payments/requests';

export class RiderPayments {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Rider payments ----------

  async createPayment(data: ICreateRiderPaymentRequest): Promise<IRiderPayment> {
    const { riderId, ...body } = data;

    const response = await this.instance.post(`/riders/${riderId}/payments`, body);

    return response.data;
  }

  async deletePayment(data: IDeleteRiderPaymentRequest): Promise<void> {
    const { riderId, riderPaymentId } = data;

    await this.instance.delete(`/riders/${riderId}/payments/${riderPaymentId}`);
  }

  async find(params: IFindRidersPaymentsRequest): Promise<IPaginateDTO<IRiderPayment>> {
    const response = await this.instance.get(`/riders/payments`, { params });

    return response.data;
  }

  findTotal(params: IFindRidersTotalPaymentsRequest): Promise<AxiosResponse<IRiderTotalPayments>> {
    return this.instance.get(`/riders/payments/totals`, { params });
  }

  async updatePayment(data: IUpdateRiderPaymentRequest): Promise<IRiderPayment> {
    const { riderId, riderPaymentId, ...body } = data;

    const response = await this.instance.patch(`/riders/${riderId}/payments/${riderPaymentId}`, body);

    return response.data;
  }

  // ---------- Export rider payments ----------

  async exportPaymentsCsv(params: IFindRidersPaymentsRequest): Promise<AxiosResponse<void>> {
    const url = `/riders/payments/report/csv/export`;
    const { data, ...response } = await this.instance.get(url, { params });

    fileDownload(data, `rider-payments-${params.from}-to-${params.to}.csv`);

    return { data, ...response };
  }

  async exportPaymentsPdf(params: IFindRidersPaymentsRequest): Promise<AxiosResponse<void>> {
    const url = `/riders/payments/report/pdf/export`;
    const { data, ...response } = await this.instance.get(url, { params, responseType: 'blob' });

    openPdf(data);

    return { data, ...response };
  }
}

export const riderPayments = (instance: AxiosInstance): RiderPayments => new RiderPayments(instance);
