import { AxiosInstance } from 'axios';

import { openPdf } from '@shared/utils/OpenPDF';

import { ExportInvoiceItemsStatementPdfRequest } from '@modules/invoices/types/Invoices/requests';

export class InvoicesReports {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Invoice statement ----------

  async exportStatementPdf({ invoiceId }: ExportInvoiceItemsStatementPdfRequest): Promise<void> {
    const { data } = await this.instance.get(`/invoices/${invoiceId}/statement/pdf/export`, { responseType: 'blob' });

    openPdf(data);
  }
}

export const invoicesReports = (instance: AxiosInstance): InvoicesReports => new InvoicesReports(instance);
