import { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';

import { endOfMonth, format, startOfDay, startOfMonth } from 'date-fns';
import { createContext } from 'use-context-selector';

import { PaymentsClient } from '@shared/clients/http/PaymentsClient';
import { HandleApiErrors } from '@shared/utils/HandleApiErrors';

import { useLoader } from '@modules/globals/hooks/useLoader';

import { IConciliationDashboard } from '@modules/invoices/types/Conciliation/conciliation';
import { IConciliationContext } from '@modules/invoices/types/Conciliation/context';
import { IGetConciliationDashboardRequest } from '@modules/invoices/types/Conciliation/requests';

const ConciliationContext = createContext({} as IConciliationContext);
ConciliationContext.displayName = 'Conciliation';

const ConciliationProvider: FC<PropsWithChildren> = ({ children }) => {
  const { startLoad, endLoad } = useLoader();

  const [conciliationDashboard, setConciliationDashboard] = useState({} as IConciliationDashboard);
  const [filterConciliation, setFilterConciliation] = useState<IGetConciliationDashboardRequest>({
    date: format(startOfDay(new Date()), 'yyyy-MM-dd'),
    from: format(startOfDay(startOfMonth(new Date())), 'yyyy-MM-dd'),
    limit: 10,
    origin: ['RIDER'],
    page: 1,
    to: format(startOfDay(endOfMonth(new Date())), 'yyyy-MM-dd'),
  });

  const getConciliationDashboard = useCallback(
    async (data: IGetConciliationDashboardRequest) => {
      try {
        startLoad();

        const response = await PaymentsClient.conciliation().getConciliationDashboard(data);

        setConciliationDashboard(response.data);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const handleFilterConciliation = useCallback((data: IGetConciliationDashboardRequest, reset?: boolean) => {
    setFilterConciliation(current => (reset ? (data as IGetConciliationDashboardRequest) : { ...current, ...data }));
  }, []);

  const contextValue = useMemo<IConciliationContext>(
    () => ({ conciliationDashboard, filterConciliation, getConciliationDashboard, handleFilterConciliation }),
    [conciliationDashboard, filterConciliation, getConciliationDashboard, handleFilterConciliation],
  );

  return <ConciliationContext.Provider value={contextValue}>{children}</ConciliationContext.Provider>;
};

export { ConciliationContext, ConciliationProvider };
