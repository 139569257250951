import { AxiosInstance, AxiosResponse } from 'axios';

import { ICustomer } from '@modules/payments/types/Customers/customers';
import { ICreateCustomerRequest } from '@modules/payments/types/Customers/requests';

export class Customers {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Customers ----------

  createCustomer(data: ICreateCustomerRequest): Promise<AxiosResponse<ICustomer>> {
    return this.instance.post(`/customers/me`, data);
  }
}

export const customers = (instance: AxiosInstance): Customers => new Customers(instance);
