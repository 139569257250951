import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { createContext } from 'use-context-selector';

import { CompaniesClient } from '@shared/clients/http/CompaniesClient';
import { toast } from '@shared/components/Toast';
import { HandleApiErrors } from '@shared/utils/HandleApiErrors';

import { useLoader } from '@modules/globals/hooks/useLoader';

import { IRiderNotificationContext } from '@modules/riders/types/Notifications/context';
import { IRequestProfileUpdateRequest } from '@modules/riders/types/Notifications/requests';

const RidersNotificationsContext = createContext({} as IRiderNotificationContext);
RidersNotificationsContext.displayName = 'RidersNotifications';

const RidersNotificationsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation('riders', { keyPrefix: 'messages' });

  const { startLoad, endLoad } = useLoader();

  const notificationRequestProfileUpdate = useCallback(
    async (data: IRequestProfileUpdateRequest) => {
      try {
        startLoad();

        await CompaniesClient.riderNotifications().notificationsRequestProfileUpdate(data);

        toast(t('rider_notification_sended_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad, t],
  );

  const contextValue = useMemo<IRiderNotificationContext>(
    () => ({ notificationRequestProfileUpdate }),
    [notificationRequestProfileUpdate],
  );

  return <RidersNotificationsContext.Provider value={contextValue}>{children}</RidersNotificationsContext.Provider>;
};

export { RidersNotificationsContext, RidersNotificationsProvider };
