import { AxiosInstance } from 'axios';

import { IPaginateDTO } from '@shared/dtos/IPaginateDTO';

import { HorseFeed, HorseFeedConfigGrouped } from '@modules/horses/types/Feeds/horseFeeds';
import {
  CreateHorsesFeedsRequest,
  CreateHorsesFeedsResponse,
  DeleteHorseFeedConfigRequest,
  DeleteHorseFeedFoodRequest,
  FindHorseFeedsConfigGroupedRequest,
  FindHorseFeedsFoodsByDateRequest,
  FindHorseFeedsFoodsByHorseIdItemResponse,
  FindHorseFeedsFoodsByHorseIdRequest,
  FindHorsesToFeedResponseDTO,
  UpdateHorseFeedFoodRequest,
} from '@modules/horses/types/Feeds/requests';

type IGetHorseFeedsFoodsResponse = Promise<IPaginateDTO<FindHorseFeedsFoodsByHorseIdItemResponse>>;

export class HorseFeeds {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Feeds ----------

  async createHorsesFeeds(data: CreateHorsesFeedsRequest): Promise<CreateHorsesFeedsResponse> {
    const response = await this.instance.post(`/horses/feeds`, data);

    return response.data;
  }

  async getHorsesFeedsGrouped(data: FindHorseFeedsConfigGroupedRequest): Promise<IPaginateDTO<HorseFeedConfigGrouped>> {
    const response = await this.instance.get(`/horses/feeds`, { params: data });

    return response.data;
  }

  async deleteHorseFeedGrouped({ configId }: DeleteHorseFeedConfigRequest): Promise<void> {
    await this.instance.delete(`/horses/feeds/${configId}`);
  }

  // ---------- Horses to feed ----------

  async getHorsesToFeed(): Promise<FindHorsesToFeedResponseDTO> {
    const response = await this.instance.get(`/horses/to-feeds`);

    return response.data;
  }

  // ---------- Horse feed handle ----------

  async deleteHorseFeed(data: DeleteHorseFeedFoodRequest): Promise<void> {
    const { horseId, feedFoodId } = data;

    await this.instance.delete(`/horses/${horseId}/feeds/${feedFoodId}`);
  }

  async updateHorseFeed(data: UpdateHorseFeedFoodRequest): Promise<HorseFeed> {
    const { horseId, feedFoodId, ...body } = data;

    const response = await this.instance.patch(`/horses/${horseId}/feeds/${feedFoodId}`, body);

    return response.data;
  }

  // ---------- Feeds history by horse ----------

  async getHorseFeeds(data: FindHorseFeedsFoodsByHorseIdRequest): IGetHorseFeedsFoodsResponse {
    const { horseId, ...params } = data;

    const response = await this.instance.get(`/horses/${horseId}/feeds`, { params });

    return response.data;
  }

  // ---------- Feeds history by date ----------

  async getFeedsByDate(params: FindHorseFeedsFoodsByDateRequest): IGetHorseFeedsFoodsResponse {
    const response = await this.instance.get(`/horses/feeds/history/date`, { params });

    return response.data;
  }
}

export const horseFeeds = (instance: AxiosInstance): HorseFeeds => new HorseFeeds(instance);
