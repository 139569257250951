import { AxiosInstance } from 'axios';

import { ConfigurationFinancialWallet } from '@modules/configurations/types/ConfigurationsFinancial/financial';
import { UpdateInvoiceConfigWalletIdRequest } from '@modules/configurations/types/ConfigurationsFinancial/requests';

export class InvoicesConfigWallet {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  async getWalletId(): Promise<ConfigurationFinancialWallet> {
    const response = await this.instance.get(`/invoices/config/wallet-id`);

    return response.data;
  }

  async updateWalletId(data: UpdateInvoiceConfigWalletIdRequest): Promise<void> {
    await this.instance.put(`/invoices/config/wallet-id`, data);
  }
}

export const invoicesConfigWallet = (instance: AxiosInstance): InvoicesConfigWallet =>
  new InvoicesConfigWallet(instance);
