/**
 * AssociationsRidersRoles
 * @readonly
 * @enum {string}
 */
export enum AssociationsRidersRoles {
  /** Create a new rider to associations. */
  CREATE_ASSOCIATION_RIDER = 'CREATE_ASSOCIATION_RIDER',

  /** Create a new rider payment to associations. */
  CREATE_ASSOCIATION_RIDER_PAYMENT = 'CREATE_ASSOCIATION_RIDER_PAYMENT',

  /** Delete a rider from associations. */
  DELETE_ASSOCIATION_RIDER = 'DELETE_ASSOCIATION_RIDER',

  /** Delete a rider payment from associations. */
  DELETE_ASSOCIATION_RIDER_PAYMENT = 'DELETE_ASSOCIATION_RIDER_PAYMENT',

  /** Export association riders list. */
  EXPORT_ASSOCIATION_RIDERS = 'EXPORT_ASSOCIATION_RIDERS',

  /** Export the riders payments as PDF or CSV. */
  EXPORT_ASSOCIATION_RIDERS_PAYMENTS = 'EXPORT_ASSOCIATION_RIDERS_PAYMENTS',

  /** Get riders from associations. */
  GET_ASSOCIATIONS_RIDERS = 'GET_ASSOCIATIONS_RIDERS',

  /** Get riders payments from associations. */
  GET_ASSOCIATIONS_RIDERS_PAYMENT = 'GET_ASSOCIATIONS_RIDERS_PAYMENT',

  /** Update a rider from association. */
  UPDATE_ASSOCIATION_RIDER = 'UPDATE_ASSOCIATION_RIDER',

  /** Update a rider payment from association. */
  UPDATE_ASSOCIATION_RIDER_PAYMENT = 'UPDATE_ASSOCIATION_RIDER_PAYMENT',
}
