import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { motion, MotionProps } from 'framer-motion';
import { tv } from 'tailwind-variants';

import { Text } from '@shared/components/Text';

import { useSidebar } from '@modules/globals/hooks/useSidebar';

interface ISidebarSubItem extends MotionProps {
  id: string;
  name: string;
  path: string;
}

const textVariants = tv({
  base: 'text-sm font-semibold leading-none',
  variants: {
    isSelected: {
      true: 'text-green-500',
      false: 'text-gray-500 dark:text-gray-400',
    },
  },
});

export const SidebarMobileSubItem = ({ id, name, path, ...props }: ISidebarSubItem): ReactElement => {
  const { menuSelected, handleMenuSelected, toggleSidebarOpened } = useSidebar();

  const { t } = useTranslation('sidebar');

  const handleClickNavigate = (): void => {
    handleMenuSelected(id);
    toggleSidebarOpened();
  };

  return (
    <motion.button type="button" {...props}>
      <Link
        to={path}
        onClick={handleClickNavigate}
        className="flex w-full rounded-lg px-4 py-2.5 hover:bg-green-50 dark:hover:bg-green-500/10"
      >
        <Text className={textVariants({ isSelected: menuSelected === id })}>{t(name)}</Text>
      </Link>
    </motion.button>
  );
};
