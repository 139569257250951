import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { createContext } from 'use-context-selector';

import { PaymentsClient } from '@shared/clients/http/PaymentsClient';
import { toast } from '@shared/components/Toast';
import { Storage } from '@shared/constants/Storage';
import { IPaginateDTO } from '@shared/dtos/IPaginateDTO';
import { HandleApiErrors } from '@shared/utils/HandleApiErrors';
import { sleep } from '@shared/utils/SleepFunction';
import { usePersistedState } from '@shared/utils/usePersistedState';

import { useLoader } from '@modules/globals/hooks/useLoader';

import { IInvoice, INextAndLastPayment } from '@modules/invoices/types/Invoices/invoices';
import { FindInvoicesFromSubscriptionDTO } from '@modules/invoices/types/Invoices/requests';

import { ISubscriptionContext } from '@modules/subscriptions/types/Subscriptions/context';
import {
  IChangeCreditCardDTO,
  IChangeLicenseSimulationDTO,
  ICreateSubscriptionDataDTO,
} from '@modules/subscriptions/types/Subscriptions/requests';
import { IChangeLicenseSimulation, ISubscription } from '@modules/subscriptions/types/Subscriptions/subscriptions';

const SubscriptionsContext = createContext({} as ISubscriptionContext);
SubscriptionsContext.displayName = 'Subscriptions';

const SubscriptionsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation('subscriptions', { keyPrefix: 'messages' });

  const { startLoad, endLoad } = useLoader();
  const navigate = useNavigate();

  const [currentSubscription, setCurrentSubscription] = usePersistedState(
    Storage.CURRENT_SUBSCRIPTION,
    {} as ISubscription,
    'LOCAL',
  );
  const [dataCreateSubscription, setDataCreateSubscription] = useState({} as ICreateSubscriptionDataDTO);
  const [licenseSimulation, setLicenseSimulation] = useState({} as IChangeLicenseSimulation);
  const [invoices, setInvoices] = useState({} as IPaginateDTO<IInvoice>);
  const [lastAndNextInvoice, setLastAndNextInvoice] = useState({} as INextAndLastPayment);
  const [showConfetti, setShowConfetti] = useState(false);

  const getCurrentSubscription = useCallback(async () => {
    try {
      startLoad();

      const response = await PaymentsClient.subscriptions().getCurrent();

      setCurrentSubscription(response.data);
    } catch (err) {
      HandleApiErrors.handle({ err });
    } finally {
      endLoad();
    }
  }, [endLoad, setCurrentSubscription, startLoad]);

  const getInvoices = useCallback(
    async (data: FindInvoicesFromSubscriptionDTO) => {
      try {
        startLoad();

        const response = await PaymentsClient.subscriptions().getInvoices(data);

        setInvoices(response.data);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const getLastAndNextInvoice = useCallback(async () => {
    try {
      startLoad();

      const response = await PaymentsClient.subscriptions().getLastAndNextInvoice();

      setLastAndNextInvoice(response.data);
    } catch (err) {
      HandleApiErrors.handle({ err });
    } finally {
      endLoad();
    }
  }, [endLoad, startLoad]);

  const activateSubscription = useCallback(async () => {
    try {
      startLoad();

      const response = await PaymentsClient.subscriptions().activateSubscription();

      setCurrentSubscription(response.data);

      toast(t('subscription_activated_success'), { type: 'success' });
    } catch (err) {
      HandleApiErrors.handle({ err });
    } finally {
      endLoad();
    }
  }, [endLoad, setCurrentSubscription, startLoad, t]);

  const cancelSubscription = useCallback(async () => {
    try {
      startLoad();

      const response = await PaymentsClient.subscriptions().cancelSubscription();

      setCurrentSubscription(response.data);

      navigate('/subscriptions');

      toast(t('subscription_suspended_success'), { type: 'success' });
    } catch (err) {
      HandleApiErrors.handle({ err });
    } finally {
      endLoad();
    }
  }, [endLoad, navigate, setCurrentSubscription, startLoad, t]);

  const changeCreditCard = useCallback(
    async (data: IChangeCreditCardDTO) => {
      try {
        startLoad();

        await PaymentsClient.subscriptions().changeCreditCard(data);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const changeLicenseSimulation = useCallback(
    async (data: IChangeLicenseSimulationDTO) => {
      try {
        startLoad();

        const response = await PaymentsClient.subscriptions().changeLicenseSimulation(data);

        setLicenseSimulation(response.data);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const upgradeSubscription = useCallback(async () => {
    try {
      startLoad();

      const response = await PaymentsClient.subscriptions().upgradeSubscription({
        creditCardId: dataCreateSubscription.creditCard.id,
        licenseId: dataCreateSubscription.license.licenseId,
      });

      setCurrentSubscription(response.data);

      toast(t('subscription_upgraded_success'), { type: 'success' });

      setShowConfetti(true);

      await sleep(2000);

      navigate('/subscriptions');
    } catch (err) {
      HandleApiErrors.handle({ err });
    } finally {
      endLoad();
      setShowConfetti(false);
    }
  }, [dataCreateSubscription, endLoad, navigate, setCurrentSubscription, startLoad, t]);

  const handleCreateSubscriptionData = useCallback((data: Partial<ICreateSubscriptionDataDTO>, reset?: boolean) => {
    setDataCreateSubscription(current => (reset ? (data as ICreateSubscriptionDataDTO) : { ...current, ...data }));
  }, []);

  const contextValue = useMemo<ISubscriptionContext>(
    () => ({
      activateSubscription,
      cancelSubscription,
      changeCreditCard,
      changeLicenseSimulation,
      currentSubscription,
      dataCreateSubscription,
      getCurrentSubscription,
      getInvoices,
      getLastAndNextInvoice,
      handleCreateSubscriptionData,
      invoices,
      lastAndNextInvoice,
      licenseSimulation,
      showConfetti,
      upgradeSubscription,
    }),
    [
      activateSubscription,
      cancelSubscription,
      changeCreditCard,
      changeLicenseSimulation,
      currentSubscription,
      dataCreateSubscription,
      getCurrentSubscription,
      getInvoices,
      getLastAndNextInvoice,
      handleCreateSubscriptionData,
      invoices,
      lastAndNextInvoice,
      licenseSimulation,
      showConfetti,
      upgradeSubscription,
    ],
  );

  return <SubscriptionsContext.Provider value={contextValue}>{children}</SubscriptionsContext.Provider>;
};

export { SubscriptionsContext, SubscriptionsProvider };
