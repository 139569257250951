import { AxiosInstance, AxiosResponse } from 'axios';

import { IConciliationDashboard } from '@modules/invoices/types/Conciliation/conciliation';
import { IGetConciliationDashboardRequest } from '@modules/invoices/types/Conciliation/requests';

export class Conciliation {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Dashboard ----------

  getConciliationDashboard(params: IGetConciliationDashboardRequest): Promise<AxiosResponse<IConciliationDashboard>> {
    return this.instance.get(`/conciliation/dashboard`, { params });
  }
}

export const conciliation = (instance: AxiosInstance): Conciliation => new Conciliation(instance);
