import { AxiosInstance, AxiosResponse } from 'axios';

import { IPaginateDTO } from '@shared/dtos/IPaginateDTO';

import { IBalance, IBalanceAmount } from '@modules/balances/types/Balances/balances';
import {
  ICreateBalanceRequest,
  ICreateBalanceTransferenceRequest,
  IDeleteBalanceAttachmentRequest,
  IDeleteBalanceRequest,
  IDeleteBalanceTransferenceAttachmentRequest,
  IDeleteBalanceTransferenceRequest,
  IFindBalancesAmountsByCompanyIdRequest,
  IFindBalancesByCompanyIdRequest,
  IUpdateBalanceAttachmentRequest,
  IUpdateBalanceRequest,
  IUpdateBalanceTransferenceAttachmentRequest,
  IUpdateBalanceTransferenceRequest,
} from '@modules/balances/types/Balances/requests';

type FindBalancesPaginatedResponse = Promise<AxiosResponse<IPaginateDTO<IBalance>>>;

export class Balances {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Balances ----------

  createBalance(data: ICreateBalanceRequest): Promise<AxiosResponse<IBalance>> {
    return this.instance.post(`/balances/movements`, data);
  }

  deleteBalance({ balanceId, ...params }: IDeleteBalanceRequest): Promise<AxiosResponse<void>> {
    return this.instance.delete(`/balances/movements/${balanceId}`, { params });
  }

  findBalancesPaginated(params: IFindBalancesByCompanyIdRequest): FindBalancesPaginatedResponse {
    return this.instance.get(`/balances/movements`, { params });
  }

  updateBalance(data: IUpdateBalanceRequest): Promise<AxiosResponse<IBalance>> {
    const { balanceId, ...body } = data;
    return this.instance.patch(`/balances/movements/${balanceId}`, body);
  }

  // ---------- Balances attachment ----------

  updateBalanceAttachment({ balanceId, file }: IUpdateBalanceAttachmentRequest): Promise<AxiosResponse<IBalance>> {
    const formData = new FormData();
    formData.append('attachment', file);

    return this.instance.patch(`/balances/movements/${balanceId}/attachment`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  deleteBalanceAttachment({ balanceId }: IDeleteBalanceAttachmentRequest): Promise<AxiosResponse<void>> {
    return this.instance.delete(`/balances/movements/${balanceId}/attachment`);
  }

  // ---------- Transference balances ----------

  createTransference(data: ICreateBalanceTransferenceRequest): Promise<AxiosResponse<IBalance[]>> {
    return this.instance.post(`/balances/transference`, data);
  }

  deleteTransference({ transferenceId }: IDeleteBalanceTransferenceRequest): Promise<AxiosResponse<void>> {
    return this.instance.delete(`/balances/transference/${transferenceId}`);
  }

  updateTransference(data: IUpdateBalanceTransferenceRequest): Promise<AxiosResponse<IBalance[]>> {
    const { transferenceId, ...body } = data;
    return this.instance.patch(`/balances/transference/${transferenceId}`, body);
  }

  // ---------- Transference balances attachment ----------

  updateTransferenceAttachment(data: IUpdateBalanceTransferenceAttachmentRequest): Promise<AxiosResponse<IBalance>> {
    const { transferenceId, file } = data;

    const formData = new FormData();
    formData.append('attachment', file);

    return this.instance.patch(`/balances/transference/${transferenceId}/attachment`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  deleteTransferenceAttachment(data: IDeleteBalanceTransferenceAttachmentRequest): Promise<AxiosResponse<void>> {
    const { transferenceId } = data;
    return this.instance.delete(`/balances/transference/${transferenceId}/attachment`);
  }

  // ---------- Balances amounts ----------

  getAmounts(params: IFindBalancesAmountsByCompanyIdRequest): Promise<AxiosResponse<IBalanceAmount>> {
    return this.instance.get(`/balances/amounts`, { params });
  }
}

export const balances = (instance: AxiosInstance): Balances => new Balances(instance);
