import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';
import { createContext } from 'use-context-selector';

import { CompaniesClient } from '@shared/clients/http/CompaniesClient';
import { toast } from '@shared/components/Toast';
import { HandleApiErrors } from '@shared/utils/HandleApiErrors';

import { useLoader } from '@modules/globals/hooks/useLoader';

import { IHorseContext } from '@modules/horses/types/Horses/contexts';
import { IHorse, IHorseProfileSummary, IHorseSummary } from '@modules/horses/types/Horses/horses';
import {
  ICreateHorseRequest,
  IDeleteHorseRequest,
  IUpdateHorsePhotoRequest,
  IUpdateHorseRequest,
} from '@modules/horses/types/Horses/requests';

const HorsesContext = createContext({} as IHorseContext);
HorsesContext.displayName = 'Horses';

const HorsesProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation('horses', { keyPrefix: 'messages' });

  const { startLoad, endLoad } = useLoader();

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const [horse, setHorse] = useState({} as IHorse);
  const [horsesSummary, setHorsesSummary] = useState<IHorseSummary[]>([]);
  const [horseProfileSummary, setHorseProfileSummary] = useState({} as IHorseProfileSummary);

  const invalidateQueries = useCallback(
    (horseId?: string) => {
      queryClient.invalidateQueries({ queryKey: ['horses:summary'], exact: false });
      queryClient.invalidateQueries({ queryKey: ['horses:list'], exact: false });
      queryClient.invalidateQueries({ queryKey: ['appointments:calendar'], exact: false });
      queryClient.invalidateQueries({ queryKey: ['appointments:id'], exact: false });
      queryClient.invalidateQueries({ queryKey: ['dashboard:home'] });
      queryClient.invalidateQueries({ queryKey: ['configurations:horses:pavilions:stalls:summary'] });
      queryClient.invalidateQueries({ queryKey: ['configurations:horses:stalls:current-stall', horseId] });
    },
    [queryClient],
  );

  const getHorseById = useCallback(
    async (horseId: string) => {
      try {
        startLoad();

        const response = await CompaniesClient.horses().getHorseById(horseId);

        setHorse(response.data);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const getHorsesSummary = useCallback(async () => {
    try {
      startLoad();

      const response = await CompaniesClient.horses().getHorsesSummary();

      setHorsesSummary(response);

      setHorse({} as IHorse);
      setHorseProfileSummary({} as IHorseProfileSummary);
    } catch (err) {
      HandleApiErrors.handle({ err });
    } finally {
      endLoad();
    }
  }, [endLoad, startLoad]);

  const getHorseProfileSummary = useCallback(
    async (horseId: string) => {
      try {
        startLoad();

        const response = await CompaniesClient.horses().getHorsesProfileSummaryById(horseId);

        setHorseProfileSummary(response.data);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const createHorse = useCallback(
    async (data: ICreateHorseRequest) => {
      try {
        startLoad();

        const response = await CompaniesClient.horses().createHorse(data);

        navigate(`/stable/horses/${response.data.id}/profile`);

        invalidateQueries();

        toast(t('horse_created_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, invalidateQueries, navigate, startLoad, t],
  );

  const deleteHorse = useCallback(
    async (data: IDeleteHorseRequest) => {
      try {
        startLoad();

        await CompaniesClient.horses().deleteHorse(data);

        invalidateQueries();

        toast(t('horse_deleted_success'), { type: 'success' });

        await getHorsesSummary();
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, getHorsesSummary, invalidateQueries, startLoad, t],
  );

  const updateHorse = useCallback(
    async (data: IUpdateHorseRequest) => {
      try {
        startLoad();

        await CompaniesClient.horses().updateHorse(data);

        invalidateQueries(data.horseId);

        toast(t('horse_updated_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, invalidateQueries, startLoad, t],
  );

  const updateHorsePhoto = useCallback(
    async (data: IUpdateHorsePhotoRequest) => {
      try {
        startLoad();

        const response = await CompaniesClient.horses().updateHorsePhoto(data);

        setHorseProfileSummary(current => ({ ...current, photoUrl: response.data.photoUrl }));

        invalidateQueries(data.horseId);

        toast(t('horse_updated_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, invalidateQueries, startLoad, t],
  );

  const contextValue = useMemo<IHorseContext>(
    () => ({
      createHorse,
      deleteHorse,
      getHorseById,
      getHorsesSummary,
      getHorseProfileSummary,
      horse,
      horseProfileSummary,
      horsesSummary,
      updateHorse,
      updateHorsePhoto,
    }),
    [
      createHorse,
      deleteHorse,
      getHorseById,
      getHorseProfileSummary,
      getHorsesSummary,
      horse,
      horseProfileSummary,
      horsesSummary,
      updateHorse,
      updateHorsePhoto,
    ],
  );

  return <HorsesContext.Provider value={contextValue}>{children}</HorsesContext.Provider>;
};

export { HorsesContext, HorsesProvider };
