import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { createContext } from 'use-context-selector';

import { CompaniesClient } from '@shared/clients/http/CompaniesClient';
import { toast } from '@shared/components/Toast';
import { IPaginateDTO } from '@shared/dtos/IPaginateDTO';
import { HandleApiErrors } from '@shared/utils/HandleApiErrors';

import { useLoader } from '@modules/globals/hooks/useLoader';

import { IHorseOdontologyContext } from '@modules/horses/types/Odontology/context';
import { IHorseOdontology, IFilterOdontology } from '@modules/horses/types/Odontology/odontology';
import {
  IFindHorseOdontologyByHorseIdRequest,
  ICreateHorseOdontologyRequest,
  IUpdateHorseOdontologyRequest,
  IDeleteHorseOdontologyRequest,
} from '@modules/horses/types/Odontology/requests';

const HorseOdontologyContext = createContext({} as IHorseOdontologyContext);
HorseOdontologyContext.displayName = 'HorsesOdontology';

const HorseOdontologyProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation('horses', { keyPrefix: 'messages' });

  const { startLoad, endLoad } = useLoader();

  const [odontology, setOdontology] = useState({} as IPaginateDTO<IHorseOdontology>);
  const [filterOdontology, setFilterOdontology] = useState({} as IFilterOdontology);

  const getHorseOdontology = useCallback(
    async (data: Partial<IFindHorseOdontologyByHorseIdRequest>) => {
      try {
        startLoad();

        const response = await CompaniesClient.horseOdontology().getHorseOdontology({ ...filterOdontology, ...data });

        setOdontology(response.data);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, filterOdontology, startLoad],
  );

  const createHorseOdontology = useCallback(
    async (data: ICreateHorseOdontologyRequest) => {
      try {
        startLoad();

        await CompaniesClient.horseOdontology().createOdontology(data);

        await getHorseOdontology(filterOdontology);

        toast(t('horse_odontology_created_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, filterOdontology, getHorseOdontology, startLoad, t],
  );

  const updateHorseOdontology = useCallback(
    async (data: IUpdateHorseOdontologyRequest) => {
      try {
        startLoad();

        await CompaniesClient.horseOdontology().updateOdontology(data);

        await getHorseOdontology(filterOdontology);

        toast(t('horse_odontology_updated_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, filterOdontology, getHorseOdontology, startLoad, t],
  );

  const deleteHorseOdontology = useCallback(
    async (data: IDeleteHorseOdontologyRequest) => {
      try {
        startLoad();

        await CompaniesClient.horseOdontology().deleteOdontology(data);

        await getHorseOdontology(filterOdontology);

        toast(t('horse_odontology_deleted_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, filterOdontology, getHorseOdontology, startLoad, t],
  );

  const handleFilterOdontology = useCallback((data: Partial<IFilterOdontology>, reset?: boolean) => {
    setFilterOdontology(current => (reset ? ({ ...data } as IFilterOdontology) : { ...current, ...data }));
  }, []);

  const contextValue = useMemo<IHorseOdontologyContext>(
    () => ({
      createHorseOdontology,
      deleteHorseOdontology,
      filterOdontology,
      getHorseOdontology,
      handleFilterOdontology,
      odontology,
      updateHorseOdontology,
    }),
    [
      createHorseOdontology,
      deleteHorseOdontology,
      filterOdontology,
      getHorseOdontology,
      handleFilterOdontology,
      odontology,
      updateHorseOdontology,
    ],
  );

  return <HorseOdontologyContext.Provider value={contextValue}>{children}</HorseOdontologyContext.Provider>;
};

export { HorseOdontologyProvider, HorseOdontologyContext };
