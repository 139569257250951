import { AxiosInstance } from 'axios';

import { RiderFinancialOfficer as FinancialOfficer } from '@modules/riders/types/FinancialOfficer/financialOfficer';
import {
  CreateOrUpdateRiderFinancialOfficerRequest,
  DeleteRiderFinancialOfficerByRiderIdRequest,
  FindRiderFinancialOfficerByRiderIdRequest,
} from '@modules/riders/types/FinancialOfficer/requests';

export class RiderFinancialOfficer {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Rider financial officer ----------

  async create(data: CreateOrUpdateRiderFinancialOfficerRequest): Promise<FinancialOfficer> {
    const { riderId, ...body } = data;

    const response = await this.instance.post(`/riders/${riderId}/financial-officer`, body);

    return response.data;
  }

  async get({ riderId }: FindRiderFinancialOfficerByRiderIdRequest): Promise<FinancialOfficer> {
    const response = await this.instance.get(`/riders/${riderId}/financial-officer`);

    return response.data;
  }

  async delete({ riderId }: DeleteRiderFinancialOfficerByRiderIdRequest): Promise<void> {
    await this.instance.delete(`/riders/${riderId}/financial-officer`);
  }
}

export const riderFinancialOfficer = (instance: AxiosInstance): RiderFinancialOfficer =>
  new RiderFinancialOfficer(instance);
