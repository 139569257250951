import { AxiosInstance } from 'axios';

import { IClientConfigProps } from '@shared/clients/types/types';

import { password, Password } from '@modules/auth/services/Password';

import { groupsPermissions, GroupsPermissions } from '@modules/configurations/services/GroupPermissions';
import { MyTeam, myTeam } from '@modules/configurations/services/MyTeam';
import { permissions, Permissions } from '@modules/configurations/services/Permissions';

import { identityApiConfig } from './config';

export class IdentityAPI {
  private instance: AxiosInstance;

  constructor(params: IClientConfigProps) {
    this.instance = identityApiConfig(params).instance();
  }

  groupsPermissions(): GroupsPermissions {
    return groupsPermissions(this.instance);
  }

  myTeam(): MyTeam {
    return myTeam(this.instance);
  }

  password(): Password {
    return password(this.instance);
  }

  permissions(): Permissions {
    return permissions(this.instance);
  }
}

export const IdentityFactoryAPI = (params: IClientConfigProps): IdentityAPI => new IdentityAPI(params);
