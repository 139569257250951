/**
 * InvoiceRoles
 * @readonly
 * @enum {string}
 */
export enum InvoiceRoles {
  /** Cancel an invoice. */
  CANCEL_INVOICE = 'CANCEL_INVOICE',

  /** Generate data to invoices summary. */
  GENERATE_INVOICE_SUMMARY = 'GENERATE_INVOICE_SUMMARY',

  /** View the dashboard to conciliations between entity and Equites. */
  GET_CONCILIATION_DASHBOARD = 'GET_CONCILIATION_DASHBOARD',

  /** Submit invoice. */
  SUBMIT_INVOICE = 'SUBMIT_INVOICE',
}
