import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { createContext } from 'use-context-selector';

import { CompaniesClient } from '@shared/clients/http/CompaniesClient';
import { toast } from '@shared/components/Toast';
import { HandleApiErrors } from '@shared/utils/HandleApiErrors';

import { useLoader } from '@modules/globals/hooks/useLoader';

import { IHorseVaccineContext } from '@modules/horses/types/Vaccines/context';
import {
  ICreateHorseVaccineRequest,
  IDeleteHorseVaccineRequest,
  IFindHorseVaccinesByHorseIdRequest,
} from '@modules/horses/types/Vaccines/requests';
import { IFilterVaccines, IHorseVaccine } from '@modules/horses/types/Vaccines/vaccines';

const HorseVaccinesContext = createContext({} as IHorseVaccineContext);
HorseVaccinesContext.displayName = 'HorsesVaccines';

const HorseVaccinesProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation('horses', { keyPrefix: 'messages' });

  const { startLoad, endLoad } = useLoader();

  const [vaccines, setVaccines] = useState<IHorseVaccine[]>([]);
  const [filterVaccines, setFilterVaccines] = useState<IFilterVaccines>({ year: new Date().getFullYear().toString() });

  const getHorseVaccines = useCallback(
    async (data: IFindHorseVaccinesByHorseIdRequest) => {
      try {
        startLoad();

        const response = await CompaniesClient.horseVaccines().getHorseVaccines(data);

        setVaccines(response.data);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const createHorseVaccine = useCallback(
    async (data: ICreateHorseVaccineRequest) => {
      try {
        startLoad();

        await CompaniesClient.horseVaccines().createVaccine(data);

        await getHorseVaccines({ ...filterVaccines, horseId: data.horseId });

        toast(t('horse_vaccine_created_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, filterVaccines, getHorseVaccines, startLoad, t],
  );

  const deleteHorseVaccine = useCallback(
    async (data: IDeleteHorseVaccineRequest) => {
      try {
        startLoad();

        await CompaniesClient.horseVaccines().deleteVaccine(data);

        await getHorseVaccines({ ...filterVaccines, horseId: data.horseId });

        toast(t('horse_vaccine_deleted_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, filterVaccines, getHorseVaccines, startLoad, t],
  );

  const handleFilterVaccines = useCallback((data: IFilterVaccines) => {
    setFilterVaccines(data);
  }, []);

  const contextValue = useMemo<IHorseVaccineContext>(
    () => ({
      createHorseVaccine,
      deleteHorseVaccine,
      filterVaccines,
      getHorseVaccines,
      handleFilterVaccines,
      vaccines,
    }),
    [createHorseVaccine, deleteHorseVaccine, filterVaccines, getHorseVaccines, handleFilterVaccines, vaccines],
  );

  return <HorseVaccinesContext.Provider value={contextValue}>{children}</HorseVaccinesContext.Provider>;
};

export { HorseVaccinesProvider, HorseVaccinesContext };
