import { AxiosInstance } from 'axios';

import { IPaginateDTO } from '@shared/dtos/IPaginateDTO';

import { Plan as PlanEntity } from '@modules/plans/types/Plans/plans';
import {
  CreatePlanRequest,
  DeletePlanRequest,
  FindPlanByIdRequest,
  FindPlansPaginateRequest,
  UpdatePlanRequest,
} from '@modules/plans/types/Plans/requests';

export class Plan {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Rider financial officer ----------

  async create(data: CreatePlanRequest): Promise<PlanEntity> {
    const response = await this.instance.post(`/plans`, data);

    return response.data;
  }

  async delete(data: DeletePlanRequest): Promise<void> {
    await this.instance.delete(`/plans/${data.planId}`);
  }

  async findById(data: FindPlanByIdRequest): Promise<PlanEntity> {
    const response = await this.instance.get(`/plans/${data.planId}`);

    return response.data;
  }

  async findPaginate(data: FindPlansPaginateRequest): Promise<IPaginateDTO<PlanEntity>> {
    const response = await this.instance.get(`/plans`, { params: data });

    return response.data;
  }

  async update({ planId, ...data }: UpdatePlanRequest): Promise<PlanEntity> {
    const response = await this.instance.patch(`/plans/${planId}`, data);

    return response.data;
  }
}

export const plan = (instance: AxiosInstance): Plan => new Plan(instance);
