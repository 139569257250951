import { AxiosInstance, AxiosResponse } from 'axios';

import {
  IGenerateInvoiceDataSummary,
  IValidateRidersIsAvailableToCreateInvoices,
} from '@modules/invoices/types/Invoices/invoices';
import {
  CancelInvoiceRequest,
  GenerateInvoiceDataSummaryRequest,
  SubmitInvoicesRequest,
  SubmitInvoicesResponse,
  ValidateRidersIsAvailableToCreateInvoicesRequest,
} from '@modules/invoices/types/Invoices/requests';

type IValidateRidersResponse = Promise<AxiosResponse<IValidateRidersIsAvailableToCreateInvoices[]>>;

export class Invoices {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Invoices ----------

  cancelInvoice({ invoiceId }: CancelInvoiceRequest): Promise<AxiosResponse> {
    return this.instance.post(`/invoices/${invoiceId}/cancel`);
  }

  // ---------- Invoices summary ----------

  generateSummary(data: GenerateInvoiceDataSummaryRequest): Promise<AxiosResponse<IGenerateInvoiceDataSummary>> {
    return this.instance.post(`/invoices/summary/generate`, data);
  }

  submitSummary(data: SubmitInvoicesRequest): Promise<AxiosResponse<SubmitInvoicesResponse>> {
    return this.instance.post(`/invoices/summary/submit`, data);
  }

  // ---------- Invoices validate riders ----------

  validateRiders(data: ValidateRidersIsAvailableToCreateInvoicesRequest): IValidateRidersResponse {
    return this.instance.post(`/invoices/validate-riders`, data);
  }
}

export const invoices = (instance: AxiosInstance): Invoices => new Invoices(instance);
