import { useState, useEffect, Dispatch, SetStateAction } from 'react';

type Response<T> = [T, Dispatch<SetStateAction<T>>];

export function usePersistedState<T>(
  key: string,
  initialState: T,
  storage: 'LOCAL' | 'SESSION' = 'LOCAL',
): Response<T> {
  const [state, setState] = useState<T>(() => {
    const storedValue = storage === 'LOCAL' ? localStorage.getItem(key) : sessionStorage.getItem(key);
    return storedValue && storedValue !== 'undefined' ? JSON.parse(storedValue) : initialState;
  });

  useEffect(() => {
    if (storage === 'LOCAL') {
      localStorage.setItem(key, JSON.stringify(state));
    } else if (storage === 'SESSION') {
      sessionStorage.setItem(key, JSON.stringify(state));
    }
  }, [key, state, storage]);

  return [state, setState];
}
