import { AxiosInstance, AxiosResponse } from 'axios';

import { IPaginateDTO } from '@shared/dtos/IPaginateDTO';

import { INotification } from '@modules/notifications/types/Notifications/notifications';
import {
  IGetNotificationsRequest,
  IUpdateNotificationRequest,
} from '@modules/notifications/types/Notifications/requests';

export class Notifications {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  getNotifications(params: IGetNotificationsRequest): Promise<AxiosResponse<IPaginateDTO<INotification>>> {
    return this.instance.get(`/notifications`, { params });
  }

  updateNotification(notifications: IUpdateNotificationRequest[]): Promise<AxiosResponse<INotification[]>> {
    return this.instance.patch(`/notifications`, { notifications });
  }
}

export const notifications = (instance: AxiosInstance): Notifications => new Notifications(instance);
