import { AxiosInstance } from 'axios';

import { IAppointmentLockDay } from '@modules/appointments/types/AppointmentsLockDays/appointmentsLockDays';
import {
  IFindLockDaysToAppointmentsRequest,
  ILockDayToAppointmentRequest,
  IUnlockDayToAppointmentRequest,
} from '@modules/appointments/types/AppointmentsLockDays/requests';

export class AppointmentsLockDays {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Appointments lock days ----------

  async find(params: IFindLockDaysToAppointmentsRequest): Promise<IAppointmentLockDay[]> {
    const response = await this.instance.get(`/appointments/lock-days`, { params });

    return response.data;
  }

  async lock(data: ILockDayToAppointmentRequest): Promise<IAppointmentLockDay> {
    const response = await this.instance.post(`/appointments/lock-days`, data);

    return response.data;
  }

  async unlock(params: IUnlockDayToAppointmentRequest): Promise<void> {
    await this.instance.delete(`/appointments/lock-days`, { params });
  }
}

export const appointmentsLockDays = (instance: AxiosInstance): AppointmentsLockDays =>
  new AppointmentsLockDays(instance);
