import { AppointmentRoles } from './appointments';
import { AppointmentLockDayRoles } from './appointmentsLockDays';
import { AppointmentModalityRoles } from './appointmentsModalities';
import { AssociationsAffiliationsRoles } from './associationsAffiliations';
import { AssociationsCompaniesRoles } from './associationsCompanies';
import { AssociationsHorsesRoles } from './associationsHorses';
import { AssociationsRidersRoles } from './associationsRiders';
import { BalanceRoles } from './balances';
import { ConfigurationRoles } from './configurations';
import { ContactsRoles } from './contacts';
import { DashboardRoles } from './dashboard';
import { HorseRoles } from './horses';
import { InvoiceRoles } from './invoices';
import { ModalitiesRoles } from './modalities';
import { PlansRoles } from './plans';
import { RiderRoles } from './riders';
import { RidersFinancialOfficerFiRoles } from './ridersFinancialOfficer';
import { TeacherRoles } from './teachers';
import { UserRoles } from './users';
import { WarehouseRoles } from './warehouse';
import { WithdrawalsRoles } from './withdrawals';

/**
 * Roles to allow users access functions on Equites
 * @readonly
 * @property {AppointmentRoles} appointments
 * @property {AppointmentLockDayRoles} appointmentsLockDays
 * @property {AppointmentModalityRoles} appointmentsModalities
 * @property {AssociationsAffiliationsRoles} associationsAffiliations
 * @property {AssociationsCompaniesRoles} associationsCompanies
 * @property {AssociationsHorsesRoles} associationsHorses
 * @property {AssociationsRidersRoles} associationsRiders
 * @property {BalanceRoles} balances
 * @property {ConfigurationRoles} configurations
 * @property {ContactsRoles} contacts
 * @property {DashboardRoles} dashboard
 * @property {HorseRoles} horses
 * @property {InvoiceRoles} invoices
 * @property {ModalitiesRoles} modalities
 * @property {PlansRoles} plans
 * @property {RidersRoles} riders
 * @property {RidersFinancialOfficerFiRoles} ridersFinancialOfficer
 * @property {TeacherRoles} teachers
 * @property {UserRoles} users
 * @property {WarehouseRoles} warehouse
 * @property {WithdrawalsRoles} withdrawals
 */
export const Roles = {
  /** Roles to manage appointments */
  appointments: AppointmentRoles,

  /** Roles to manage appointments lock days */
  appointmentsLockDays: AppointmentLockDayRoles,

  /** Roles to manage appointments modalities */
  appointmentsModalities: AppointmentModalityRoles,

  /** Roles to manage associations affiliations */
  associationsAffiliations: AssociationsAffiliationsRoles,

  /** Roles to manage associations companies */
  associationsCompanies: AssociationsCompaniesRoles,

  /** Roles to manage associations horses */
  associationsHorses: AssociationsHorsesRoles,

  /** Roles to manage associations riders */
  associationsRiders: AssociationsRidersRoles,

  /** Roles to manage balances */
  balances: BalanceRoles,

  /** Roles to manage configurations */
  configurations: ConfigurationRoles,

  /** Roles to manage contacts */
  contacts: ContactsRoles,

  /** Roles to manage dashboard */
  dashboard: DashboardRoles,

  /** Roles to manage horses */
  horses: HorseRoles,

  /** Roles to manage invoices */
  invoices: InvoiceRoles,

  /** Roles to manage invoices */
  modalities: ModalitiesRoles,

  /** Roles to manage plans */
  plans: PlansRoles,

  /** Roles to manage riders */
  riders: RiderRoles,

  /** Roles to manage financial officer from riders */
  ridersFinancialOfficer: RidersFinancialOfficerFiRoles,

  /** Roles to manage teachers */
  teachers: TeacherRoles,

  /** Roles to manage users */
  users: UserRoles,

  /** Roles to manage warehouse */
  warehouse: WarehouseRoles,

  /** Roles to manage withdrawals */
  withdrawals: WithdrawalsRoles,
};
