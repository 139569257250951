import { AxiosInstance, AxiosResponse } from 'axios';

import { IFood, IFoodSummary } from '@modules/warehouse/types/Foods/foods';
import {
  ICreateFoodRequest,
  ICreateFoodResponse,
  IFindFoodByIdRequest,
  IUpdateFoodRequest,
  IUpdateFoodResponse,
} from '@modules/warehouse/types/Foods/requests';

export class Foods {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Foods ----------

  createFood(data: ICreateFoodRequest): Promise<AxiosResponse<ICreateFoodResponse>> {
    return this.instance.post(`/foods`, data);
  }

  findFoodById(data: IFindFoodByIdRequest): Promise<AxiosResponse<IFood>> {
    const { foodId } = data;
    return this.instance.get(`/foods/${foodId}`);
  }

  updateFood(data: IUpdateFoodRequest): Promise<AxiosResponse<IUpdateFoodResponse>> {
    const { id, ...body } = data;
    return this.instance.patch(`/foods/${id}`, body);
  }

  // ---------- Summary ----------

  getFoodsSummary(): Promise<AxiosResponse<IFoodSummary[]>> {
    return this.instance.get(`/foods/summary`);
  }
}

export const foods = (instance: AxiosInstance): Foods => new Foods(instance);
