/**
 * TeacherRoles
 * @readonly
 * @enum {string}
 */
export enum TeacherRoles {
  /** Create a new teacher */
  CREATE_TEACHER = 'CREATE_TEACHER',

  /** Delete a teacher */
  DELETE_TEACHER = 'DELETE_TEACHER',

  /** Get a teachers summary list */
  GET_TEACHERS = 'GET_TEACHERS',

  /** Update a teacher */
  UPDATE_TEACHER = 'UPDATE_TEACHER',

  /** Update a teacher photo */
  UPDATE_TEACHER_AVATAR = 'UPDATE_TEACHER_AVATAR',
}
