import { AxiosInstance } from 'axios';

import { EquitesAxiosInstance } from '@shared/clients/http/commons/EquitesAxiosInstance';
import { HandleRequestsFunctions } from '@shared/clients/http/commons/HandleRequestFunctions';
import { HandleResponseFunctions } from '@shared/clients/http/commons/HandleResponseFunctions';
import { IClientConfigProps } from '@shared/clients/types/types';
import { Languages } from '@shared/constants/Languages';
import { Storage } from '@shared/constants/Storage';

class Config {
  private axios: AxiosInstance;

  constructor({ apiUrl }: IClientConfigProps) {
    if (!apiUrl) throw new Error('Missing identity api url');

    this.axios = EquitesAxiosInstance.createInstance(apiUrl, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Language': localStorage.getItem(Storage.LANGUAGE) ?? Languages.PT_BR,
      },
    });

    this.axios.interceptors.request.use(
      request => HandleRequestsFunctions.handleRequest(request),
      error => error,
    );

    this.axios.interceptors.response.use(
      response => response,
      error => HandleResponseFunctions.handleResponseError(error),
    );
  }

  instance(): AxiosInstance {
    return this.axios;
  }
}

export const identityApiConfig = (props: IClientConfigProps): Config => new Config(props);
