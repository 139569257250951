import { AxiosInstance, AxiosResponse } from 'axios';

import { IRequestProfileUpdateRequest } from '@modules/riders/types/Notifications/requests';

export class RidersNotifications {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  notificationsRequestProfileUpdate({ riderId }: IRequestProfileUpdateRequest): Promise<AxiosResponse<void>> {
    return this.instance.post(`/riders/${riderId}/notifications/request-profile-update`);
  }
}

export const ridersNotifications = (instance: AxiosInstance): RidersNotifications => new RidersNotifications(instance);
