import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAsync } from 'react-use';

import { NotificationsClient } from '@shared/clients/http/NotificationsClient';

import { useSidebar } from '@modules/globals/hooks/useSidebar';

interface IResponse {
  navigateToProfile: () => void;
  qtNotifications: number;
}

export function useHeader(): IResponse {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { handleMenuOpened, handleMenuSelected } = useSidebar();

  const [qtNotifications, setQtNotifications] = useState(0);

  const navigateToProfile = (): void => {
    navigate('/profile');
    handleMenuOpened('profile');
    handleMenuSelected('profile');
  };

  useAsync(async () => {
    if (pathname !== '/notifications') {
      const response = await NotificationsClient.notifications().getNotifications({
        limit: 1,
        page: 1,
        read: false,
      });

      setQtNotifications(response.data.totalItems);
    }
  }, [pathname]);

  return { navigateToProfile, qtNotifications };
}
