import { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';

import { createContext } from 'use-context-selector';

import { PaymentsClient } from '@shared/clients/http/PaymentsClient';
import { HandleApiErrors } from '@shared/utils/HandleApiErrors';

import { IBank } from '@modules/banks/types/Banks/banks';
import { IBankContext } from '@modules/banks/types/Banks/context';

import { useLoader } from '@modules/globals/hooks/useLoader';

const BankContext = createContext({} as IBankContext);
BankContext.displayName = 'Banks';

const BankProvider: FC<PropsWithChildren> = ({ children }) => {
  const { startLoad, endLoad } = useLoader();

  const [banks, setBanks] = useState<IBank[]>([]);

  const getAllBanks = useCallback(async () => {
    try {
      startLoad();

      const response = await PaymentsClient.banks().getAll();

      setBanks(response.data);
    } catch (err) {
      HandleApiErrors.handle({ err });
    } finally {
      endLoad();
    }
  }, [endLoad, startLoad]);

  const contextValue = useMemo<IBankContext>(() => ({ banks, getAllBanks }), [banks, getAllBanks]);

  return <BankContext.Provider value={contextValue}>{children}</BankContext.Provider>;
};

export { BankContext, BankProvider };
