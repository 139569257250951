/**
 * AppointmentLockDayRoles
 * @readonly
 * @enum {string}
 */
export enum AppointmentLockDayRoles {
  /** Get lock days to a company. */
  GET_LOCK_DAYS_TO_APPOINTMENTS = 'GET_LOCK_DAYS_TO_APPOINTMENTS',

  /** Create a new lock day to not register appointments. */
  LOCK_DAY_TO_APPOINTMENTS = 'LOCK_DAY_TO_APPOINTMENTS',

  /** Delete a lock day to register appointments. */
  UNLOCK_DAY_TO_APPOINTMENTS = 'UNLOCK_DAY_TO_APPOINTMENTS',
}
