import { AxiosInstance, AxiosResponse } from 'axios';

import { IPaginateDTO } from '@shared/dtos/IPaginateDTO';

import { IHorseOdontology } from '@modules/horses/types/Odontology/odontology';
import {
  ICreateHorseOdontologyRequest,
  ICreateHorseOdontologyResponse,
  IDeleteHorseOdontologyRequest,
  IFindHorseOdontologyByHorseIdRequest,
  IUpdateHorseOdontologyRequest,
  IUpdateHorseOdontologyResponse,
} from '@modules/horses/types/Odontology/requests';

type IGetHorseOdontologyResponse = Promise<AxiosResponse<IPaginateDTO<IHorseOdontology>>>;

export class HorseOdontology {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  createOdontology(data: ICreateHorseOdontologyRequest): Promise<AxiosResponse<ICreateHorseOdontologyResponse>> {
    const { horseId, ...body } = data;
    return this.instance.post(`/horses/${horseId}/odontology`, body);
  }

  deleteOdontology(data: IDeleteHorseOdontologyRequest): Promise<AxiosResponse<void>> {
    const { horseId, odontologyId } = data;
    return this.instance.delete(`/horses/${horseId}/odontology/${odontologyId}`);
  }

  getHorseOdontology(data: IFindHorseOdontologyByHorseIdRequest): IGetHorseOdontologyResponse {
    const { horseId, ...params } = data;
    return this.instance.get(`/horses/${horseId}/odontology`, { params });
  }

  updateOdontology(data: IUpdateHorseOdontologyRequest): Promise<AxiosResponse<IUpdateHorseOdontologyResponse>> {
    const { horseId, odontologyId, ...body } = data;
    return this.instance.patch(`/horses/${horseId}/odontology/${odontologyId}`, body);
  }
}

export const horseOdontology = (instance: AxiosInstance): HorseOdontology => new HorseOdontology(instance);
