import { HorseExamsProvider } from '@modules/horses/contexts/Exams';
import { HorseExpensesProvider } from '@modules/horses/contexts/Expenses';
import { HorseFeedFoodForecastProvider } from '@modules/horses/contexts/FeedsFoodForecast';
import { HorseFoodsProvider } from '@modules/horses/contexts/Foods';
import { HorsesProvider } from '@modules/horses/contexts/Horses';
import { HorseHorseshoesProvider } from '@modules/horses/contexts/Horseshoes';
import { HorseMedicinesProvider } from '@modules/horses/contexts/Medicines';
import { HorseOdontologyProvider } from '@modules/horses/contexts/Odontology';
import { HorseOwnersProvider } from '@modules/horses/contexts/Owners';
import { HorseVaccinesProvider } from '@modules/horses/contexts/Vaccines';

export const AppHorsesProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <HorsesProvider>
      <HorseVaccinesProvider>
        <HorseExamsProvider>
          <HorseMedicinesProvider>
            <HorseOdontologyProvider>
              <HorseHorseshoesProvider>
                <HorseFoodsProvider>
                  <HorseOwnersProvider>
                    <HorseExpensesProvider>
                      <HorseFeedFoodForecastProvider>{children}</HorseFeedFoodForecastProvider>
                    </HorseExpensesProvider>
                  </HorseOwnersProvider>
                </HorseFoodsProvider>
              </HorseHorseshoesProvider>
            </HorseOdontologyProvider>
          </HorseMedicinesProvider>
        </HorseExamsProvider>
      </HorseVaccinesProvider>
    </HorsesProvider>
  );
};
