/**
 * RidersRoles
 * @readonly
 * @enum {string}
 */
export enum RiderRoles {
  /** Create a new rider */
  CREATE_RIDER = 'CREATE_RIDER',

  /** Create a new configuration of the fixed appointment to one rider */
  CREATE_RIDER_FIXED_APPOINTMENT = 'CREATE_RIDER_FIXED_APPOINTMENT',

  /** Add a new emergency contact to rider */
  CREATE_RIDER_EMERGENCY_CONTACT = 'CREATE_RIDER_EMERGENCY_CONTACT',

  /** Create a new lesson package to rider */
  CREATE_OR_UPDATE_RIDER_LESSONS_PACKAGE = 'CREATE_OR_UPDATE_RIDER_LESSONS_PACKAGE',

  /** Create a new payment to rider */
  CREATE_RIDER_PAYMENT = 'CREATE_RIDER_PAYMENT',

  /** Remove an emergency contact from rider */
  DELETE_RIDER_EMERGENCY_CONTACT = 'DELETE_RIDER_EMERGENCY_CONTACT',

  /** Delete a configuration of the fixed appointment from one rider */
  DELETE_RIDER_FIXED_APPOINTMENT = 'DELETE_RIDER_FIXED_APPOINTMENT',

  /** Delete a rider. */
  DELETE_RIDER = 'DELETE_RIDER',

  /** Remove a payment from rider */
  DELETE_RIDER_PAYMENT = 'DELETE_RIDER_PAYMENT',

  /** Export payments as PDF or CSV */
  EXPORT_RIDER_PAYMENTS = 'EXPORT_RIDER_PAYMENTS',

  /** Get appointments history from one rider */
  GET_RIDER_APPOINTMENTS_HISTORY = 'GET_RIDER_APPOINTMENTS_HISTORY',

  /** Get fixed appointments from one rider */
  GET_RIDER_FIXED_APPOINTMENTS = 'GET_RIDER_FIXED_APPOINTMENTS',

  /** Get lessons package from one rider */
  GET_RIDER_LESSONS_PACKAGE = 'GET_RIDER_LESSONS_PACKAGE',

  /** Get payments from one rider */
  GET_RIDER_PAYMENTS = 'GET_RIDER_PAYMENTS',

  /** Get a rider profile. */
  GET_RIDER_PROFILE = 'GET_RIDER_PROFILE',

  /** Get riders summary list. */
  GET_RIDERS = 'GET_RIDERS',

  /** Send invite to register company's rider as athlete. */
  INVITE_RIDER_TO_REGISTER_AS_ATHLETE = 'INVITE_RIDER_TO_REGISTER_AS_ATHLETE',

  /** Send notification to rider requesting a profile update. */
  NOTIFICATION_RIDER_REQUEST_PROFILE_UPDATE = 'NOTIFICATION_RIDER_REQUEST_PROFILE_UPDATE',

  /** Update a payment to rider */
  UPDATE_RIDER_PAYMENT = 'UPDATE_RIDER_PAYMENT',

  /** Update a rider. */
  UPDATE_RIDER = 'UPDATE_RIDER',

  /** Update a rider photo. */
  UPDATE_RIDER_AVATAR = 'UPDATE_RIDER_AVATAR',

  /** Update a configuration of the fixed appointment from one rider */
  UPDATE_RIDER_FIXED_APPOINTMENT = 'UPDATE_RIDER_FIXED_APPOINTMENT',
}
