import { AxiosInstance, AxiosResponse } from 'axios';

import { IPaginateDTO } from '@shared/dtos/IPaginateDTO';

import { IHorse, IHorseProfileSummary, IHorseSummary } from '@modules/horses/types/Horses/horses';
import {
  ICreateHorseRequest,
  ICreateHorseResponse,
  IDeleteHorseRequest,
  IFindHorsesRequest,
  IFindHorsesSummaryRequest,
  IUpdateHorsePhotoRequest,
  IUpdateHorsePhotoResponse,
  IUpdateHorseRequest,
  IUpdateHorseResponse,
} from '@modules/horses/types/Horses/requests';

export class Horses {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Horses ----------

  createHorse(data: ICreateHorseRequest): Promise<AxiosResponse<ICreateHorseResponse>> {
    return this.instance.post(`/horses`, data);
  }

  async deleteHorse(data: IDeleteHorseRequest): Promise<void> {
    const { horseId, ...params } = data;

    await this.instance.delete(`/horses/${horseId}`, { params });
  }

  getHorseById(horseId: string): Promise<AxiosResponse<IHorse>> {
    return this.instance.get(`/horses/${horseId}`);
  }

  async getHorses(params: IFindHorsesRequest): Promise<IPaginateDTO<IHorse>> {
    const response = await this.instance.get('/horses', { params });

    return response.data;
  }

  updateHorse(data: IUpdateHorseRequest): Promise<AxiosResponse<IUpdateHorseResponse>> {
    const { horseId, ...body } = data;
    return this.instance.patch(`/horses/${horseId}`, body);
  }

  // ---------- Photo ----------

  updateHorsePhoto(data: IUpdateHorsePhotoRequest): Promise<AxiosResponse<IUpdateHorsePhotoResponse>> {
    const { horseId, file } = data;

    const formData = new FormData();
    formData.append('photo', file);

    return this.instance.patch(`/horses/${horseId}/photo`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  // ---------- Summary ----------

  async getHorsesSummary(params?: IFindHorsesSummaryRequest): Promise<IHorseSummary[]> {
    const response = await this.instance.get(`/horses/summary`, { params });

    return response.data;
  }

  // ---------- Profiles ----------

  getHorsesProfileSummaryById(horseId: string): Promise<AxiosResponse<IHorseProfileSummary>> {
    return this.instance.get(`/horses/${horseId}/profile/summary`);
  }
}

export const horses = (instance: AxiosInstance): Horses => new Horses(instance);
