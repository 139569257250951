import { AxiosInstance } from 'axios';

import { IAppointmentModality } from '@modules/appointments/types/AppointmentsModalities/appointmentsModalities';
import {
  ICreateAppointmentModalityRequest,
  IUpdateAppointmentModalityRequest,
} from '@modules/appointments/types/AppointmentsModalities/requests';

export class AppointmentsModalities {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Appointments modalities ----------

  async create(data: ICreateAppointmentModalityRequest): Promise<IAppointmentModality> {
    const response = await this.instance.post(`/appointments/modalities`, data);

    return response.data;
  }

  async findAll(): Promise<IAppointmentModality[]> {
    const response = await this.instance.get(`/appointments/modalities/`);

    return response.data;
  }

  async update(data: IUpdateAppointmentModalityRequest): Promise<IAppointmentModality> {
    const { id, ...body } = data;

    const response = await this.instance.patch(`/appointments/modalities/${id}`, { ...body });

    return response.data;
  }
}

export const appointmentsModalities = (instance: AxiosInstance): AppointmentsModalities =>
  new AppointmentsModalities(instance);
