import { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { createContext } from 'use-context-selector';

import { CompaniesClient } from '@shared/clients/http/CompaniesClient';
import { toast } from '@shared/components/Toast';
import { HandleApiErrors } from '@shared/utils/HandleApiErrors';
import { sleep } from '@shared/utils/SleepFunction';

import { useLoader } from '@modules/globals/hooks/useLoader';

import { IInvoiceContext } from '@modules/invoices/types/Invoices/context';
import { IValidateRidersIsAvailableToCreateInvoices } from '@modules/invoices/types/Invoices/invoices';
import {
  CancelInvoiceRequest,
  GenerateInvoiceDataSummaryRequest,
  SubmitInvoicesRequest,
  ValidateRidersIsAvailableToCreateInvoicesRequest,
} from '@modules/invoices/types/Invoices/requests';

const InvoiceContext = createContext({} as IInvoiceContext);
InvoiceContext.displayName = 'Invoice';

const InvoiceProvider: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation('invoices', { keyPrefix: 'messages' });

  const { startLoad, endLoad } = useLoader();

  const [validationErrors, setValidationErrors] = useState<IValidateRidersIsAvailableToCreateInvoices[]>([]);

  const cancelInvoice = useCallback(
    async (data: CancelInvoiceRequest) => {
      try {
        startLoad();

        await CompaniesClient.invoices().cancelInvoice(data);

        toast(t('invoice_canceled_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
        throw new Error('Error to generate summary');
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad, t],
  );

  const generateInvoicesSummary = useCallback(
    async (data: GenerateInvoiceDataSummaryRequest) => {
      try {
        startLoad();

        const response = await CompaniesClient.invoices().generateSummary(data);

        return response.data;
      } catch (err) {
        HandleApiErrors.handle({ err });
        throw new Error('Error to generate summary');
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const submitInvoices = useCallback(
    async (data: SubmitInvoicesRequest) => {
      try {
        startLoad();

        await CompaniesClient.invoices().submitSummary(data);

        await sleep(1000);

        toast(t('invoices_created_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad, t],
  );

  const validateRidersAvailableToInvoices = useCallback(
    async (data: ValidateRidersIsAvailableToCreateInvoicesRequest) => {
      try {
        startLoad();

        const response = await CompaniesClient.invoices().validateRiders(data);

        setValidationErrors(response.data);

        return response.data;
      } catch (err) {
        HandleApiErrors.handle({ err });
        throw new Error('Error to validate riders');
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const contextValue = useMemo<IInvoiceContext>(
    () => ({
      cancelInvoice,
      generateInvoicesSummary,
      submitInvoices,
      validateRidersAvailableToInvoices,
      validationErrors,
    }),
    [cancelInvoice, generateInvoicesSummary, submitInvoices, validateRidersAvailableToInvoices, validationErrors],
  );

  return <InvoiceContext.Provider value={contextValue}>{children}</InvoiceContext.Provider>;
};

export { InvoiceContext, InvoiceProvider };
