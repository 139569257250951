import { initReactI18next } from 'react-i18next';

import i18n, { BackendModule } from 'i18next';
import Backend from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend';

import { Languages } from '@shared/constants/Languages';
import { Storage } from '@shared/constants/Storage';

const getResources = (): BackendModule => {
  return resourcesToBackend((language, namespace, callback) => {
    import(`../assets/locales/${language}/${namespace}.json`)
      .then(resources => callback(null, resources))
      .catch(error => callback(error, null));
  });
};

i18n
  .use(initReactI18next)
  .use(Backend)
  .use(getResources())
  .init({
    debug: false,
    lng: localStorage.getItem(Storage.LANGUAGE) || Languages.PT_BR,
    fallbackLng: Languages.PT_BR,
    interpolation: { escapeValue: false },
    returnNull: false,
  });

export { i18n };
