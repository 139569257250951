import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { createContext } from 'use-context-selector';

import { CompaniesClient } from '@shared/clients/http/CompaniesClient';
import { toast } from '@shared/components/Toast';
import { IPaginateDTO } from '@shared/dtos/IPaginateDTO';
import { HandleApiErrors } from '@shared/utils/HandleApiErrors';

import { useLoader } from '@modules/globals/hooks/useLoader';

import { IHorseMedicineContext } from '@modules/horses/types/Medicines/context';
import { IHorseMedicine, IFilterMedicines } from '@modules/horses/types/Medicines/medicines';
import {
  ICreateHorseMedicineRequest,
  IDeleteHorseMedicineRequest,
  IFindHorseMedicinesByHorseIdRequest,
  IUpdateHorseMedicineRequest,
} from '@modules/horses/types/Medicines/requests';

const HorseMedicinesContext = createContext({} as IHorseMedicineContext);
HorseMedicinesContext.displayName = 'HorsesMedicines';

const HorseMedicinesProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation('horses', { keyPrefix: 'messages' });

  const { startLoad, endLoad } = useLoader();

  const [medicines, setMedicines] = useState({} as IPaginateDTO<IHorseMedicine>);
  const [filterMedicines, setFilterMedicines] = useState({} as IFilterMedicines);

  const getHorseMedicines = useCallback(
    async (data: Partial<IFindHorseMedicinesByHorseIdRequest>) => {
      try {
        startLoad();

        const response = await CompaniesClient.horseMedicines().getHorseMedicines({ ...filterMedicines, ...data });

        setMedicines(response.data);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, filterMedicines, startLoad],
  );

  const createHorseMedicine = useCallback(
    async (data: ICreateHorseMedicineRequest) => {
      try {
        startLoad();

        await CompaniesClient.horseMedicines().createMedicine(data);

        await getHorseMedicines(filterMedicines);

        toast(t('horse_medicine_created_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, filterMedicines, getHorseMedicines, startLoad, t],
  );

  const updateHorseMedicine = useCallback(
    async (data: IUpdateHorseMedicineRequest) => {
      try {
        startLoad();

        await CompaniesClient.horseMedicines().updateMedicine(data);

        await getHorseMedicines(filterMedicines);

        toast(t('horse_medicine_updated_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, filterMedicines, getHorseMedicines, startLoad, t],
  );

  const deleteHorseMedicine = useCallback(
    async (data: IDeleteHorseMedicineRequest) => {
      try {
        startLoad();

        await CompaniesClient.horseMedicines().deleteMedicine(data);

        await getHorseMedicines(filterMedicines);

        toast(t('horse_medicine_deleted_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, filterMedicines, getHorseMedicines, startLoad, t],
  );

  const handleFilterMedicines = useCallback((data: Partial<IFilterMedicines>, reset?: boolean) => {
    setFilterMedicines(current => (reset ? ({ ...data } as IFilterMedicines) : { ...current, ...data }));
  }, []);

  const contextValue = useMemo<IHorseMedicineContext>(
    () => ({
      createHorseMedicine,
      deleteHorseMedicine,
      filterMedicines,
      getHorseMedicines,
      handleFilterMedicines,
      medicines,
      updateHorseMedicine,
    }),
    [
      createHorseMedicine,
      deleteHorseMedicine,
      filterMedicines,
      getHorseMedicines,
      handleFilterMedicines,
      medicines,
      updateHorseMedicine,
    ],
  );

  return <HorseMedicinesContext.Provider value={contextValue}>{children}</HorseMedicinesContext.Provider>;
};

export { HorseMedicinesProvider, HorseMedicinesContext };
