import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { createContext } from 'use-context-selector';

import { CompaniesClient } from '@shared/clients/http/CompaniesClient';
import { toast } from '@shared/components/Toast';
import { HandleApiErrors } from '@shared/utils/HandleApiErrors';

import { useLoader } from '@modules/globals/hooks/useLoader';

import { IHorseExamContext } from '@modules/horses/types/Exams/context';
import { IFilterExams, IHorseExam } from '@modules/horses/types/Exams/exams';
import {
  ICreateHorseExamRequest,
  IDeleteHorseExamReportRequest,
  IDeleteHorseExamRequest,
  IFindHorseExamsByHorseIdRequest,
  IUpdateHorseExamRequest,
  IUploadHorseExamReportRequest,
} from '@modules/horses/types/Exams/requests';

const HorseExamsContext = createContext({} as IHorseExamContext);
HorseExamsContext.displayName = 'HorsesExams';

const HorseExamsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation('horses', { keyPrefix: 'messages' });

  const { startLoad, endLoad } = useLoader();

  const [exams, setExams] = useState<IHorseExam[]>([]);
  const [filterExams, setFilterExams] = useState<IFilterExams>({ year: new Date().getFullYear().toString() });

  const getHorseExams = useCallback(
    async (data: IFindHorseExamsByHorseIdRequest) => {
      try {
        startLoad();

        const response = await CompaniesClient.horseExams().getHorseExams(data);

        setExams(response.data);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const createHorseExam = useCallback(
    async (data: ICreateHorseExamRequest) => {
      try {
        startLoad();

        await CompaniesClient.horseExams().createExam(data);

        await getHorseExams({ ...filterExams, horseId: data.horseId });

        toast(t('horse_exam_created_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, filterExams, getHorseExams, startLoad, t],
  );

  const updateHorseExam = useCallback(
    async (data: IUpdateHorseExamRequest) => {
      try {
        startLoad();

        await CompaniesClient.horseExams().updateExam(data);

        await getHorseExams({ ...filterExams, horseId: data.horseId });

        toast(t('horse_exam_updated_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, filterExams, getHorseExams, startLoad, t],
  );

  const deleteHorseExam = useCallback(
    async (data: IDeleteHorseExamRequest) => {
      try {
        startLoad();

        await CompaniesClient.horseExams().deleteExam(data);

        await getHorseExams({ ...filterExams, horseId: data.horseId });

        toast(t('horse_exam_deleted_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, filterExams, getHorseExams, startLoad, t],
  );

  const uploadHorseExamReport = useCallback(
    async (data: IUploadHorseExamReportRequest) => {
      try {
        startLoad();

        await CompaniesClient.horseExams().updateExamReport(data);

        await getHorseExams({ ...filterExams, horseId: data.horseId });

        toast(t('horse_exam_updated_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, filterExams, getHorseExams, startLoad, t],
  );

  const deleteHorseExamReport = useCallback(
    async (data: IDeleteHorseExamReportRequest) => {
      try {
        startLoad();

        await CompaniesClient.horseExams().deleteExamReport(data);

        await getHorseExams({ ...filterExams, horseId: data.horseId });

        toast(t('horse_exam_updated_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, filterExams, getHorseExams, startLoad, t],
  );

  const handleFilterExams = useCallback((data: IFilterExams) => {
    setFilterExams(data);
  }, []);

  const contextValue = useMemo<IHorseExamContext>(
    () => ({
      createHorseExam,
      deleteHorseExam,
      deleteHorseExamReport,
      exams,
      filterExams,
      getHorseExams,
      handleFilterExams,
      updateHorseExam,
      uploadHorseExamReport,
    }),
    [
      createHorseExam,
      deleteHorseExam,
      deleteHorseExamReport,
      exams,
      filterExams,
      getHorseExams,
      handleFilterExams,
      updateHorseExam,
      uploadHorseExamReport,
    ],
  );

  return <HorseExamsContext.Provider value={contextValue}>{children}</HorseExamsContext.Provider>;
};

export { HorseExamsProvider, HorseExamsContext };
