import { useContextSelector } from 'use-context-selector';

import { ProfileContext } from '../contexts/Profile';
import { IProfileContext } from '../types/Profile/context';

export function useProfile(): IProfileContext {
  const currentLanguage = useContextSelector(ProfileContext, cs => cs.currentLanguage);
  const currentProfile = useContextSelector(ProfileContext, cs => cs.currentProfile);
  const getCurrentProfile = useContextSelector(ProfileContext, cs => cs.getCurrentProfile);
  const handleCurrentProfileState = useContextSelector(ProfileContext, cs => cs.handleCurrentProfileState);
  const updateCurrentProfileAvatar = useContextSelector(ProfileContext, cs => cs.updateCurrentProfileAvatar);
  const updateCurrentProfileCompany = useContextSelector(ProfileContext, cs => cs.updateCurrentProfileCompany);
  const updateCurrentProfileIdiomAndRegion = useContextSelector(
    ProfileContext,
    cs => cs.updateCurrentProfileIdiomAndRegion,
  );

  return {
    currentLanguage,
    currentProfile,
    getCurrentProfile,
    handleCurrentProfileState,
    updateCurrentProfileAvatar,
    updateCurrentProfileCompany,
    updateCurrentProfileIdiomAndRegion,
  };
}
