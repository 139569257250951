import { AxiosInstance } from 'axios';

import { IDashboardHome } from '@modules/dashboard/types/dashboard';
import { IGetDashboardHomeRequest } from '@modules/dashboard/types/requests';

export class Dashboard {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Home ----------

  async getDashboardHome(params: IGetDashboardHomeRequest): Promise<IDashboardHome> {
    const response = await this.instance.get(`/dashboard/home`, { params });

    return response.data;
  }
}

export const dashboard = (instance: AxiosInstance): Dashboard => new Dashboard(instance);
