import './shared/styles/global.css';

import { ReactElement } from 'react';
import { I18nextProvider } from 'react-i18next';

import { i18n } from '@shared/configs/Translate';
import { Routes } from '@shared/routes';

import { removeSw } from '../remove-sw';

export const App = (): ReactElement => {
  removeSw();

  return (
    <I18nextProvider i18n={i18n}>
      <Routes />
    </I18nextProvider>
  );
};
