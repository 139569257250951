import { AxiosInstance, AxiosResponse } from 'axios';

import { ISendInviteToRidersRequest } from '@modules/riders/types/Invites/requests';

export class RiderInvites {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  sendInvite(data: ISendInviteToRidersRequest): Promise<AxiosResponse<void>> {
    return this.instance.post(`/riders/register/athletes/invites`, data);
  }
}

export const riderInvites = (instance: AxiosInstance): RiderInvites => new RiderInvites(instance);
