import { createContext, FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { isDesktop } from 'react-device-detect';

export interface IAppContext {
  ptrIsEnabled: boolean;
  enablePtr: () => void;
  disablePtr: () => void;
}

const AppContext = createContext<IAppContext>({} as IAppContext);
AppContext.displayName = 'App';

const AppProvider: FC<PropsWithChildren> = ({ children }) => {
  const [ptrIsEnabled, setPtrIsEnabled] = useState(!isDesktop);

  const enablePtr = useCallback(() => {
    setPtrIsEnabled(true);
  }, []);

  const disablePtr = useCallback(() => {
    setPtrIsEnabled(false);
  }, []);

  const contextValue = useMemo<IAppContext>(
    () => ({ disablePtr, enablePtr, ptrIsEnabled }),
    [disablePtr, enablePtr, ptrIsEnabled],
  );

  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};

export { AppContext, AppProvider };
