import { useCallback, useMemo, useState } from 'react';

import { createContext } from 'use-context-selector';

import { CompaniesClient } from '@shared/clients/http/CompaniesClient';
import { HandleApiErrors } from '@shared/utils/HandleApiErrors';

import { useLoader } from '@modules/globals/hooks/useLoader';

import { ICurrentProfileAbout } from '@modules/profiles/types/About/about';
import { IProfileAboutContext } from '@modules/profiles/types/About/context';

const ProfileAboutContext = createContext({} as IProfileAboutContext);
ProfileAboutContext.displayName = 'ProfileAbout';

const ProfileAboutProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { startLoad, endLoad } = useLoader();

  const [currentProfileAbout, setCurrentProfileAbout] = useState({} as ICurrentProfileAbout);

  const getCurrentProfileAbout = useCallback(async () => {
    try {
      startLoad();

      const response = await CompaniesClient.profilesAbout().getCurrentProfileAbout();

      setCurrentProfileAbout(response.data);
    } catch (err) {
      HandleApiErrors.handle({ err });
    } finally {
      endLoad();
    }
  }, [endLoad, startLoad]);

  const contextValue = useMemo<IProfileAboutContext>(
    () => ({ currentProfileAbout, getCurrentProfileAbout }),
    [currentProfileAbout, getCurrentProfileAbout],
  );

  return <ProfileAboutContext.Provider value={contextValue}>{children}</ProfileAboutContext.Provider>;
};

export { ProfileAboutProvider, ProfileAboutContext };
