import { AxiosInstance, AxiosResponse } from 'axios';

import { IChangePasswordRequest } from '@modules/auth/types/Password/requests';

export class Password {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  changePassword(data: IChangePasswordRequest): Promise<AxiosResponse<void>> {
    return this.instance.post(`/change-password`, data);
  }
}

export const password = (instance: AxiosInstance): Password => new Password(instance);
