import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';
import { createContext } from 'use-context-selector';

import { CompaniesClient } from '@shared/clients/http/CompaniesClient';
import { toast } from '@shared/components/Toast';
import { HandleApiErrors } from '@shared/utils/HandleApiErrors';

import { useLoader } from '@modules/globals/hooks/useLoader';

import { QUERY_KEY_FETCH_TEACHERS_SUMMARY } from '@modules/teachers/hooks/useFetchTeachersSummary';
import { ITeacherContext } from '@modules/teachers/types/Teachers/context';
import {
  ICreateTeacherRequest,
  IDeleteTeacherRequest,
  IFindTeacherByIdRequest,
  IUpdateTeacherAvatarRequest,
  IUpdateTeacherRequest,
} from '@modules/teachers/types/Teachers/requests';
import { ITeacher, ITeacherSummary } from '@modules/teachers/types/Teachers/teacher';

const TeachersContext = createContext({} as ITeacherContext);
TeachersContext.displayName = 'Teachers';

const TeachersProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation('teachers', { keyPrefix: 'messages' });

  const { startLoad, endLoad } = useLoader();

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const [teacher, setTeacher] = useState({} as ITeacher);
  const [teachersSummary, setTeachersSummary] = useState<ITeacherSummary[]>([]);

  const invalidateQueries = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: QUERY_KEY_FETCH_TEACHERS_SUMMARY });
    queryClient.invalidateQueries({ queryKey: ['appointments:calendar'], exact: false });
    queryClient.invalidateQueries({ queryKey: ['appointments:id'], exact: false });
    queryClient.invalidateQueries({ queryKey: ['dashboard:home'] });
  }, [queryClient]);

  const getTeachersSummary = useCallback(async () => {
    try {
      startLoad();

      const response = await CompaniesClient.teachers().getTeachersSummary();

      setTeachersSummary(response);

      setTeacher({} as ITeacher);
    } catch (err) {
      HandleApiErrors.handle({ err });
    } finally {
      endLoad();
    }
  }, [endLoad, startLoad]);

  const createTeacher = useCallback(
    async (data: ICreateTeacherRequest) => {
      try {
        startLoad();

        const response = await CompaniesClient.teachers().createTeacher(data);

        navigate(`/teachers/${response.data.id}/edit`);

        invalidateQueries();

        toast(t('teacher_created_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, invalidateQueries, navigate, startLoad, t],
  );

  const deleteTeacher = useCallback(
    async (data: IDeleteTeacherRequest) => {
      try {
        startLoad();

        await CompaniesClient.teachers().deleteTeacher(data);

        toast(t('teacher_deleted_success'), { type: 'success' });

        invalidateQueries();

        await getTeachersSummary();
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, getTeachersSummary, invalidateQueries, startLoad, t],
  );

  const findTeacherById = useCallback(
    async (data: IFindTeacherByIdRequest) => {
      try {
        startLoad();

        const response = await CompaniesClient.teachers().findTeacherById(data);

        setTeacher(response.data);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const updateTeacher = useCallback(
    async (data: IUpdateTeacherRequest) => {
      try {
        startLoad();

        const response = await CompaniesClient.teachers().updateTeacher(data);

        invalidateQueries();

        setTeacher(current => ({ ...current, ...response.data }));

        toast(t('teacher_updated_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, invalidateQueries, startLoad, t],
  );

  const updateTeacherAvatar = useCallback(
    async (data: IUpdateTeacherAvatarRequest) => {
      try {
        startLoad();

        const response = await CompaniesClient.teachers().updateTeacherAvatar(data);

        invalidateQueries();

        setTeacher(current => ({ ...current, avatarUrl: response.data.avatarUrl }));

        toast(t('teacher_avatar_updated_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, invalidateQueries, startLoad, t],
  );

  const contextValue = useMemo<ITeacherContext>(
    () => ({
      createTeacher,
      deleteTeacher,
      findTeacherById,
      getTeachersSummary,
      teacher,
      teachersSummary,
      updateTeacher,
      updateTeacherAvatar,
    }),
    [
      createTeacher,
      deleteTeacher,
      findTeacherById,
      getTeachersSummary,
      teacher,
      teachersSummary,
      updateTeacher,
      updateTeacherAvatar,
    ],
  );

  return <TeachersContext.Provider value={contextValue}>{children}</TeachersContext.Provider>;
};

export { TeachersProvider, TeachersContext };
