import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { createContext } from 'use-context-selector';

import { CompaniesClient } from '@shared/clients/http/CompaniesClient';
import { toast } from '@shared/components/Toast';
import { IPaginateDTO } from '@shared/dtos/IPaginateDTO';
import { HandleApiErrors } from '@shared/utils/HandleApiErrors';

import { useLoader } from '@modules/globals/hooks/useLoader';

import { IHorseExpenseContext } from '@modules/horses/types/Expenses/context';
import { IFilterHorseExpenses, IHorseExpense } from '@modules/horses/types/Expenses/expenses';
import {
  ICreateHorseExpenseRequest,
  IDeleteHorseExpenseRequest,
  IFindHorseExpensesRequest,
  IUpdateHorseExpenseRequest,
} from '@modules/horses/types/Expenses/requests';

const HorseExpensesContext = createContext({} as IHorseExpenseContext);
HorseExpensesContext.displayName = 'HorsesExpenses';

const HorseExpensesProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation('horses', { keyPrefix: 'messages' });

  const { startLoad, endLoad } = useLoader();

  const [horseExpenses, setHorseExpenses] = useState({} as IPaginateDTO<IHorseExpense>);
  const [filterHorseExpenses, setFilterHorseExpenses] = useState({} as IFilterHorseExpenses);

  const findHorseExpenses = useCallback(
    async (data: IFindHorseExpensesRequest) => {
      try {
        startLoad();

        const response = await CompaniesClient.horseExpenses().findExpenses(data);

        setHorseExpenses(response.data);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const exportHorseExpensesPDF = useCallback(
    async (data: IFindHorseExpensesRequest) => {
      try {
        startLoad();

        await CompaniesClient.horseExpenses().exportExpensesPDF(data);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const createHorseExpense = useCallback(
    async (data: ICreateHorseExpenseRequest) => {
      try {
        startLoad();

        await CompaniesClient.horseExpenses().createExpense(data);

        toast(t('horse_expense_created_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad, t],
  );

  const updateHorseExpense = useCallback(
    async (data: IUpdateHorseExpenseRequest) => {
      try {
        startLoad();

        await CompaniesClient.horseExpenses().updateExpense(data);

        await findHorseExpenses(filterHorseExpenses);

        toast(t('horse_expense_updated_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, filterHorseExpenses, findHorseExpenses, startLoad, t],
  );

  const deleteHorseExpense = useCallback(
    async (data: IDeleteHorseExpenseRequest) => {
      try {
        startLoad();

        await CompaniesClient.horseExpenses().deleteExpense(data);

        await findHorseExpenses(filterHorseExpenses);

        toast(t('horse_expense_deleted_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, filterHorseExpenses, findHorseExpenses, startLoad, t],
  );

  const handleFilterHorseExpenses = useCallback((data: IFilterHorseExpenses, reset?: boolean) => {
    setFilterHorseExpenses(current => (reset ? (data as IFilterHorseExpenses) : { ...current, ...data }));
  }, []);

  const contextValue = useMemo<IHorseExpenseContext>(
    () => ({
      createHorseExpense,
      deleteHorseExpense,
      exportHorseExpensesPDF,
      filterHorseExpenses,
      findHorseExpenses,
      handleFilterHorseExpenses,
      horseExpenses,
      updateHorseExpense,
    }),
    [
      createHorseExpense,
      deleteHorseExpense,
      exportHorseExpensesPDF,
      filterHorseExpenses,
      findHorseExpenses,
      handleFilterHorseExpenses,
      horseExpenses,
      updateHorseExpense,
    ],
  );

  return <HorseExpensesContext.Provider value={contextValue}>{children}</HorseExpensesContext.Provider>;
};

export { HorseExpensesProvider, HorseExpensesContext };
