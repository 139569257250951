import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { createContext } from 'use-context-selector';

import { CompaniesClient } from '@shared/clients/http/CompaniesClient';
import { toast } from '@shared/components/Toast';
import { Languages } from '@shared/constants/Languages';
import { Storage } from '@shared/constants/Storage';
import { HandleApiErrors } from '@shared/utils/HandleApiErrors';
import { usePersistedState } from '@shared/utils/usePersistedState';

import { useLoader } from '@modules/globals/hooks/useLoader';

import { IProfileContext } from '@modules/profiles/types/Profile/context';
import { ICurrentProfile } from '@modules/profiles/types/Profile/profile';
import {
  IUpdateCurrentProfileAvatarRequest,
  IUpdateCurrentProfileCompanyRequest,
  IUpdateCurrentProfileIdiomAndRegionRequest,
} from '@modules/profiles/types/Profile/requests';

const ProfileContext = createContext<IProfileContext>({} as IProfileContext);
ProfileContext.displayName = 'Profile';

const ProfileProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation('profiles', { keyPrefix: 'messages' });

  const { startLoad, endLoad } = useLoader();

  const [currentProfile, setCurrentProfile] = usePersistedState(Storage.CURRENT_PROFILE, {} as ICurrentProfile);
  const [currentLanguage, setCurrentLanguage] = usePersistedState(Storage.LANGUAGE, Languages.PT_BR);

  const getCurrentProfile = useCallback(async () => {
    try {
      startLoad();

      const response = await CompaniesClient.profiles().getCurrentProfile();

      setCurrentProfile(response.data);
    } catch (err) {
      HandleApiErrors.handle({ err });
    } finally {
      endLoad();
    }
  }, [endLoad, setCurrentProfile, startLoad]);

  const handleCurrentProfileState = useCallback(
    (data: Partial<ICurrentProfile>, reset = false) => {
      setCurrentProfile(current => (reset ? ({ ...data } as ICurrentProfile) : { ...current, ...data }));
    },
    [setCurrentProfile],
  );

  const updateCurrentProfileCompany = useCallback(
    async (data: IUpdateCurrentProfileCompanyRequest) => {
      try {
        startLoad();

        await CompaniesClient.profiles().updateCurrentProfileCompany(data);

        getCurrentProfile();

        toast(t('profile_updated_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, getCurrentProfile, startLoad, t],
  );

  const updateCurrentProfileIdiomAndRegion = useCallback(
    async (data: IUpdateCurrentProfileIdiomAndRegionRequest) => {
      try {
        startLoad();

        const response = await CompaniesClient.profiles().updateCurrentProfileIdiomAndRegion(data);

        setCurrentLanguage(response.data.language);

        getCurrentProfile();

        toast(t('profile_updated_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, getCurrentProfile, setCurrentLanguage, startLoad, t],
  );

  const updateCurrentProfileAvatar = useCallback(
    async (data: IUpdateCurrentProfileAvatarRequest) => {
      try {
        startLoad();

        await CompaniesClient.profiles().updateCurrentProfileAvatar(data);

        getCurrentProfile();

        toast(t('profile_updated_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, getCurrentProfile, startLoad, t],
  );

  const contextValue = useMemo<IProfileContext>(
    () => ({
      currentLanguage,
      currentProfile,
      getCurrentProfile,
      handleCurrentProfileState,
      updateCurrentProfileAvatar,
      updateCurrentProfileCompany,
      updateCurrentProfileIdiomAndRegion,
    }),
    [
      currentLanguage,
      currentProfile,
      getCurrentProfile,
      handleCurrentProfileState,
      updateCurrentProfileAvatar,
      updateCurrentProfileCompany,
      updateCurrentProfileIdiomAndRegion,
    ],
  );

  return <ProfileContext.Provider value={contextValue}>{children}</ProfileContext.Provider>;
};

export { ProfileProvider, ProfileContext };
