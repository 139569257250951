import { AxiosInstance } from 'axios';

import { IGroupPermissions } from '@modules/configurations/types/GroupPermissions/groupPermissions';
import {
  ICreateGroupPermissionRequest,
  IFindGroupPermissionsByIdRequest,
  IUpdateGroupPermissionRequest,
} from '@modules/configurations/types/GroupPermissions/requests';

export class GroupsPermissions {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  async createGroupPermission(data: ICreateGroupPermissionRequest): Promise<IGroupPermissions> {
    const response = await this.instance.post(`/permissions/groups/custom`, data);

    return response.data;
  }

  async getGroupPermissionsById(data: IFindGroupPermissionsByIdRequest): Promise<IGroupPermissions> {
    const response = await this.instance.get(`/permissions/groups/${data.groupPermissionsId}`);

    return response.data;
  }

  async getGroupsPermissionsStandards(): Promise<IGroupPermissions[]> {
    const response = await this.instance.get(`/permissions/groups/standards`);

    return response.data;
  }

  async updateGroupPermission(data: IUpdateGroupPermissionRequest): Promise<IGroupPermissions> {
    const { groupId, ...body } = data;

    const response = await this.instance.patch(`/permissions/groups/${groupId}/custom`, body);

    return response.data;
  }
}

export const groupsPermissions = (instance: AxiosInstance): GroupsPermissions => new GroupsPermissions(instance);
