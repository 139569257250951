import { AxiosInstance } from 'axios';

import { IConfiguration } from '@modules/configurations/types/Configurations/configurations';
import { UpdateConfigurationsNotificationsRequest } from '@modules/configurations/types/ConfigurationsNotifications/requests';

export class ConfigurationNotification {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  async update(data: UpdateConfigurationsNotificationsRequest): Promise<IConfiguration> {
    const response = await this.instance.patch(`/configurations/notifications`, data);

    return response.data;
  }
}

export const configurationNotification = (instance: AxiosInstance): ConfigurationNotification =>
  new ConfigurationNotification(instance);
