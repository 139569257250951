import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { createContext } from 'use-context-selector';

import { CompaniesClient } from '@shared/clients/http/CompaniesClient';
import { toast } from '@shared/components/Toast';
import { HandleApiErrors } from '@shared/utils/HandleApiErrors';

import { useLoader } from '@modules/globals/hooks/useLoader';

import { IFoodContext } from '@modules/warehouse/types/Foods/context';
import { IFood, IFoodSummary } from '@modules/warehouse/types/Foods/foods';
import { ICreateFoodRequest, IFindFoodByIdRequest, IUpdateFoodRequest } from '@modules/warehouse/types/Foods/requests';

const FoodsContext = createContext({} as IFoodContext);
FoodsContext.displayName = 'Foods';

const FoodsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation('warehouse', { keyPrefix: 'messages' });

  const { startLoad, endLoad } = useLoader();

  const [food, setFood] = useState({} as IFood);
  const [foodsSummary, setFoodsSummary] = useState<IFoodSummary[]>([]);

  const getFoodsSummary = useCallback(async () => {
    try {
      startLoad();

      const response = await CompaniesClient.foods().getFoodsSummary();

      setFoodsSummary(response.data);

      setFood({} as IFood);
    } catch (err) {
      HandleApiErrors.handle({ err });
    } finally {
      endLoad();
    }
  }, [endLoad, startLoad]);

  const createFood = useCallback(
    async (data: ICreateFoodRequest) => {
      try {
        startLoad();

        await CompaniesClient.foods().createFood(data);

        toast(t('food_created_success'), { type: 'success' });

        await getFoodsSummary();
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, getFoodsSummary, startLoad, t],
  );

  const findFoodById = useCallback(
    async (data: IFindFoodByIdRequest) => {
      try {
        startLoad();

        const response = await CompaniesClient.foods().findFoodById(data);

        setFood(response.data);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const updateFood = useCallback(
    async (data: IUpdateFoodRequest) => {
      try {
        startLoad();

        const response = await CompaniesClient.foods().updateFood(data);

        setFood(current => ({ ...current, ...response.data }));

        toast(t('food_updated_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad, t],
  );

  const contextValue = useMemo<IFoodContext>(
    () => ({ createFood, findFoodById, getFoodsSummary, food, foodsSummary, updateFood }),
    [createFood, findFoodById, getFoodsSummary, food, foodsSummary, updateFood],
  );

  return <FoodsContext.Provider value={contextValue}>{children}</FoodsContext.Provider>;
};

export { FoodsProvider, FoodsContext };
