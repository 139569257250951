/**
 * RidersRoles
 * @readonly
 * @enum {string}
 */
export enum RidersFinancialOfficerFiRoles {
  /** Remove financial officer from rider */
  DELETE_RIDER_FINANCIAL_OFFICER = 'DELETE_RIDER_FINANCIAL_OFFICER',

  /** Get financial officer from rider */
  GET_RIDER_FINANCIAL_OFFICER = 'GET_RIDER_FINANCIAL_OFFICER',

  /** Create or update a financial officer on rider */
  HANDLE_RIDER_FINANCIAL_OFFICER = 'HANDLE_RIDER_FINANCIAL_OFFICER',
}
