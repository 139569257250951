import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import clsx from 'clsx';
import { AnimatePresence, Variants, motion } from 'framer-motion';

import { Chevron } from '@shared/components/Chevron';
import { Text } from '@shared/components/Text';
import { IMenuItem } from '@shared/routes/MenuItems';

import { useSidebar } from '@modules/globals/hooks/useSidebar';

// ------------------------------------------

const SidebarItemContent = ({ id, icon: Icon, items, name }: IMenuItem): ReactElement => {
  const { sidebarOpened, menuSelected } = useSidebar();

  const { t } = useTranslation('sidebar');

  const mainElementHasChildSelected = menuSelected && items?.map(item => item.id).includes(menuSelected);
  const textIsGray = menuSelected !== id && !mainElementHasChildSelected;

  const variants: Variants = {
    hidden: { opacity: 0, x: -10 },
    visible: { opacity: 1, x: 0, transition: { type: 'spring', stiffness: 120, delay: 0.2 } },
  };

  return (
    <>
      <Icon
        size={26}
        data-text-is-gray={textIsGray}
        className="text-green-500 data-[text-is-gray=true]:text-gray-500 data-[text-is-gray=true]:dark:text-gray-400"
      />

      <AnimatePresence mode="wait">
        {sidebarOpened && (
          <Text
            data-text-is-gray={textIsGray}
            className="ml-2 text-xs font-semibold text-green-500 data-[text-is-gray=true]:text-gray-500 data-[text-is-gray=true]:dark:text-gray-400"
            initial="hidden"
            animate="visible"
            variants={variants}
          >
            {t(name)}
          </Text>
        )}
      </AnimatePresence>
    </>
  );
};

// ------------------------------------------

export const SidebarItem = (props: IMenuItem): ReactElement => {
  const { id, path, items, ...restProps } = props;

  const { sidebarOpened, menuOpened, menuSelected, handleMenuSelected, handleMenuOpened } = useSidebar();

  const mainElementHasChildSelected = menuSelected && items?.map(item => item.id).includes(menuSelected);

  const handleClickNavigate = (): void => {
    handleMenuSelected(id);
    handleMenuOpened(id);
  };

  return items ? (
    <motion.button
      {...restProps}
      type="button"
      onClick={() => handleMenuOpened(id)}
      data-sidebar-opened={sidebarOpened}
      className="flex w-full items-center justify-center px-6 py-3 transition-all data-[sidebar-opened=true]:justify-start hover:bg-green-50 dark:hover:bg-green-500/10"
    >
      <SidebarItemContent {...props} />
      {sidebarOpened && (
        <Chevron
          height={11}
          className={clsx('ml-auto transition-transform duration-300', {
            'fill-gray-500': menuSelected !== id && !mainElementHasChildSelected,
            'dark:fill-gray-400': menuSelected !== id && !mainElementHasChildSelected,
            'fill-green-500': menuSelected === id || mainElementHasChildSelected,
            'rotate-90': menuOpened !== id,
            'rotate-[270deg]': menuOpened === id,
          })}
        />
      )}
    </motion.button>
  ) : (
    <AnimatePresence mode="wait">
      <motion.button {...restProps} type="button">
        <Link
          to={path}
          onClick={handleClickNavigate}
          data-sidebar-opened={sidebarOpened}
          className="flex w-full items-center justify-center px-6 py-3 transition-all data-[sidebar-opened=true]:justify-start hover:bg-green-50 dark:hover:bg-green-500/10"
        >
          <SidebarItemContent {...props} />
        </Link>
      </motion.button>
    </AnimatePresence>
  );
};
