import { AxiosInstance, AxiosResponse } from 'axios';

import { IFoodForecastDetailed } from '@modules/horses/types/FeedsFoodForeacast/feedsFoodForecast';
import { IGetFoodForecastDetailedRequest } from '@modules/horses/types/FeedsFoodForeacast/requests';

export class HorsesFeedsFoodForecast {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  getFoodForecast(params: IGetFoodForecastDetailedRequest): Promise<AxiosResponse<IFoodForecastDetailed[]>> {
    return this.instance.get(`/horses/feeds/food-forecast`, { params });
  }
}

export const horsesFeedsFoodForecast = (instance: AxiosInstance): HorsesFeedsFoodForecast =>
  new HorsesFeedsFoodForecast(instance);
