/**
 * ModalitiesRoles
 * @readonly
 * @enum {string}
 */
export enum ModalitiesRoles {
  /** Create a new custom modality category. */
  CREATE_MODALITY_CATEGORY = 'CREATE_MODALITY_CATEGORY',

  /** Delete a modality category. */
  DELETE_MODALITY_CATEGORY = 'DELETE_MODALITY_CATEGORY',

  /** List all modalities. */
  GET_MODALITIES = 'GET_MODALITIES',

  /** List all modalities categories. */
  GET_MODALITIES_CATEGORIES = 'GET_MODALITIES_CATEGORIES',

  /** Update a custom modality category. */
  UPDATE_MODALITY_CATEGORY = 'UPDATE_MODALITY_CATEGORY',
}
