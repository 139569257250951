import { AxiosInstance } from 'axios';

import { IClientConfigProps } from '@shared/clients/types/types';

import { Notifications, notifications } from '@modules/notifications/services/Notifications';

import { notificationsApiConfig } from './config';

export class NotificationsAPI {
  private instance: AxiosInstance;

  constructor(params: IClientConfigProps) {
    this.instance = notificationsApiConfig(params).instance();
  }

  notifications(): Notifications {
    return notifications(this.instance);
  }
}

export const NotificationsFactoryAPI = (params: IClientConfigProps): NotificationsAPI => new NotificationsAPI(params);
