/**
 * WarehouseRoles
 * @readonly
 * @enum {string}
 */
export enum WarehouseRoles {
  /** Create a new food. */
  CREATE_FOOD = 'CREATE_FOOD',

  /** Get a foods summary list. */
  GET_FOODS = 'GET_FOODS',

  /** Update a food. */
  UPDATE_FOOD = 'UPDATE_FOOD',
}
