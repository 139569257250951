import { AxiosInstance } from 'axios';

import { IPaginateDTO } from '@shared/dtos/IPaginateDTO';

import { HorseByOwner, Owner } from '@modules/owners/types/Owners/owners';
import {
  CreateLinkHorsesWithOwnerRequest,
  CreateOwnerRequest,
  DeleteLinkHorsesWithOwnerRequest,
  DeleteOwnerRequest,
  FindHorsesByOwnerIdRequest,
  FindOwnerByIdRequest,
  FindOwnersByCompanyIdRequest,
  UpdateOwnerRequest,
} from '@modules/owners/types/Owners/requests';

export class Owners {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Owners ----------

  async createOwner(data: CreateOwnerRequest): Promise<Owner> {
    const response = await this.instance.post(`/owners`, data);

    return response.data;
  }

  async deleteOwner(data: DeleteOwnerRequest): Promise<void> {
    const { ownerId } = data;

    const response = await this.instance.delete(`/owners/${ownerId}`);

    return response.data;
  }

  async getOwnerById({ ownerId }: FindOwnerByIdRequest): Promise<Owner> {
    const response = await this.instance.get(`/owners/${ownerId}`);

    return response.data;
  }

  async getOwners(params: FindOwnersByCompanyIdRequest): Promise<IPaginateDTO<Owner>> {
    const response = await this.instance.get(`/owners`, { params });

    return response.data;
  }

  async updateOwner(data: UpdateOwnerRequest): Promise<Owner> {
    const { ownerId, ...body } = data;

    const response = await this.instance.patch(`/owners/${ownerId}`, body);

    return response.data;
  }

  // ---------- Horse owners ----------

  async createLinkHorseWithOwner(data: CreateLinkHorsesWithOwnerRequest): Promise<HorseByOwner> {
    const { ownerId, ...body } = data;

    const response = await this.instance.post(`/owners/${ownerId}/horses`, body);

    return response.data;
  }

  async getHorsesByOwner({ ownerId, ...params }: FindHorsesByOwnerIdRequest): Promise<HorseByOwner[]> {
    const response = await this.instance.get(`/owners/${ownerId}/horses`, { params });

    return response.data;
  }

  async unlinkHorseWithOwner({ horseId, ownerId }: DeleteLinkHorsesWithOwnerRequest): Promise<void> {
    await this.instance.delete(`/owners/${ownerId}/horses/${horseId}`);
  }
}

export const owners = (instance: AxiosInstance): Owners => new Owners(instance);
