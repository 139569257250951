import { AxiosInstance, AxiosResponse } from 'axios';

import { IPaginateDTO } from '@shared/dtos/IPaginateDTO';

import { IHorseHorseshoe } from '@modules/horses/types/Horseshoes/horseshoes';
import {
  ICreateHorseHorseshoeRequest,
  IDeleteHorseHorseshoeRequest,
  IFindHorseHorseshoesByHorseIdRequest,
} from '@modules/horses/types/Horseshoes/requests';

type IGetHorseHorseshoesResponse = Promise<AxiosResponse<IPaginateDTO<IHorseHorseshoe>>>;

export class HorseHorseshoes {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  createHorseshoe(data: ICreateHorseHorseshoeRequest): Promise<AxiosResponse<IHorseHorseshoe>> {
    const { horseId, ...body } = data;
    return this.instance.post(`/horses/${horseId}/horseshoes`, body);
  }

  deleteHorseshoe(data: IDeleteHorseHorseshoeRequest): Promise<AxiosResponse<IHorseHorseshoe>> {
    const { horseId, horseshoeId } = data;
    return this.instance.delete(`/horses/${horseId}/horseshoes/${horseshoeId}`);
  }

  getHorseHorseshoes(data: IFindHorseHorseshoesByHorseIdRequest): IGetHorseHorseshoesResponse {
    const { horseId, ...params } = data;
    return this.instance.get(`/horses/${horseId}/horseshoes`, { params });
  }
}

export const horseHorseshoes = (instance: AxiosInstance): HorseHorseshoes => new HorseHorseshoes(instance);
