import { FC, PropsWithChildren, useEffect, useMemo } from 'react';

import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';

import { useProfile } from '@modules/profiles/hooks/useProfile';

const FeatureFlagProvider: FC<PropsWithChildren> = ({ children }) => {
  const { currentProfile } = useProfile();

  const featureFlagClientKey = import.meta.env.VITE_FEATURE_FLAG_CLIENT_KEY;

  const gb = useMemo(() => {
    return new GrowthBook({
      apiHost: 'https://cdn.growthbook.io',
      clientKey: featureFlagClientKey,
      enableDevMode: false,
    });
  }, [featureFlagClientKey]);

  useEffect(() => {
    if (featureFlagClientKey) {
      gb.init({ streaming: true });

      gb.setAttributes({
        identifierId: currentProfile.identifierId,
        company: currentProfile.companyName,
        companyId: currentProfile.companyId,
        name: currentProfile.name,
        username: currentProfile.username,
      });
    }
  }, [currentProfile, featureFlagClientKey, gb]);

  return <GrowthBookProvider growthbook={gb}>{children}</GrowthBookProvider>;
};

export { FeatureFlagProvider };
