import { AxiosInstance, AxiosResponse } from 'axios';

import {
  ICreateHorseFoodRequest,
  ICreateHorseFoodResponse,
  IDeleteHorseFoodRequest,
  IFindHorseFoodsByHorseIdRequest,
  IFindHorseFoodsByHorseIdResponse,
  IUpdateHorseFoodRequest,
  IUpdateHorseFoodResponse,
} from '@modules/horses/types/Foods/requests';

export class HorseFoods {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  createHorseFood(data: ICreateHorseFoodRequest): Promise<AxiosResponse<ICreateHorseFoodResponse>> {
    const { horseId, ...body } = data;
    return this.instance.post(`/horses/${horseId}/foods`, body);
  }

  deleteHorseFoods(data: IDeleteHorseFoodRequest): Promise<AxiosResponse<void>> {
    const { horseId, horseFoodId } = data;
    return this.instance.delete(`/horses/${horseId}/foods/${horseFoodId}`);
  }

  getHorseFoods(data: IFindHorseFoodsByHorseIdRequest): Promise<AxiosResponse<IFindHorseFoodsByHorseIdResponse[]>> {
    const { horseId } = data;
    return this.instance.get(`/horses/${horseId}/foods`);
  }

  updateHorseFood(data: IUpdateHorseFoodRequest): Promise<AxiosResponse<IUpdateHorseFoodResponse>> {
    const { horseId, horseFoodId, ...body } = data;
    return this.instance.patch(`/horses/${horseId}/foods/${horseFoodId}`, body);
  }
}

export const horseFoods = (instance: AxiosInstance): HorseFoods => new HorseFoods(instance);
