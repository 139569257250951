import { ReactElement } from 'react';

import { Root as SwitchPrimitiveRoot, Thumb as SwitchPrimitiveThumb } from '@radix-ui/react-switch';

import { switchVariants } from '@shared/components/Switch/styles';
import { ISwitchProps } from '@shared/components/Switch/types';

export const Switch = ({ checked, disabled, onCheckedChange, ...props }: ISwitchProps): ReactElement => {
  const { root, thumb } = switchVariants({ checked });

  return (
    <SwitchPrimitiveRoot
      checked={checked}
      onCheckedChange={onCheckedChange}
      disabled={disabled}
      aria-disabled={disabled}
      className={root()}
      {...props}
    >
      <SwitchPrimitiveThumb aria-disabled={disabled} className={thumb()} />
    </SwitchPrimitiveRoot>
  );
};
