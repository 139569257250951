import { AxiosInstance, AxiosResponse } from 'axios';

import { IPaginateDTO } from '@shared/dtos/IPaginateDTO';

import { IHorseMedicine } from '@modules/horses/types/Medicines/medicines';
import {
  ICreateHorseMedicineRequest,
  ICreateHorseMedicineResponse,
  IDeleteHorseMedicineRequest,
  IFindHorseMedicinesByHorseIdRequest,
  IUpdateHorseMedicineRequest,
  IUpdateHorseMedicineResponse,
} from '@modules/horses/types/Medicines/requests';

type IGetHorseMedicinesResponse = Promise<AxiosResponse<IPaginateDTO<IHorseMedicine>>>;

export class HorseMedicines {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  createMedicine(data: ICreateHorseMedicineRequest): Promise<AxiosResponse<ICreateHorseMedicineResponse>> {
    const { horseId, ...body } = data;
    return this.instance.post(`/horses/${horseId}/medicines`, body);
  }

  deleteMedicine(data: IDeleteHorseMedicineRequest): Promise<AxiosResponse<void>> {
    const { horseId, medicineId } = data;
    return this.instance.delete(`/horses/${horseId}/medicines/${medicineId}`);
  }

  getHorseMedicines(data: IFindHorseMedicinesByHorseIdRequest): IGetHorseMedicinesResponse {
    const { horseId, ...params } = data;
    return this.instance.get(`/horses/${horseId}/medicines`, { params });
  }

  updateMedicine(data: IUpdateHorseMedicineRequest): Promise<AxiosResponse<IUpdateHorseMedicineResponse>> {
    const { horseId, medicineId, ...body } = data;
    return this.instance.patch(`/horses/${horseId}/medicines/${medicineId}`, body);
  }
}

export const horseMedicines = (instance: AxiosInstance): HorseMedicines => new HorseMedicines(instance);
