import { AxiosInstance, AxiosResponse } from 'axios';

import { IAddressByZipCode } from '@modules/addresses/types/addresses';
import { IFindAddressByZipCodeRequest } from '@modules/addresses/types/requests';

export class Addresses {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Addresses ----------

  finByZipCode(data: IFindAddressByZipCodeRequest): Promise<AxiosResponse<IAddressByZipCode>> {
    const { zipCode } = data;
    return this.instance.get(`/addresses/zip-code/${zipCode}`);
  }
}

export const addresses = (instance: AxiosInstance): Addresses => new Addresses(instance);
