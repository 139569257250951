import { FC, PropsWithChildren } from 'react';

import { ProfileCompanyDataProvider } from './About';
import { ProfileAboutProvider } from './CompanyData';
import { ProfileProvider } from './Profile';

export const AppProfileProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <ProfileProvider>
      <ProfileAboutProvider>
        <ProfileCompanyDataProvider>{children}</ProfileCompanyDataProvider>
      </ProfileAboutProvider>
    </ProfileProvider>
  );
};
