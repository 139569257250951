import { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useQueryClient } from '@tanstack/react-query';
import { createContext } from 'use-context-selector';

import { CompaniesClient } from '@shared/clients/http/CompaniesClient';
import { toast } from '@shared/components/Toast';
import { IPaginateDTO } from '@shared/dtos/IPaginateDTO';
import { HandleApiErrors } from '@shared/utils/HandleApiErrors';

import { IBalance, IBalanceAmount, IBalanceFilter } from '@modules/balances/types/Balances/balances';
import { IBalancesContext } from '@modules/balances/types/Balances/context';
import {
  IBalancesReportRequest,
  ICreateBalanceRequest,
  ICreateBalanceTransferenceRequest,
  IDeleteBalanceAttachmentRequest,
  IDeleteBalanceRequest,
  IDeleteBalanceTransferenceAttachmentRequest,
  IDeleteBalanceTransferenceRequest,
  IFindBalancesAmountsByCompanyIdRequest,
  IFindBalancesByCompanyIdRequest,
  IUpdateBalanceAttachmentRequest,
  IUpdateBalanceRequest,
  IUpdateBalanceTransferenceAttachmentRequest,
  IUpdateBalanceTransferenceRequest,
} from '@modules/balances/types/Balances/requests';

import { useLoader } from '@modules/globals/hooks/useLoader';

const BalancesContext = createContext({} as IBalancesContext);
BalancesContext.displayName = 'Balances';

const BalancesProvider: FC<PropsWithChildren> = ({ children }) => {
  const { startLoad, endLoad } = useLoader();

  const { t } = useTranslation('balances', { keyPrefix: 'messages' });

  const [balances, setBalances] = useState({} as IPaginateDTO<IBalance>);
  const [balancesAmounts, setBalancesAmounts] = useState({} as IBalanceAmount);
  const [balancesFilter, setBalancesFilter] = useState({} as IBalanceFilter);

  const queryClient = useQueryClient();

  const invalidateCache = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ['association-riders:payments:list'], exact: false });
  }, [queryClient]);

  const getBalances = useCallback(
    async (data: IFindBalancesByCompanyIdRequest) => {
      try {
        startLoad();

        const response = await CompaniesClient.balances().findBalancesPaginated(data);

        setBalances(response.data);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const getBalancesAmounts = useCallback(
    async (data: IFindBalancesByCompanyIdRequest) => {
      try {
        startLoad();

        const payload: IFindBalancesAmountsByCompanyIdRequest = {
          from: data.from,
          to: data.to,
          categoryId: data.categoryId,
          justPaid: data.justPaid,
          justUnpaid: data.justUnpaid,
          search: data.search,
          type: data.type,
          walletId: data.walletId,
        };

        const response = await CompaniesClient.balances().getAmounts(payload);

        setBalancesAmounts(response.data);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const handleBalancesFilter = useCallback((filter: Partial<IBalanceFilter>, reset?: boolean) => {
    setBalancesFilter(current => (reset ? (filter as IBalanceFilter) : { ...current, ...filter }));
  }, []);

  const createBalance = useCallback(
    async (data: ICreateBalanceRequest) => {
      try {
        startLoad();

        await CompaniesClient.balances().createBalance(data);

        toast(t('balance_created_success'), { type: 'success' });

        await Promise.all([getBalances(balancesFilter), getBalancesAmounts(balancesFilter)]);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [balancesFilter, endLoad, getBalances, getBalancesAmounts, startLoad, t],
  );

  const deleteBalance = useCallback(
    async (data: IDeleteBalanceRequest) => {
      try {
        startLoad();

        await CompaniesClient.balances().deleteBalance(data);

        toast(t('balance_deleted_success'), { type: 'success' });

        await Promise.all([getBalances(balancesFilter), getBalancesAmounts(balancesFilter)]);

        invalidateCache();
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [balancesFilter, endLoad, getBalances, getBalancesAmounts, invalidateCache, startLoad, t],
  );

  const deleteBalanceAttachment = useCallback(
    async (data: IDeleteBalanceAttachmentRequest) => {
      try {
        startLoad();

        await CompaniesClient.balances().deleteBalanceAttachment(data);

        toast(t('balance_attachment_deleted_success'), { type: 'success' });

        await getBalances(balancesFilter);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [balancesFilter, endLoad, getBalances, startLoad, t],
  );

  const updateBalance = useCallback(
    async (data: IUpdateBalanceRequest) => {
      try {
        startLoad();

        await CompaniesClient.balances().updateBalance(data);

        toast(t('balance_updated_success'), { type: 'success' });

        invalidateCache();
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, invalidateCache, startLoad, t],
  );

  const updateBalanceAttachment = useCallback(
    async (data: IUpdateBalanceAttachmentRequest) => {
      try {
        startLoad();

        await CompaniesClient.balances().updateBalanceAttachment(data);

        toast(t('balance_attachment_updated_success'), { type: 'success' });

        await getBalances(balancesFilter);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [balancesFilter, endLoad, getBalances, startLoad, t],
  );

  const createTransference = useCallback(
    async (data: ICreateBalanceTransferenceRequest) => {
      try {
        startLoad();

        await CompaniesClient.balances().createTransference(data);

        toast(t('transference_created_success'), { type: 'success' });

        await getBalances(balancesFilter);

        if (balancesFilter.walletId || balancesFilter.categoryId) {
          await getBalancesAmounts(balancesFilter);
        }
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [balancesFilter, endLoad, getBalances, getBalancesAmounts, startLoad, t],
  );

  const deleteTransference = useCallback(
    async (data: IDeleteBalanceTransferenceRequest) => {
      try {
        startLoad();

        await CompaniesClient.balances().deleteTransference(data);

        toast(t('transference_deleted_success'), { type: 'success' });

        await getBalances(balancesFilter);

        if (balancesFilter.walletId || balancesFilter.categoryId) {
          await getBalancesAmounts(balancesFilter);
        }
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [balancesFilter, endLoad, getBalances, getBalancesAmounts, startLoad, t],
  );

  const deleteTransferenceAttachment = useCallback(
    async (data: IDeleteBalanceTransferenceAttachmentRequest) => {
      try {
        startLoad();

        await CompaniesClient.balances().deleteTransferenceAttachment(data);

        toast(t('balance_attachment_deleted_success'), { type: 'success' });

        await getBalances(balancesFilter);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [balancesFilter, endLoad, getBalances, startLoad, t],
  );

  const updateTransference = useCallback(
    async (data: IUpdateBalanceTransferenceRequest) => {
      try {
        startLoad();

        await CompaniesClient.balances().updateTransference(data);

        toast(t('transference_updated_success'), { type: 'success' });

        await getBalances(balancesFilter);

        if (balancesFilter.walletId || balancesFilter.categoryId) {
          await getBalancesAmounts(balancesFilter);
        }
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [balancesFilter, endLoad, getBalances, getBalancesAmounts, startLoad, t],
  );

  const updateTransferenceAttachment = useCallback(
    async (data: IUpdateBalanceTransferenceAttachmentRequest) => {
      try {
        startLoad();

        await CompaniesClient.balances().updateTransferenceAttachment(data);

        toast(t('balance_attachment_updated_success'), { type: 'success' });

        await getBalances(balancesFilter);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [balancesFilter, endLoad, getBalances, startLoad, t],
  );

  const exportBalancesCsv = useCallback(
    async (data: IBalancesReportRequest) => {
      try {
        startLoad();

        await CompaniesClient.balancesReports().exportBalancesCsv(data);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const exportBalancesXlsx = useCallback(
    async (data: IBalancesReportRequest) => {
      try {
        startLoad();

        await CompaniesClient.balancesReports().exportBalancesXlsx(data);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const exportBalancesPdf = useCallback(
    async (data: IBalancesReportRequest) => {
      try {
        startLoad();

        await CompaniesClient.balancesReports().exportBalancesPdf(data);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const contextValue = useMemo<IBalancesContext>(
    () => ({
      balances,
      balancesAmounts,
      balancesFilter,
      createBalance,
      createTransference,
      deleteBalance,
      deleteBalanceAttachment,
      deleteTransference,
      deleteTransferenceAttachment,
      exportBalancesCsv,
      exportBalancesPdf,
      exportBalancesXlsx,
      getBalances,
      getBalancesAmounts,
      handleBalancesFilter,
      updateBalance,
      updateBalanceAttachment,
      updateTransference,
      updateTransferenceAttachment,
    }),
    [
      balances,
      balancesAmounts,
      balancesFilter,
      createBalance,
      createTransference,
      deleteBalance,
      deleteBalanceAttachment,
      deleteTransference,
      deleteTransferenceAttachment,
      exportBalancesCsv,
      exportBalancesPdf,
      exportBalancesXlsx,
      getBalances,
      getBalancesAmounts,
      handleBalancesFilter,
      updateBalance,
      updateBalanceAttachment,
      updateTransference,
      updateTransferenceAttachment,
    ],
  );

  return <BalancesContext.Provider value={contextValue}>{children}</BalancesContext.Provider>;
};

export { BalancesContext, BalancesProvider };
