export const Storage = {
  APPOINTMENT_TO_EDIT: '@Equites:explosion:appointment_to_edit',
  BIG_CALENDAR_WEEKDAYS_VIEW: '@Equites:explosion:big_calendar_weekdays_view',
  CURRENT_PROFILE: '@Equites:explosion:profile:current',
  CURRENT_SUBSCRIPTION: '@Equites:explosion:subscription:current',
  CREATE_SUBSCRIPTION_DATA: '@Equites:explosion:subscription:create:data',
  FIELDS_BALANCE_MOVEMENTS: '@Equites:explosion:fields:balance_movements',
  LANGUAGE: '@Equites:explosion:language',
  PERMISSIONS: '@Equites:explosion:permissions',
  PLAN_TO_CREATE: '@Equites:explosion:plan_to_create',
  RIDER_TO_CREATE: '@Equites:explosion:rider_to_create',
  THEME: '@Equites:explosion:theme',
  TOKENS: '@Equites:explosion:tokens',
  VIEW_HORSES_LIST: '@Equites:horses:list:view',
  VIEW_RIDERS_LIST: '@Equites:riders:list:view',
};
