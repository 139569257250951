import { AxiosInstance } from 'axios';

import {
  CreateAppointmentRuleRequest,
  DeleteAppointmentRuleRequest,
  FindAppointmentRuleByRidersIdsRequest,
  UpdateAppointmentRuleRequest,
} from '@modules/riders/types/AppointmentsRules/requests';
import { AppointmentRuleSummary } from '@modules/riders/types/AppointmentsRules/rules';

export class RiderAppointmentRules {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Rider appointment rules ----------

  async createAppointmentRule(data: CreateAppointmentRuleRequest): Promise<AppointmentRuleSummary> {
    const { riderId, ...body } = data;

    const response = await this.instance.post(`/appointments/riders/${riderId}/rules`, body);

    return response.data;
  }

  async deleteAppointmentRule(data: DeleteAppointmentRuleRequest): Promise<void> {
    const { appointmentRuleId, riderId } = data;

    const response = await this.instance.delete(`/appointments/riders/${riderId}/rules/${appointmentRuleId}`);

    return response.data;
  }

  async updateAppointmentRule(data: UpdateAppointmentRuleRequest): Promise<AppointmentRuleSummary> {
    const { appointmentRuleId, riderId, ...body } = data;

    const response = await this.instance.put(`/appointments/riders/${riderId}/rules/${appointmentRuleId}`, body);

    return response.data;
  }

  async getAppointmentRulesByRider(data: FindAppointmentRuleByRidersIdsRequest): Promise<AppointmentRuleSummary[]> {
    const { riderId } = data;

    const response = await this.instance.get(`/appointments/riders/${riderId}/rules`);

    return response.data;
  }
}

export const riderAppointmentRules = (instance: AxiosInstance): RiderAppointmentRules =>
  new RiderAppointmentRules(instance);
