/**
 * AppointmentModalityRoles
 * @readonly
 * @enum {string}
 */
export enum AppointmentModalityRoles {
  /** Create a new appointment modality. */
  CREATE_APPOINTMENT_MODALITY = 'CREATE_APPOINTMENT_MODALITY',

  /** Get appointments modalities. */
  GET_APPOINTMENTS_MODALITIES = 'GET_APPOINTMENTS_MODALITIES',

  /** Update an appointment modality. */
  UPDATE_APPOINTMENT_MODALITY = 'UPDATE_APPOINTMENT_MODALITY',
}
