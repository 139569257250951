/**
 * AssociationsAffiliationsRoles
 * @readonly
 * @enum {string}
 */
export enum AssociationsAffiliationsRoles {
  /** Create a new affiliation. */
  CREATE_ASSOCIATION_AFFILIATION = 'CREATE_ASSOCIATION_AFFILIATION',

  /** Delete an affiliation. */
  DELETE_ASSOCIATION_AFFILIATION = 'DELETE_ASSOCIATION_AFFILIATION',

  /** Get affiliations. */
  GET_ASSOCIATIONS_AFFILIATION = 'GET_ASSOCIATIONS_AFFILIATION',

  /** Update an affiliation. */
  UPDATE_ASSOCIATION_AFFILIATION = 'UPDATE_ASSOCIATION_AFFILIATION',
}
