import { AxiosInstance, AxiosResponse } from 'axios';

import { ILicenseToSubscribe } from '@modules/licenses/types/Licenses/license';

export class Licenses {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Licenses to subscribe ----------

  getLicensesToSubscribe(): Promise<AxiosResponse<ILicenseToSubscribe[]>> {
    return this.instance.get('/licenses/to-subscribe');
  }
}

export const licenses = (instance: AxiosInstance): Licenses => new Licenses(instance);
