/**
 * AssociationsCompaniesRoles
 * @readonly
 * @enum {string}
 */
export enum AssociationsCompaniesRoles {
  /** Create a new company to associations. */
  CREATE_ASSOCIATION_COMPANY = 'CREATE_ASSOCIATION_COMPANY',

  /** Delete a company from associations. */
  DELETE_ASSOCIATION_COMPANY = 'DELETE_ASSOCIATION_COMPANY',

  /** Get companies from associations. */
  GET_ASSOCIATIONS_COMPANIES = 'GET_ASSOCIATIONS_COMPANIES',

  /** Update a company from association. */
  UPDATE_ASSOCIATION_COMPANY = 'UPDATE_ASSOCIATION_COMPANY',
}
