import { AxiosInstance, AxiosResponse } from 'axios';

import { IPaginateDTO } from '@shared/dtos/IPaginateDTO';

import { IInvoice, INextAndLastPayment } from '@modules/invoices/types/Invoices/invoices';
import { FindInvoicesFromSubscriptionDTO } from '@modules/invoices/types/Invoices/requests';

import {
  IChangeCreditCardDTO,
  IChangeLicenseSimulationDTO,
  IUpgradeSubscriptionDTO,
} from '@modules/subscriptions/types/Subscriptions/requests';
import { IChangeLicenseSimulation, ISubscription } from '@modules/subscriptions/types/Subscriptions/subscriptions';

export class Subscriptions {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Subscriptions ----------

  activateSubscription(): Promise<AxiosResponse<ISubscription>> {
    return this.instance.post('/subscriptions/me/activate');
  }

  cancelSubscription(): Promise<AxiosResponse<ISubscription>> {
    return this.instance.post('/subscriptions/me/cancel');
  }

  changeCreditCard(data: IChangeCreditCardDTO): Promise<AxiosResponse<void>> {
    return this.instance.put(`/subscriptions/me/credit-card`, data);
  }

  changeLicenseSimulation(data: IChangeLicenseSimulationDTO): Promise<AxiosResponse<IChangeLicenseSimulation>> {
    return this.instance.get(`/subscriptions/me/simulation/licenses/${data.licenseId}`);
  }

  getCurrent(): Promise<AxiosResponse<ISubscription>> {
    return this.instance.get(`/subscriptions/me/current`);
  }

  upgradeSubscription(data: IUpgradeSubscriptionDTO): Promise<AxiosResponse<ISubscription>> {
    return this.instance.post('/subscriptions/me/upgrade', data);
  }

  // ---------- Invoices ----------

  getInvoices(params: FindInvoicesFromSubscriptionDTO): Promise<AxiosResponse<IPaginateDTO<IInvoice>>> {
    return this.instance.get(`/subscriptions/me/invoices`, { params });
  }

  getLastAndNextInvoice(): Promise<AxiosResponse<INextAndLastPayment>> {
    return this.instance.get(`/subscriptions/me/invoices/last-next-invoices`);
  }
}

export const subscriptions = (instance: AxiosInstance): Subscriptions => new Subscriptions(instance);
