/**
 * AssociationsHorsesRoles
 * @readonly
 * @enum {string}
 */
export enum AssociationsHorsesRoles {
  /** Create a new horse to associations. */
  CREATE_ASSOCIATION_HORSE = 'CREATE_ASSOCIATION_HORSE',

  /** Create a new horse to associations. */
  CREATE_ASSOCIATION_HORSE_PAYMENT = 'CREATE_ASSOCIATION_HORSE_PAYMENT',

  /** Create a new stamp to association horses. */
  CREATE_ASSOCIATION_HORSE_STAMP = 'CREATE_ASSOCIATION_HORSE_STAMP',

  /** Delete a horse from associations. */
  DELETE_ASSOCIATION_HORSE = 'DELETE_ASSOCIATION_HORSE',

  /** Delete a horse from associations. */
  DELETE_ASSOCIATION_HORSE_PAYMENT = 'DELETE_ASSOCIATION_HORSE_PAYMENT',

  /** Delete a stamp from association horse. */
  DELETE_ASSOCIATION_HORSE_STAMP = 'DELETE_ASSOCIATION_HORSE_STAMP',

  /** Delete a passport attachment from association horse. */
  DELETE_ASSOCIATION_HORSE_PASSPORT_ATTACHMENT = 'DELETE_ASSOCIATION_HORSE_PASSPORT_ATTACHMENT',

  /** Export association horses list. */
  EXPORT_ASSOCIATION_HORSES = 'EXPORT_ASSOCIATION_HORSES',

  /** Get horses from associations. */
  GET_ASSOCIATIONS_HORSES = 'GET_ASSOCIATIONS_HORSES',

  /** Get horses identification from associations. */
  GET_ASSOCIATIONS_HORSES_IDENTIFICATION = 'GET_ASSOCIATIONS_HORSES_IDENTIFICATION',

  /** Get horses from associations. */
  GET_ASSOCIATIONS_HORSES_PAYMENTS = 'GET_ASSOCIATIONS_HORSES_PAYMENTS',

  /** Get horses stamps from associations. */
  GET_ASSOCIATIONS_HORSES_STAMPS = 'GET_ASSOCIATIONS_HORSES_STAMPS',

  /** Update a horse from association. */
  UPDATE_ASSOCIATION_HORSE = 'UPDATE_ASSOCIATION_HORSE',

  /** Update a horse identification from association. */
  UPDATE_ASSOCIATION_HORSE_IDENTIFICATION = 'UPDATE_ASSOCIATION_HORSE_IDENTIFICATION',

  /** Update a horse from association. */
  UPDATE_ASSOCIATION_HORSE_PAYMENT = 'UPDATE_ASSOCIATION_HORSE_PAYMENT',

  /** Update a stamp from association horse. */
  UPDATE_ASSOCIATION_HORSE_STAMP = 'UPDATE_ASSOCIATION_HORSE_STAMP',

  /** Upload new passport attachments. */
  UPLOAD_PASSPORT_ATTACHMENTS = 'UPLOAD_PASSPORT_ATTACHMENTS',
}
