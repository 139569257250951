import { FC, memo, ReactElement } from 'react';

import { Slot, SlotProps } from '@radix-ui/react-slot';
import { ForwardRefComponent, HTMLMotionProps, motion } from 'framer-motion';
import { twJoin } from 'tailwind-merge';

import { ITextProps } from './types';

const TextComponent: FC<ITextProps> = ({ children, asChild, className, ...props }): ReactElement => {
  const Component:
    | React.ForwardRefExoticComponent<SlotProps & React.RefAttributes<HTMLElement>>
    | ForwardRefComponent<HTMLSpanElement, HTMLMotionProps<'span'>> = asChild ? Slot : motion.span;

  return (
    <Component className={twJoin(`font-sans leading-none antialiased`, className)} {...props}>
      {children}
    </Component>
  );
};

export const Text = memo(TextComponent);
