import { createContext, FC, PropsWithChildren, useCallback, useMemo } from 'react';
import { useEffectOnce } from 'react-use';

import { Storage } from '@shared/constants/Storage';
import { usePersistedState } from '@shared/utils/usePersistedState';

type ITheme = 'dark' | 'light';

export interface IThemeContext {
  theme: ITheme;
  handleChangeTheme: () => void;
}

const ThemeContext = createContext<IThemeContext>({} as IThemeContext);
ThemeContext.displayName = 'Theme';

const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  const [theme, setTheme] = usePersistedState<ITheme>(Storage.THEME, 'light');

  const handleChangeTheme = useCallback(() => {
    setTheme(current => {
      const newTheme = current === 'dark' ? 'light' : 'dark';

      document.querySelector('html')?.classList.remove(current);
      document.querySelector('html')?.classList.add(newTheme);

      return newTheme;
    });
  }, [setTheme]);

  useEffectOnce(() => {
    document.querySelector('html')?.classList.add(theme);
  });

  const contextValue = useMemo<IThemeContext>(() => ({ theme, handleChangeTheme }), [handleChangeTheme, theme]);

  return <ThemeContext.Provider value={contextValue}>{children}</ThemeContext.Provider>;
};

export { ThemeContext, ThemeProvider };
