import { BalancesDashboardProvider } from '@modules/balances/contexts/Dashboard';

import { BalancesProvider } from './Balances';
import { BalanceReportProvider } from './Reports';

export const AppBalancesProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <BalancesProvider>
      <BalancesDashboardProvider>
        <BalanceReportProvider>{children}</BalanceReportProvider>
      </BalancesDashboardProvider>
    </BalancesProvider>
  );
};
