import { useCallback, useMemo, useState } from 'react';

import { createContext } from 'use-context-selector';

import { CompaniesClient } from '@shared/clients/http/CompaniesClient';
import { HandleApiErrors } from '@shared/utils/HandleApiErrors';

import { useLoader } from '@modules/globals/hooks/useLoader';

import { ICurrentProfileCompanyData } from '@modules/profiles/types/CompanyData/companyData';
import { IProfileCompanyDataContext } from '@modules/profiles/types/CompanyData/context';

const ProfileCompanyDataContext = createContext({} as IProfileCompanyDataContext);
ProfileCompanyDataContext.displayName = 'ProfileCompanyData';

const ProfileCompanyDataProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { startLoad, endLoad } = useLoader();

  const [currentProfileCompanyData, setCurrentProfileCompanyData] = useState({} as ICurrentProfileCompanyData);

  const getCurrentProfileCompanyData = useCallback(async () => {
    try {
      startLoad();

      const response = await CompaniesClient.profilesCompanyData().getCurrentProfileCompanyData();

      setCurrentProfileCompanyData(response.data);
    } catch (err) {
      HandleApiErrors.handle({ err });
    } finally {
      endLoad();
    }
  }, [endLoad, startLoad]);

  const contextValue = useMemo<IProfileCompanyDataContext>(
    () => ({ currentProfileCompanyData, getCurrentProfileCompanyData }),
    [currentProfileCompanyData, getCurrentProfileCompanyData],
  );

  return <ProfileCompanyDataContext.Provider value={contextValue}>{children}</ProfileCompanyDataContext.Provider>;
};

export { ProfileCompanyDataProvider, ProfileCompanyDataContext };
