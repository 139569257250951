import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import clsx from 'clsx';
import { Variants } from 'framer-motion';

import { Chevron } from '@shared/components/Chevron';
import { Text } from '@shared/components/Text';
import { IMenuItem } from '@shared/routes/MenuItems';

import { useSidebar } from '@modules/globals/hooks/useSidebar';

// ------------------------------------------

export const SidebarMobileItemContent = ({ id, icon: Icon, items, name }: IMenuItem): ReactElement => {
  const { menuSelected } = useSidebar();

  const { t } = useTranslation('sidebar');

  const mainElementHasChildSelected = menuSelected && items?.map(item => item.id).includes(menuSelected);
  const textIsGray = menuSelected !== id && !mainElementHasChildSelected;

  const variants: Variants = {
    hidden: { opacity: 0, x: -10 },
    visible: { opacity: 1, x: 0, transition: { type: 'spring', stiffness: 120, delay: 0.4 } },
  };

  return (
    <>
      <Icon
        size={28}
        data-text-is-gray={textIsGray}
        className="text-green-500 data-[text-is-gray=true]:text-gray-500 data-[text-is-gray=true]:dark:text-gray-400"
      />

      <Text
        data-text-is-gray={textIsGray}
        className="ml-2 text-sm font-semibold text-green-500 data-[text-is-gray=true]:text-gray-500 data-[text-is-gray=true]:dark:text-gray-400"
        initial="hidden"
        animate="visible"
        variants={variants}
      >
        {t(name)}
      </Text>
    </>
  );
};

// ------------------------------------------

export const SidebarMobileItem = (props: IMenuItem): ReactElement => {
  const { id, path, items } = props;

  const { sidebarOpened, menuOpened, menuSelected, handleMenuSelected, handleMenuOpened, toggleSidebarOpened } =
    useSidebar();

  const mainElementHasChildSelected = menuSelected && items?.map(item => item.id).includes(menuSelected);

  const handleClickNavigate = (): void => {
    handleMenuSelected(id);
    handleMenuOpened(id);
    toggleSidebarOpened();
  };

  return items ? (
    <button type="button" onClick={() => handleMenuOpened(id)} className="flex w-full items-center py-3 pl-6 pr-4">
      <SidebarMobileItemContent {...props} />

      {sidebarOpened && (
        <Chevron
          height={11}
          className={clsx('ml-auto transition-transform duration-300', {
            'fill-gray-500': menuSelected !== id && !mainElementHasChildSelected,
            'dark:fill-gray-400': menuSelected !== id && !mainElementHasChildSelected,
            'fill-green-500': menuSelected === id || mainElementHasChildSelected,
            'rotate-90': menuOpened !== id,
            'rotate-[270deg]': menuOpened === id,
          })}
        />
      )}
    </button>
  ) : (
    <Link to={path} onClick={handleClickNavigate} className="flex w-full items-center py-2 pl-6">
      <SidebarMobileItemContent {...props} />
    </Link>
  );
};
