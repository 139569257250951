import { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';

import { createContext } from 'use-context-selector';

import { CompaniesClient } from '@shared/clients/http/CompaniesClient';
import { HandleApiErrors } from '@shared/utils/HandleApiErrors';

import { IBalanceDashboardContext } from '@modules/balances/types/Dashboard/context';
import { IDashboardGeneralBalances } from '@modules/balances/types/Dashboard/dashboard';

import { useLoader } from '@modules/globals/hooks/useLoader';

const BalancesDashboardContext = createContext({} as IBalanceDashboardContext);
BalancesDashboardContext.displayName = 'BalanceDashboard';

const BalancesDashboardProvider: FC<PropsWithChildren> = ({ children }) => {
  const { startLoad, endLoad } = useLoader();

  const [dashboardGeneral, setDashboardGeneral] = useState({} as IDashboardGeneralBalances);

  const getBalancesDashboardGeneral = useCallback(async () => {
    try {
      startLoad();

      const response = await CompaniesClient.balancesDashboard().getDashboardGeneral();

      setDashboardGeneral(response.data);
    } catch (err) {
      HandleApiErrors.handle({ err });
    } finally {
      endLoad();
    }
  }, [endLoad, startLoad]);

  const contextValue = useMemo<IBalanceDashboardContext>(
    () => ({
      dashboardGeneral,
      getBalancesDashboardGeneral,
    }),
    [dashboardGeneral, getBalancesDashboardGeneral],
  );

  return <BalancesDashboardContext.Provider value={contextValue}>{children}</BalancesDashboardContext.Provider>;
};

export { BalancesDashboardContext, BalancesDashboardProvider };
