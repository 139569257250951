import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useQueryClient } from '@tanstack/react-query';
import { createContext } from 'use-context-selector';

import { CompaniesClient } from '@shared/clients/http/CompaniesClient';
import { toast } from '@shared/components/Toast';
import { HandleApiErrors } from '@shared/utils/HandleApiErrors';

import { useLoader } from '@modules/globals/hooks/useLoader';

import { IHorseFoodContext } from '@modules/horses/types/Foods/context';
import { IHorseFood } from '@modules/horses/types/Foods/horseFoods';
import {
  ICreateHorseFoodRequest,
  IDeleteHorseFoodRequest,
  IFindHorseFoodsByHorseIdRequest,
  IUpdateHorseFoodRequest,
} from '@modules/horses/types/Foods/requests';

const HorseFoodsContext = createContext({} as IHorseFoodContext);
HorseFoodsContext.displayName = 'HorseFoods';

const HorseFoodsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation('horses', { keyPrefix: 'messages' });

  const { startLoad, endLoad } = useLoader();

  const [horseFoods, setHorseFoods] = useState<IHorseFood[]>([]);

  const queryClient = useQueryClient();

  const invalidateQueries = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ['dashboard:home'] });
    queryClient.invalidateQueries({ queryKey: ['horses:to-feed:list'] });
  }, [queryClient]);

  const getHorseFoods = useCallback(
    async (data: IFindHorseFoodsByHorseIdRequest) => {
      try {
        startLoad();

        const response = await CompaniesClient.horseFoods().getHorseFoods(data);

        setHorseFoods(response.data);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const createHorseFoods = useCallback(
    async (data: ICreateHorseFoodRequest) => {
      try {
        startLoad();

        await CompaniesClient.horseFoods().createHorseFood(data);

        await getHorseFoods({ horseId: data.horseId });

        toast(t('horse_food_created_success'), { type: 'success' });

        invalidateQueries();
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, getHorseFoods, invalidateQueries, startLoad, t],
  );

  const deleteHorseFoods = useCallback(
    async (data: IDeleteHorseFoodRequest) => {
      try {
        startLoad();

        await CompaniesClient.horseFoods().deleteHorseFoods(data);

        await getHorseFoods({ horseId: data.horseId });

        toast(t('horse_feed_deleted_success'), { type: 'success' });

        invalidateQueries();
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, getHorseFoods, invalidateQueries, startLoad, t],
  );

  const updateHorseFoods = useCallback(
    async (data: IUpdateHorseFoodRequest) => {
      try {
        startLoad();

        await CompaniesClient.horseFoods().updateHorseFood(data);

        await getHorseFoods({ horseId: data.horseId });

        toast(t('horse_feed_updated_success'), { type: 'success' });

        invalidateQueries();
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, getHorseFoods, invalidateQueries, startLoad, t],
  );

  const contextValue = useMemo<IHorseFoodContext>(
    () => ({ createHorseFoods, deleteHorseFoods, getHorseFoods, horseFoods, updateHorseFoods }),
    [createHorseFoods, deleteHorseFoods, getHorseFoods, horseFoods, updateHorseFoods],
  );

  return <HorseFoodsContext.Provider value={contextValue}>{children}</HorseFoodsContext.Provider>;
};

export { HorseFoodsProvider, HorseFoodsContext };
