import { AuthProvider } from './Auth';
import { PasswordProvider } from './Password';

export const AppAuthProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <AuthProvider>
      <PasswordProvider>{children}</PasswordProvider>
    </AuthProvider>
  );
};
