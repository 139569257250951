import { createContext, FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';

export interface ILoaderContext {
  isLoading: boolean;
  startLoad: () => void;
  endLoad: () => void;
}

const LoaderContext = createContext<ILoaderContext>({} as ILoaderContext);
LoaderContext.displayName = 'Loader';

const LoaderProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const startLoad = useCallback(() => {
    setIsLoading(true);
  }, []);

  const endLoad = useCallback(() => {
    setIsLoading(false);
  }, []);

  const contextValue = useMemo<ILoaderContext>(
    () => ({ isLoading, startLoad, endLoad }),
    [endLoad, isLoading, startLoad],
  );

  return <LoaderContext.Provider value={contextValue}>{children}</LoaderContext.Provider>;
};

export { LoaderContext, LoaderProvider };
