import { AxiosInstance, AxiosResponse } from 'axios';

import {
  ICreateHorseExamRequest,
  ICreateHorseExamResponse,
  IDeleteHorseExamReportRequest,
  IDeleteHorseExamRequest,
  IFindHorseExamsByHorseIdRequest,
  IFindHorseExamsByHorseIdResponse,
  IUpdateHorseExamRequest,
  IUpdateHorseExamResponse,
  IUploadHorseExamReportRequest,
  IUploadHorseExamReportResponse,
} from '@modules/horses/types/Exams/requests';

type IGetExamsResponse = Promise<AxiosResponse<IFindHorseExamsByHorseIdResponse[]>>;
type IUploadExamReportResponse = Promise<AxiosResponse<IUploadHorseExamReportResponse>>;

export class HorseExams {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Exams ----------

  createExam(data: ICreateHorseExamRequest): Promise<AxiosResponse<ICreateHorseExamResponse>> {
    const { horseId, ...body } = data;
    return this.instance.post(`/horses/${horseId}/exams`, body);
  }

  deleteExam(data: IDeleteHorseExamRequest): Promise<AxiosResponse<void>> {
    const { horseId, examId } = data;
    return this.instance.delete(`/horses/${horseId}/exams/${examId}`);
  }

  updateExam(data: IUpdateHorseExamRequest): Promise<AxiosResponse<IUpdateHorseExamResponse>> {
    const { horseId, examId, ...body } = data;
    return this.instance.patch(`/horses/${horseId}/exams/${examId}`, body);
  }

  getHorseExams(data: IFindHorseExamsByHorseIdRequest): IGetExamsResponse {
    const { horseId, ...params } = data;
    return this.instance.get(`/horses/${horseId}/exams`, { params });
  }

  // ---------- Reports ----------

  updateExamReport(data: IUploadHorseExamReportRequest): IUploadExamReportResponse {
    const { horseId, examId, file } = data;

    const formData = new FormData();
    formData.append('report', file);

    return this.instance.patch(`/horses/${horseId}/exams/${examId}/report`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  deleteExamReport(data: IDeleteHorseExamReportRequest): Promise<AxiosResponse<void>> {
    const { horseId, examId } = data;
    return this.instance.delete(`/horses/${horseId}/exams/${examId}/report`);
  }
}

export const horseExams = (instance: AxiosInstance): HorseExams => new HorseExams(instance);
