import { AxiosInstance } from 'axios';

import { IPaginateDTO } from '@shared/dtos/IPaginateDTO';

import {
  LastAndNextPaymentPlanRider,
  PlanRider as PlanRiderEntity,
} from '@modules/plans/types/PlansRiders/plansRiders';
import {
  CreatePlanRiderRequest,
  DeletePlanRiderRequest,
  FindLastAndNextPaymentPlanRiderRequest,
  FindPlanRiderByRiderIdRequest,
  FindPlansRidersHistoryByRiderPaginateRequest,
  FindRidersByPlanPaginateRequest,
  UpdatePlanRiderRequest,
} from '@modules/plans/types/PlansRiders/requests';

export class PlanRider {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Rider financial officer ----------

  async create({ planId, riderId, ...body }: CreatePlanRiderRequest): Promise<PlanRiderEntity> {
    const response = await this.instance.post(`/plans/${planId}/riders/${riderId}`, body);

    return response.data;
  }

  async delete({ id, planId, riderId, ...params }: DeletePlanRiderRequest): Promise<void> {
    await this.instance.delete(`/plans/${planId}/riders/${riderId}/plan-rider/${id}`, { params });
  }

  async findByPlan({ planId, ...params }: FindRidersByPlanPaginateRequest): Promise<IPaginateDTO<PlanRiderEntity>> {
    const response = await this.instance.get(`/plans/${planId}/riders`, { params });

    return response.data;
  }

  async findCurrentPlanByRider({ riderId, ...params }: FindPlanRiderByRiderIdRequest): Promise<PlanRiderEntity> {
    const response = await this.instance.get(`/riders/${riderId}/current-plan`, { params });

    return response.data;
  }

  async findFuturePlanByRider({ riderId, ...params }: FindPlanRiderByRiderIdRequest): Promise<PlanRiderEntity | null> {
    const response = await this.instance.get(`/riders/${riderId}/future-plan`, { params });

    return response.data;
  }

  async findHistory(data: FindPlansRidersHistoryByRiderPaginateRequest): Promise<IPaginateDTO<PlanRiderEntity>> {
    const { riderId, ...params } = data;

    const response = await this.instance.get(`/plans/riders/${riderId}/history`, { params });

    return response.data;
  }

  async findLastAndNextPayment(data: FindLastAndNextPaymentPlanRiderRequest): Promise<LastAndNextPaymentPlanRider> {
    const { riderId, ...params } = data;

    const response = await this.instance.get(`/plans/riders/${riderId}/last-next-payment`, { params });

    return response.data;
  }

  async update({ id, planId, riderId, ...body }: UpdatePlanRiderRequest): Promise<PlanRiderEntity> {
    const response = await this.instance.patch(`/plans/${planId}/riders/${riderId}/plan-rider/${id}`, body);

    return response.data;
  }
}

export const planRider = (instance: AxiosInstance): PlanRider => new PlanRider(instance);
