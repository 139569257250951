import { AxiosInstance, AxiosResponse } from 'axios';

import { ICreditCard } from '@modules/payments/types/CreditCards/creditCards';
import { ICreateCreditCardRequest, IDeleteCreditCardRequest } from '@modules/payments/types/CreditCards/requests';

export class CreditCards {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Credit cards ----------

  createCreditCard(data: ICreateCreditCardRequest): Promise<AxiosResponse<ICreditCard>> {
    return this.instance.post(`/customers/me/credit-cards`, { ...data, origin: 'COMPANY' });
  }

  deleteCreditCard(data: IDeleteCreditCardRequest): Promise<AxiosResponse<ICreditCard>> {
    const { cardId } = data;

    return this.instance.delete(`/customers/me/credit-cards/${cardId}`);
  }

  getCreditCards(): Promise<AxiosResponse<ICreditCard[]>> {
    return this.instance.get(`/customers/me/credit-cards`);
  }
}

export const creditCards = (instance: AxiosInstance): CreditCards => new CreditCards(instance);
