import { useCallback, useMemo, useState } from 'react';

import { createContext } from 'use-context-selector';

import { CompaniesClient } from '@shared/clients/http/CompaniesClient';
import { IPaginateDTO } from '@shared/dtos/IPaginateDTO';
import { HandleApiErrors } from '@shared/utils/HandleApiErrors';

import { useLoader } from '@modules/globals/hooks/useLoader';

import { IOwnerContext } from '@modules/owners/types/Owners/context';
import { Owner } from '@modules/owners/types/Owners/owners';
import { FindOwnersByCompanyIdRequest } from '@modules/owners/types/Owners/requests';

const OwnersContext = createContext({} as IOwnerContext);
OwnersContext.displayName = 'Owners';

const OwnersProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { startLoad, endLoad } = useLoader();

  const [owners, setOwners] = useState({} as IPaginateDTO<Owner>);

  const getOwners = useCallback(
    async (data: FindOwnersByCompanyIdRequest) => {
      try {
        startLoad();

        const response = await CompaniesClient.owners().getOwners(data);

        setOwners(response);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const contextValue = useMemo<IOwnerContext>(() => ({ getOwners, owners }), [getOwners, owners]);

  return <OwnersContext.Provider value={contextValue}>{children}</OwnersContext.Provider>;
};

export { OwnersContext, OwnersProvider };
