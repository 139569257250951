/**
 * ContactsRoles
 * @readonly
 * @enum {string}
 */
export enum ContactsRoles {
  /** Create a new contact. */
  CREATE_CONTACT = 'CREATE_CONTACT',

  /** Create a new category to contacts. */
  CREATE_CONTACT_CATEGORY = 'CREATE_CONTACT_CATEGORY',

  /** Delete a contact. */
  DELETE_CONTACT = 'DELETE_CONTACT',

  /** Delete a category of the contacts. */
  DELETE_CONTACT_CATEGORIES = 'DELETE_CONTACT_CATEGORIES',

  /** List all categories of the contacts. */
  GET_CONTACT_CATEGORIES = 'GET_CONTACT_CATEGORIES',

  /** List all contacts. */
  GET_CONTACTS = 'GET_CONTACTS',

  /** Update a contact. */
  UPDATE_CONTACT = 'UPDATE_CONTACT',

  /** Update a category of the contacts. */
  UPDATE_CONTACT_CATEGORY = 'UPDATE_CONTACT_CATEGORY',
}
