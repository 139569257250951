/**
 * UserRoles
 * @readonly
 * @enum {string}
 */
export enum UserRoles {
  /** Create a new permission. */
  CREATE_PERMISSION = 'CREATE_PERMISSION',

  /** Create a new user. */
  CREATE_TEAM_USER = 'CREATE_TEAM_USER',

  /** Create a new group. */
  CREATE_USERS_GROUP = 'CREATE_USERS_GROUP',

  /** Delete an user. */
  DELETE_TEAM_USER = 'DELETE_TEAM_USER',

  /** Get permissions list. */
  GET_PERMISSIONS = 'GET_PERMISSIONS',

  /** Get current profile. */
  GET_PROFILE = 'GET_PROFILE',

  /** Get users list. */
  GET_TEAM_USERS = 'GET_TEAM_USERS',

  /** Get groups list. */
  GET_USERS_GROUPS = 'GET_USERS_GROUPS',

  /** Lock and unlock user. */
  HANDLE_BLOCK_TEAM_USER = 'HANDLE_BLOCK_TEAM_USER',

  /** Update a permission. */
  UPDATE_PERMISSION = 'UPDATE_PERMISSION',

  /** Update current profile. */
  UPDATE_PROFILE = 'UPDATE_PROFILE',

  /** Update an user. */
  UPDATE_TEAM_USER = 'UPDATE_TEAM_USER',

  /** Update a group. */
  UPDATE_USERS_GROUP = 'UPDATE_USERS_GROUP',
}
