import { useContextSelector } from 'use-context-selector';

import { SubscriptionsContext } from '@modules/subscriptions/context/Subscriptions';
import { ISubscriptionContext } from '@modules/subscriptions/types/Subscriptions/context';

export function useSubscriptions(): ISubscriptionContext {
  const currentSubscription = useContextSelector(SubscriptionsContext, cs => cs.currentSubscription);
  const dataCreateSubscription = useContextSelector(SubscriptionsContext, cs => cs.dataCreateSubscription);
  const invoices = useContextSelector(SubscriptionsContext, cs => cs.invoices);
  const lastAndNextInvoice = useContextSelector(SubscriptionsContext, cs => cs.lastAndNextInvoice);
  const licenseSimulation = useContextSelector(SubscriptionsContext, cs => cs.licenseSimulation);
  const showConfetti = useContextSelector(SubscriptionsContext, cs => cs.showConfetti);

  const activateSubscription = useContextSelector(SubscriptionsContext, cs => cs.activateSubscription);
  const cancelSubscription = useContextSelector(SubscriptionsContext, cs => cs.cancelSubscription);
  const changeCreditCard = useContextSelector(SubscriptionsContext, cs => cs.changeCreditCard);
  const changeLicenseSimulation = useContextSelector(SubscriptionsContext, cs => cs.changeLicenseSimulation);
  const getCurrentSubscription = useContextSelector(SubscriptionsContext, cs => cs.getCurrentSubscription);
  const getInvoices = useContextSelector(SubscriptionsContext, cs => cs.getInvoices);
  const getLastAndNextInvoice = useContextSelector(SubscriptionsContext, cs => cs.getLastAndNextInvoice);
  const upgradeSubscription = useContextSelector(SubscriptionsContext, cs => cs.upgradeSubscription);
  const handleCreateSubscriptionData = useContextSelector(SubscriptionsContext, cs => cs.handleCreateSubscriptionData);

  return {
    changeCreditCard,
    changeLicenseSimulation,
    currentSubscription,
    dataCreateSubscription,
    activateSubscription,
    cancelSubscription,
    getCurrentSubscription,
    getInvoices,
    getLastAndNextInvoice,
    handleCreateSubscriptionData,
    invoices,
    lastAndNextInvoice,
    licenseSimulation,
    showConfetti,
    upgradeSubscription,
  };
}
