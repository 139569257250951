import { FC, memo, Suspense } from 'react';
import { isDesktop } from 'react-device-detect';
import { createBrowserRouter, NonIndexRouteObject, Outlet, RouterProvider } from 'react-router-dom';

import { Maintenance } from '@modules/maintenance/pages/Maintenance';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AnimatePresence } from 'framer-motion';

import { queryClient } from '@shared/clients/http/ReactQuery';
import { Authenticate } from '@shared/components/Authenticate';
import { Fallback } from '@shared/components/Fallback';
import { Header } from '@shared/components/Header';
import { Sidebar, SidebarMobile } from '@shared/components/Sidebar';
import { Toast } from '@shared/components/Toast';
import { WhatsApp } from '@shared/components/WhatsApp';
import { WrapperContent } from '@shared/components/WrapperContent';
import { EquitesAppProvider } from '@shared/contexts';
import { ErrorPage } from '@shared/routes/ErrorPage';

import { Auth } from '@modules/auth/pages/Auth';

import { SidebarProvider } from '@modules/globals/contexts/SidebarContext';

import { EquitesRoutes } from './EquitesRoutes';

export interface IEquitesRouteObject extends NonIndexRouteObject {
  title: string;
}

const EquitesRoutesComponent: FC = () => {
  const privateRoutes = EquitesRoutes.privateRoutes.map(route => {
    return {
      path: route.path,
      element: (
        <section className="flex h-full w-full bg-background-light dark:bg-background-dark">
          <SidebarProvider>
            <Toast />
            {isDesktop ? <Sidebar /> : <SidebarMobile />}
            <WrapperContent>
              <Header title={route.title} />
              <Suspense fallback={<Fallback.Navigation />}>
                <Authenticate>
                  <section className="mx-auto w-full max-w-screen-xl flex-1">{route.element}</section>
                </Authenticate>
              </Suspense>
              <WhatsApp />
            </WrapperContent>
          </SidebarProvider>
        </section>
      ),
    };
  });

  const router = createBrowserRouter([
    {
      path: '/auth',
      element: (
        <EquitesAppProvider>
          <Auth />
        </EquitesAppProvider>
      ),
    },
    {
      path: '/maintenance',
      element: (
        <EquitesAppProvider>
          <Maintenance />
        </EquitesAppProvider>
      ),
    },
    {
      path: '/',
      element: (
        <EquitesAppProvider>
          <AnimatePresence>
            <Outlet />
          </AnimatePresence>
          <ReactQueryDevtools client={queryClient} />
        </EquitesAppProvider>
      ),
      errorElement: <ErrorPage />,
      children: [...privateRoutes],
    },
  ]);

  return (
    <Suspense fallback={<Fallback.Navigation />}>
      <RouterProvider router={router} fallbackElement={<div>Loading...</div>} />
    </Suspense>
  );
};

export const Routes = memo(EquitesRoutesComponent);
