import { AxiosInstance, AxiosResponse } from 'axios';

import {
  ICreateHorseVaccineRequest,
  ICreateHorseVaccineResponse,
  IDeleteHorseVaccineRequest,
  IFindHorseVaccinesByHorseIdRequest,
  IFindHorseVaccinesByHorseIdResponse,
} from '@modules/horses/types/Vaccines/requests';

type IGetHorseVaccinesResponse = Promise<AxiosResponse<IFindHorseVaccinesByHorseIdResponse[]>>;

export class HorseVaccines {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  createVaccine(data: ICreateHorseVaccineRequest): Promise<AxiosResponse<ICreateHorseVaccineResponse>> {
    const { horseId, ...body } = data;
    return this.instance.post(`/horses/${horseId}/vaccines`, body);
  }

  deleteVaccine(data: IDeleteHorseVaccineRequest): Promise<AxiosResponse<void>> {
    const { horseId, vaccineId } = data;
    return this.instance.delete(`/horses/${horseId}/vaccines/${vaccineId}`);
  }

  getHorseVaccines(data: IFindHorseVaccinesByHorseIdRequest): IGetHorseVaccinesResponse {
    const { horseId, ...params } = data;
    return this.instance.get(`/horses/${horseId}/vaccines`, { params });
  }
}

export const horseVaccines = (instance: AxiosInstance): HorseVaccines => new HorseVaccines(instance);
