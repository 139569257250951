import { useCallback, useMemo, useState } from 'react';

import { format, parseISO } from 'date-fns';
import { createContext } from 'use-context-selector';

import { CompaniesClient } from '@shared/clients/http/CompaniesClient';
import { HandleApiErrors } from '@shared/utils/HandleApiErrors';

import { useLoader } from '@modules/globals/hooks/useLoader';

import { IHorseFeedFoodForecastContext } from '@modules/horses/types/FeedsFoodForeacast/context';
import { IFoodForecastDetailed } from '@modules/horses/types/FeedsFoodForeacast/feedsFoodForecast';
import { IGetFoodForecastDetailedRequest } from '@modules/horses/types/FeedsFoodForeacast/requests';

const HorseFeedFoodForecastContext = createContext({} as IHorseFeedFoodForecastContext);
HorseFeedFoodForecastContext.displayName = 'HorsesFeeds';

const HorseFeedFoodForecastProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { startLoad, endLoad } = useLoader();

  const [forecast, setForecast] = useState<IFoodForecastDetailed[]>([]);

  const getFeedFoodForecast = useCallback(
    async (data: IGetFoodForecastDetailedRequest) => {
      try {
        startLoad();

        const response = await CompaniesClient.horsesFeedsFoodsForecast().getFoodForecast(data);

        setForecast(
          response.data.map(food => ({
            ...food,
            forecastDateFormatted: format(parseISO(food.forecastDate), 'dd/MM/yyyy'),
          })),
        );
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const contextValue = useMemo<IHorseFeedFoodForecastContext>(
    () => ({ forecast, getFeedFoodForecast }),
    [forecast, getFeedFoodForecast],
  );

  return <HorseFeedFoodForecastContext.Provider value={contextValue}>{children}</HorseFeedFoodForecastContext.Provider>;
};

export { HorseFeedFoodForecastContext, HorseFeedFoodForecastProvider };
