import { AxiosInstance } from 'axios';

import {
  AddHorseToStallRequest,
  CreateStallRequest,
  DeleteStallByIdRequest,
  FindCurrentStallByHorseRequest,
  FindStallHistoryByHorseIdRequest,
  FindStallHistoryByStallIdRequest,
  FindStallsByPavilionRequest,
  RemoveHorseFromStallRequest,
  UpdateStallRequest,
} from '@modules/configurations/types/ConfigurationsHorses/Stalls/requests';
import {
  Stall,
  StallHistory,
  StallHorse,
  StallsSummary,
} from '@modules/configurations/types/ConfigurationsHorses/Stalls/stalls';

export class ConfigurationHorseStall {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Stalls ----------

  async create({ pavilionId, ...body }: CreateStallRequest): Promise<Stall> {
    const response = await this.instance.post(`/configurations/horses/pavilions/${pavilionId}/stalls`, body);

    return response.data;
  }

  async getByPavilion({ pavilionId, ...params }: FindStallsByPavilionRequest): Promise<Stall[]> {
    const response = await this.instance.get(`/configurations/horses/pavilions/${pavilionId}/stalls`, { params });

    return response.data;
  }

  async delete({ pavilionId, stallId, ...params }: DeleteStallByIdRequest): Promise<void> {
    await this.instance.delete(`/configurations/horses/pavilions/${pavilionId}/stalls/${stallId}`, { params });
  }

  async update({ pavilionId, stallId, ...body }: UpdateStallRequest): Promise<Stall> {
    const url = `/configurations/horses/pavilions/${pavilionId}/stalls/${stallId}`;

    const response = await this.instance.patch(url, body);

    return response.data;
  }

  // ---------- Stalls horses handle ----------

  async addHorse({ pavilionId, stallTargetId, ...body }: AddHorseToStallRequest): Promise<Stall> {
    const url = `/configurations/horses/pavilions/${pavilionId}/stalls/${stallTargetId}/horses`;

    const response = await this.instance.post(url, body);

    return response.data;
  }

  async getHorsesAvailable(): Promise<StallHorse[]> {
    const response = await this.instance.get(`/configurations/horses/pavilions/stalls/horses-available`);

    return response.data;
  }

  async removeHorse({ pavilionId, stallId, ...params }: RemoveHorseFromStallRequest): Promise<Stall> {
    const url = `/configurations/horses/pavilions/${pavilionId}/stalls/${stallId}/horses`;

    const response = await this.instance.delete(url, { params });

    return response.data;
  }

  // ---------- Stalls summary ----------

  async getSummary(): Promise<StallsSummary> {
    const response = await this.instance.get('/configurations/horses/pavilions/stalls/summary');

    return response.data;
  }

  // ---------- Stalls history ----------

  async getHistoryByHorse({ horseId }: FindStallHistoryByHorseIdRequest): Promise<StallHistory[]> {
    const url = `/configurations/horses/${horseId}/stalls-history`;

    const response = await this.instance.get(url);

    return response.data;
  }

  async getHistoryByStall({ pavilionId, stallId }: FindStallHistoryByStallIdRequest): Promise<StallHistory[]> {
    const url = `/configurations/horses/pavilions/${pavilionId}/stalls/${stallId}/history`;

    const response = await this.instance.get(url);

    return response.data;
  }

  // ---------- Current stall by horse ----------

  async getCurrentStallByHorse({ horseId }: FindCurrentStallByHorseRequest): Promise<Stall | null> {
    const response = await this.instance.get(`/configurations/horses/${horseId}/current-stall`);

    return response.data;
  }
}

export const configurationHorseStall = (instance: AxiosInstance): ConfigurationHorseStall =>
  new ConfigurationHorseStall(instance);
