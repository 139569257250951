import { AxiosInstance } from 'axios';

import { Pavilion } from '@modules/configurations/types/ConfigurationsHorses/Pavilions/pavilions';
import {
  CreatePavilionRequest,
  DeletePavilionRequest,
  DuplicatePavilionRequest,
  UpdatePavilionRequest,
} from '@modules/configurations/types/ConfigurationsHorses/Pavilions/requests';

export class ConfigurationHorsePavilion {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  async create(data: CreatePavilionRequest): Promise<Pavilion> {
    const response = await this.instance.post(`/configurations/horses/pavilions`, data);

    return response.data;
  }

  async delete({ pavilionId, ...params }: DeletePavilionRequest): Promise<void> {
    await this.instance.delete(`/configurations/horses/pavilions/${pavilionId}`, { params });
  }

  async getAll(): Promise<Pavilion[]> {
    const response = await this.instance.get(`/configurations/horses/pavilions`);

    return response.data;
  }

  async update({ id, ...body }: UpdatePavilionRequest): Promise<Pavilion> {
    const response = await this.instance.put(`/configurations/horses/pavilions/${id}`, body);

    return response.data;
  }

  async duplicate({ id, ...body }: DuplicatePavilionRequest): Promise<Pavilion> {
    const response = await this.instance.post(`/configurations/horses/pavilions/${id}/duplicate`, body);

    return response.data;
  }
}

export const configurationHorsePavilion = (instance: AxiosInstance): ConfigurationHorsePavilion =>
  new ConfigurationHorsePavilion(instance);
