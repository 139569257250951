import { AxiosInstance } from 'axios';

import {
  ICreateBalanceWalletRequest,
  IDeleteBalanceWalletRequest,
  IUpdateBalanceWalletRequest,
} from '@modules/balances/types/Wallets/requests';
import { IBalanceWallet } from '@modules/balances/types/Wallets/wallets';

export class BalancesWallets {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Wallets ----------

  async create(data: ICreateBalanceWalletRequest): Promise<IBalanceWallet> {
    const response = await this.instance.post(`/balances/wallets`, data);

    return response.data;
  }

  async delete(data: IDeleteBalanceWalletRequest): Promise<void> {
    const { walletId } = data;

    const response = await this.instance.delete(`/balances/wallets/${walletId}`);

    return response.data;
  }

  async getAll(): Promise<IBalanceWallet[]> {
    const response = await this.instance.get(`/balances/wallets`);

    return response.data;
  }

  async update(data: IUpdateBalanceWalletRequest): Promise<IBalanceWallet> {
    const { walletId, ...body } = data;

    const response = await this.instance.patch(`/balances/wallets/${walletId}`, body);

    return response.data;
  }
}

export const balancesWallets = (instance: AxiosInstance): BalancesWallets => new BalancesWallets(instance);
