import { AxiosInstance, AxiosResponse } from 'axios';

import { IPaginateDTO } from '@shared/dtos/IPaginateDTO';
import { openPdf } from '@shared/utils/OpenPDF';

import { IHorseExpense } from '@modules/horses/types/Expenses/expenses';
import {
  ICreateHorseExpenseRequest,
  IDeleteHorseExpenseRequest,
  IFindHorseExpensesRequest,
  IUpdateHorseExpenseRequest,
} from '@modules/horses/types/Expenses/requests';

export class HorseExpenses {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Expenses ----------

  createExpense(data: ICreateHorseExpenseRequest): Promise<AxiosResponse<IHorseExpense>> {
    const { horseId, ...body } = data;
    return this.instance.post(`/horses/${horseId}/expenses`, body);
  }

  deleteExpense(data: IDeleteHorseExpenseRequest): Promise<AxiosResponse<void>> {
    const { horseId, horseExpenseId } = data;
    return this.instance.delete(`/horses/${horseId}/expenses/${horseExpenseId}`);
  }

  findExpenses(data: IFindHorseExpensesRequest): Promise<AxiosResponse<IPaginateDTO<IHorseExpense>>> {
    const { horseId, ...params } = data;
    return this.instance.get(`/horses/${horseId}/expenses`, { params });
  }

  updateExpense(data: IUpdateHorseExpenseRequest): Promise<AxiosResponse<IHorseExpense>> {
    const { horseId, horseExpenseId, ...body } = data;
    return this.instance.patch(`/horses/${horseId}/expenses/${horseExpenseId}`, body);
  }

  // ---------- Reports ----------

  async exportExpensesPDF(request: IFindHorseExpensesRequest): Promise<AxiosResponse<IHorseExpense>> {
    const { horseId, ...params } = request;

    const url = `/horses/${horseId}/expenses/report/pdf/export`;
    const { data, ...response } = await this.instance.get(url, { params, responseType: 'blob' });

    openPdf(data);

    return { data, ...response };
  }
}

export const horseExpenses = (instance: AxiosInstance): HorseExpenses => new HorseExpenses(instance);
