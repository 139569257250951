import { FC, PropsWithChildren, ReactElement } from 'react';

import {
  Arrow as TooltipPrimitiveArrow,
  Content as TooltipPrimitiveContent,
  Provider as TooltipPrimitiveProvider,
  Root as TooltipPrimitiveRoot,
  Trigger as TooltipPrimitiveTrigger,
} from '@radix-ui/react-tooltip';

import { Text } from '../Text';
import { ITooltipContentProps } from './types';

// ------------------------------------------

const TooltipRoot: FC<PropsWithChildren> = ({ children }): ReactElement => {
  return (
    <TooltipPrimitiveProvider>
      <TooltipPrimitiveRoot delayDuration={250}>{children}</TooltipPrimitiveRoot>
    </TooltipPrimitiveProvider>
  );
};

TooltipRoot.displayName = 'Tooltip.Root';

// ------------------------------------------

const TooltipTrigger: FC<PropsWithChildren> = ({ children }): ReactElement => {
  return <TooltipPrimitiveTrigger asChild>{children}</TooltipPrimitiveTrigger>;
};

TooltipTrigger.displayName = 'Tooltip.Trigger';

// ------------------------------------------

const TooltipContent: FC<ITooltipContentProps> = ({ text }): ReactElement => {
  return (
    <TooltipPrimitiveContent sideOffset={4} className="inline-flex items-center rounded-lg bg-black-400 px-4 py-2">
      <TooltipPrimitiveArrow className="fill-black-400" />
      <Text className="text-xs text-white">{text}</Text>
    </TooltipPrimitiveContent>
  );
};

TooltipContent.displayName = 'Tooltip.Content';

// ------------------------------------------

export const Tooltip = {
  Root: TooltipRoot,
  Trigger: TooltipTrigger,
  Content: TooltipContent,
};
