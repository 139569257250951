import { AxiosInstance } from 'axios';
import fileDownload from 'js-file-download';

import { IPaginateDTO } from '@shared/dtos/IPaginateDTO';

import { IAppointment } from '@modules/appointments/types/Appointments/appointments';
import {
  CreateAppointmentRequest,
  DeleteAppointmentRequest,
  UpdateAppointmentRequest,
} from '@modules/appointments/types/Appointments/requests';
import {
  IExportAppointmentsCalendarRequest,
  IFindAppointmentByDatesRequest,
} from '@modules/appointments/types/AppointmentsCalendar/requests';
import { IFindAppointmentHistoryRequest } from '@modules/appointments/types/AppointmentsHistory/requests';
import { IAppointmentRequest } from '@modules/appointments/types/AppointmentsRequests/appointmentsRequests';
import {
  ICreateRiderFromAppointmentRequest,
  IFindAppointmentRequestByIdRequest,
  IFindAppointmentsRequestsRequest,
  IUpdateAppointmentRequestRequest,
} from '@modules/appointments/types/AppointmentsRequests/requests';
import { AppointmentsMaxByHourValidationRequest } from '@modules/appointments/types/AppointmentsValidations/requests';
import { AppointmentsMaxByHourValidation } from '@modules/appointments/types/AppointmentsValidations/validations';

type IGetAppointmentsByDatesResponse = Promise<IAppointment[]>;
type IGetAppointmentsHistoryResponse = Promise<IPaginateDTO<IAppointment>>;
type IGetAppointmentsRequestsResponse = Promise<IPaginateDTO<IAppointmentRequest>>;

export class Appointments {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Appointments ----------

  async createAppointment(data: CreateAppointmentRequest): Promise<IAppointment> {
    const response = await this.instance.post(`/appointments`, data);

    return response.data;
  }

  async deleteAppointment(data: DeleteAppointmentRequest): Promise<void> {
    const { appointmentId, ...params } = data;

    await this.instance.delete(`/appointments/${appointmentId}`, { params });
  }

  async getAppointmentById(appointmentId: string): Promise<IAppointment> {
    const response = await this.instance.get(`/appointments/${appointmentId}`);

    return response.data;
  }

  async getAppointmentsByDates(params: IFindAppointmentByDatesRequest): IGetAppointmentsByDatesResponse {
    const response = await this.instance.get(`/appointments/calendar`, { params });

    return response.data;
  }

  async getAppointmentsHistory(params: IFindAppointmentHistoryRequest): IGetAppointmentsHistoryResponse {
    const response = await this.instance.get(`/appointments/history`, { params });

    return response.data;
  }

  async updateAppointment(data: UpdateAppointmentRequest): Promise<IAppointment> {
    const { appointmentId, ...body } = data;

    const response = await this.instance.patch(`/appointments/${appointmentId}`, body);

    return response.data;
  }

  // ---------- Reports ----------

  async exportAppointmentsCalendarPdf(params: IExportAppointmentsCalendarRequest): Promise<void> {
    const responseType = 'arraybuffer';

    const response = await this.instance.get(`/appointments/reports/calendar/pdf/export`, { params, responseType });

    const blob = new Blob([response.data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);

    window.open(url);
  }

  async exportAppointmentsHistoryPdf(params: IFindAppointmentHistoryRequest): Promise<void> {
    const responseType = 'arraybuffer';

    const response = await this.instance.get(`/appointments/reports/history/pdf/export`, { params, responseType });

    const blob = new Blob([response.data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);

    window.open(url);
  }

  async exportAppointmentsHistoryXlsx(params: IFindAppointmentHistoryRequest): Promise<void> {
    const url = `/appointments/reports/history/xlsx/export`;

    const response = await this.instance.get(url, { params, responseType: 'blob' });

    fileDownload(response.data, `appointments.xlsx`);
  }

  // ---------- Requests ----------

  async getAppointmentsRequests(params: IFindAppointmentsRequestsRequest): IGetAppointmentsRequestsResponse {
    const response = await this.instance.get(`/appointments/requests`, { params });

    return response.data;
  }

  async getAppointmentRequestById(params: IFindAppointmentRequestByIdRequest): Promise<IAppointmentRequest> {
    const response = await this.instance.get(`/appointments/requests/${params.appointmentRequestId}`);

    return response.data;
  }

  async updateAppointmentRequest(data: IUpdateAppointmentRequestRequest): Promise<IAppointmentRequest> {
    const { appointmentRequestId, ...body } = data;

    const response = await this.instance.patch(`/appointments/requests/${appointmentRequestId}`, body);

    return response.data;
  }

  // ---------- Rider from appointment request ----------

  async createRiderFromAppointmentRequest(data: ICreateRiderFromAppointmentRequest): Promise<IAppointmentRequest> {
    const { appointmentRequestId, ...body } = data;

    const response = await this.instance.post(`/appointments/requests/${appointmentRequestId}/create-rider`, body);

    return response.data;
  }

  // ---------- Validations ----------

  async validateMaxByHour(params: AppointmentsMaxByHourValidationRequest): Promise<AppointmentsMaxByHourValidation> {
    const response = await this.instance.get(`/appointments/validations/max-appointments-by-hour`, { params });

    return response.data;
  }
}

export const appointments = (instance: AxiosInstance): Appointments => new Appointments(instance);
