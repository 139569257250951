import { createContext, FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';

export interface ISidebarContext {
  sidebarOpened: boolean;
  menuOpened?: string;
  menuSelected?: string;
  handleMenuSelected: (id: string) => void;
  handleMenuOpened: (id: string) => void;
  toggleSidebarOpened: () => void;
}

const SidebarContext = createContext<ISidebarContext>({} as ISidebarContext);
SidebarContext.displayName = 'Sidebar';

const SidebarProvider: FC<PropsWithChildren> = ({ children }) => {
  const [sidebarOpened, setSidebarOpened] = useState(false);
  const [menuSelected, setMenuSelected] = useState<string>();
  const [menuOpened, setMenuOpened] = useState<string>();

  const toggleSidebarOpened = useCallback(() => {
    setSidebarOpened(current => !current);
  }, []);

  const handleMenuSelected = useCallback((id: string) => {
    setMenuSelected(id);
  }, []);

  const handleMenuOpened = useCallback((id: string) => {
    setMenuOpened(current => (current !== id ? id : undefined));
  }, []);

  const contextValue = useMemo<ISidebarContext>(
    () => ({ sidebarOpened, menuOpened, menuSelected, handleMenuOpened, handleMenuSelected, toggleSidebarOpened }),
    [handleMenuOpened, handleMenuSelected, menuOpened, menuSelected, sidebarOpened, toggleSidebarOpened],
  );

  return <SidebarContext.Provider value={contextValue}>{children}</SidebarContext.Provider>;
};

export { SidebarContext, SidebarProvider };
