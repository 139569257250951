import { ConciliationProvider } from '@modules/invoices/contexts/Conciliation';
import { InvoiceProvider } from '@modules/invoices/contexts/Invoices';

export const AppInvoicesProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <InvoiceProvider>
      <ConciliationProvider>{children}</ConciliationProvider>
    </InvoiceProvider>
  );
};
