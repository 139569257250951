import { AxiosInstance, AxiosResponse } from 'axios';

import { IDashboardGeneralBalances } from '@modules/balances/types/Dashboard/dashboard';

export class BalancesDashboard {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- General ----------

  getDashboardGeneral(): Promise<AxiosResponse<IDashboardGeneralBalances>> {
    return this.instance.get(`/balances/dashboard/general`);
  }
}

export const balancesDashboard = (instance: AxiosInstance): BalancesDashboard => new BalancesDashboard(instance);
