import { AxiosInstance } from 'axios';

import { IPaginateDTO } from '@shared/dtos/IPaginateDTO';

import { IUser } from '@modules/configurations/types/MyTeam/myTeam';
import {
  IChangeGroupPermissionToUserRequest,
  ICreateUserRequest,
  IDeleteUserRequest,
  IDisableUserRequest,
  IGetUsersRequest,
} from '@modules/configurations/types/MyTeam/requests';

export class MyTeam {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  async changeGroupPermission(data: IChangeGroupPermissionToUserRequest): Promise<void> {
    const { identifierId, ...body } = data;

    await this.instance.patch(`/profiles/identifiers/${identifierId}/groups`, body);
  }

  public async createUser(data: ICreateUserRequest): Promise<void> {
    return this.instance.post(`/my-team/users`, data);
  }

  async deleteUser(data: IDeleteUserRequest): Promise<void> {
    const { identifierId, profile } = data;

    await this.instance.delete(`/my-team/users/${identifierId}/profile/${profile}`);
  }

  async disableUser(data: IDisableUserRequest): Promise<void> {
    const { identifierId } = data;

    await this.instance.patch(`/profiles/identifiers/${identifierId}/disable`, { profile: 'COMPANY' });
  }

  async enableUser(data: IDisableUserRequest): Promise<void> {
    const { identifierId } = data;

    await this.instance.patch(`/profiles/identifiers/${identifierId}/enable`, { profile: 'COMPANY' });
  }

  async getUsers(params: IGetUsersRequest): Promise<IPaginateDTO<IUser>> {
    const response = await this.instance.get(`/companies/users`, { params });

    return response.data;
  }
}

export const myTeam = (instance: AxiosInstance): MyTeam => new MyTeam(instance);
